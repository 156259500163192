import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Modal,
  Popover,
  Spin,
  Tag,
  Tree,
  Typography,
} from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState, useMemo } from 'react';

import { Chips } from '@marketreach/components/chips';
import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { ATTRIBUTES } from '@marketreach/services/apollo/attributes';
import { CATEGORIES } from '@marketreach/services/apollo/categories';
import { GET_PRODUCT_BY_PAGE_QUERY } from '@marketreach/services/apollo/products';
import {
  CREATE_RULE,
  DELETE_RULE,
  RULES,
  RULES_BY_PAGE_QUERY,
  RULES_QUERY,
  UPDATE_RULE,
} from '@marketreach/services/apollo/rules';
import { capitalize, useTreeData } from '@marketreach/utils/common';
import { sortTable } from '@marketreach/utils/products';

import RuleEdit from './components/rule-form/RuleForm';

import './Rules.styles.scss';

const { Text } = Typography;

const RulesPage = () => {
  const { selected: client } = useClientsState();
  /* -------------------------------------------------------------------------- */
  /*                               RULE MUTATIONS                               */
  /* -------------------------------------------------------------------------- */
  const [createRule] = useMutation(CREATE_RULE);
  const [updateRule] = useMutation(UPDATE_RULE);
  const [deleteRule] = useMutation(DELETE_RULE);

  /* -------------------------------------------------------------------------- */
  /*                              LOCAL COMP STATE                              */
  /* -------------------------------------------------------------------------- */
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [inUpdate, setInUpdate] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL RULES QUERY                            */
  /* -------------------------------------------------------------------------- */
  const {
    loading,
    data,
    refetch: refetchRules,
  } = useQuery(RULES, {
    variables: { clientCode: client?.apiId },
    fetchPolicy: 'no-cache',
  });
  const rules = data?.rules?.data || [];

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL CATEGORIES                             */
  /* -------------------------------------------------------------------------- */
  const { data: categoryRes } = useQuery(CATEGORIES, {
    variables: { clientCode: client?.apiId },
  });
  const categoryData = categoryRes ? categoryRes?.categories?.data : [];
  const remappedCategories = useMemo(() => {
    const mapped = categoryData.map((it) => ({
      name: it.name,
      ...it.core,
      _id: it._id,
    }));

    return mapped;
  }, [categoryData]);
  const [categoryTree] = useTreeData(remappedCategories);

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL ATTRIBUTES                             */
  /* -------------------------------------------------------------------------- */
  const { data: attributeRes } = useQuery(ATTRIBUTES, {
    variables: { clientCode: client?.apiId },
  });
  const attributeData = attributeRes ? attributeRes?.attributes?.data : [];
  const remappedAttributes = useMemo(() => {
    const mapped = attributeData.map((it) => ({
      name: it.name,
      ...it.core,
      _id: it._id,
    }));

    return mapped;
  }, [attributeData]);
  const [attributeTree] = useTreeData(remappedAttributes);

  /* -------------------------------------------------------------------------- */
  /*                               LOCAL HANDLERS                               */
  /* -------------------------------------------------------------------------- */
  const handleSaveClick = (values) => {
    if (selectedRule) {
      updateRule({
        variables: {
          ...selectedRule,
          ...values,
          clientCode: client?.apiId,
        },
        refetchQueries: [
          'Rules',
          GET_PRODUCT_BY_PAGE_QUERY,
          RULES_QUERY,
          RULES_BY_PAGE_QUERY,
        ],
      }).then(() => {
        message.info('Rule updated successfully');
        setIsEdit(false);
        setSelectedRule(null);
      });
    } else {
      createRule({
        variables: {
          ...values,
          clientCode: client?.apiId,
        },
        refetchQueries: [
          GET_PRODUCT_BY_PAGE_QUERY,
          RULES_QUERY,
          RULES_BY_PAGE_QUERY,
        ],
      }).then(() => {
        message.info('Rule created successfully');
        refetchRules();
        setIsEdit(false);
      });
    }
  };

  const handleEditRule = (rule) => {
    setSelectedRule(rule);
    setIsEdit(true);
  };

  const handleDeleteRuleOk = () => {
    console.log('handleDeleteRuleOk');
    deleteRule({
      variables: {
        _id: selectedRule?._id,
        clientCode: client?.apiId,
        hardDelete: true,
      },
      refetchQueries: [
        GET_PRODUCT_BY_PAGE_QUERY,
        RULES_QUERY,
        RULES_BY_PAGE_QUERY,
      ],
    }).then(() => {
      setOpenConfirmModal(false);
      setSelectedRule(null);
      refetchRules();
    });
  };

  const handleDeleteRuleCancel = () => {
    setOpenConfirmModal(false);
    setSelectedRule(null);
  };

  const handleDeleteRule = (rule) => {
    setSelectedRule(rule);
    setOpenConfirmModal(true);
  };

  const handleCancelClick = () => {
    setIsEdit(false);
    setSelectedRule(null);
  };

  const onClickEdit = () => {
    setIsEdit(true);
  };

  const basisCell = (basis) => {
    const color = basis === 'include' ? '#2cae2e' : '#ff1f2b';
    return (
      <div style={{ color }} className="rule-basis">
        {basis === 'include' ? <PlusCircleOutlined /> : <MinusCircleOutlined />}
        <Text
          className="rule-basis-title"
          style={{
            color,
            marginLeft: 8,
          }}
        >
          {capitalize(basis)}
        </Text>
      </div>
    );
  };

  const categoryPopoverContent = (rule) => {
    const onCheck = (checkedKeys, info) => {
      handleCategoryCheck(rule, checkedKeys, info);
    };

    return (
      <Spin spinning={inUpdate || loading}>
        <Tree
          className="categories-content-tree-content"
          checkable
          checkStrictly={true}
          defaultExpandedKeys={[]}
          defaultSelectedKeys={[]}
          defaultCheckedKeys={[]}
          checkedKeys={rule?.associations?.categories || []}
          treeData={categoryTree}
          onCheck={onCheck}
        />
      </Spin>
    );
  };

  const handleCategoryCheck = (rule, checkedKeys, info) => {
    console.log('checkedKeys: ', checkedKeys);
    console.log('info: ', info);

    const checkedCategories = rule?.associations?.categories || [];

    const getChildCategories = (item) => {
      const categories = [];
      item.children.forEach((sub) => {
        categories.push(sub.key);
        if (sub.children.length > 0) {
          categories.push(...getChildCategories(sub));
        }
      });

      return categories;
    };

    const needCheckAllNested = (node) => {
      if (node.children.length === 0) return false;
      for (const item of node.children) {
        if (checkedCategories.indexOf(item.key) !== -1) {
          return false;
        }
      }
      return true;
    };

    let isChecked = info?.checked || false;
    if (!isChecked && needCheckAllNested(info?.node)) {
      isChecked = true;
      checkedKeys['checked'].push(info?.node.key);
      checkedKeys['checked'].push(...getChildCategories(info?.node));
    }

    const realCheckedKeys = [];

    if (!isChecked) {
      const childs = getChildCategories(info.node);
      const toRemove = [info.node.key];
      toRemove.push(...childs);

      const keysToLeave = [
        ...checkedKeys['checked'],
        ...checkedKeys['halfChecked'],
      ].filter((item) => {
        return toRemove.indexOf(item) === -1;
      });

      realCheckedKeys.push(...keysToLeave);
    } else {
      realCheckedKeys.push(
        ...checkedKeys['checked'],
        ...checkedKeys['halfChecked']
      );
    }

    setInUpdate(true);
    const ruleForUpdate = JSON.parse(JSON.stringify(rule));
    ruleForUpdate.associations.categories = realCheckedKeys;

    updateRule({
      variables: {
        ...ruleForUpdate,
        clientCode: client?.apiId,
      },
      refetchQueries: [RULES_QUERY],
    }).then(() => {
      setInUpdate(false);
      return true;
    });
  };

  const handleAttributesCheck = (rule, checkedKeys) => {
    setInUpdate(true);
    const ruleForUpdate = JSON.parse(JSON.stringify(rule));
    ruleForUpdate.associations.attributes = checkedKeys;

    return updateRule({
      variables: {
        ...ruleForUpdate,
        clientCode: client?.apiId,
      },
      refetchQueries: [RULES_QUERY],
    }).then(() => {
      setInUpdate(false);
      return true;
    });
  };

  const attributesPopoverContent = (rule) => {
    const onCheck = (checkedKeys, info) => {
      handleAttributesCheck(rule, checkedKeys);
    };
    return (
      <Spin spinning={inUpdate || loading}>
        <Tree
          className="categories-content-tree-content"
          checkable
          defaultExpandedKeys={[]}
          defaultSelectedKeys={[]}
          defaultCheckedKeys={[]}
          checkedKeys={rule?.associations?.attributes || []}
          treeData={attributeTree}
          onCheck={onCheck}
        />
      </Spin>
    );
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      render: (text, row) => basisCell(row.basis),
    },
    {
      title: 'Match',
      dataIndex: 'match',
    },
    {
      title: 'Word',
      dataIndex: 'word',
    },
    {
      title: 'Case sensitive',
      dataIndex: 'case_sensitive',
      // eslint-disable-next-line camelcase
      render: (text, row) => <span>{(!!row?.case_sensitive).toString()}</span>,
    },
    {
      title: 'Key',
      // dataIndex: 'key',
      render: (text, row) => (
        <span>{row?.simpleMode ? row.key : 'advanced mode'}</span>
      ),
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      render: (text, row) =>
        row?.simpleMode ? <Chips entities={row.criteria} /> : row.advancedQuery,
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      render: (text, row) => (
        <Popover
          content={categoryPopoverContent(row)}
          trigger="click"
          title="Categories"
        >
          <button className="ant-btn-link link">
            {row?.associations?.categories?.filter((item) =>
              _.find(categoryData, (o) => o._id === item)
            ).length || 0}
          </button>
        </Popover>
      ),
    },
    {
      title: 'Attributes',
      dataIndex: 'attributes',
      render: (text, row) => (
        <Popover
          content={attributesPopoverContent(row)}
          trigger="click"
          title="Attributes"
        >
          <button className="ant-btn-link link">
            {row?.associations?.attributes?.filter((item) =>
              _.find(attributeData, (o) => o._id === item)
            ).length || 0}
          </button>
        </Popover>
      ),
    },
    {
      title: 'Product IDs',
      dataIndex: 'associatedProductIds',
      render: (text, row) => <span>{row?.productIds?.length || 0}</span>,
    },
    {
      title: 'SKUs',
      dataIndex: 'skus',
      render: (text, row) => <span>{row?.skus?.length || 0}</span>,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, row) => [
        <Button
          key="delete"
          type="text"
          shape="circle"
          onClick={() => handleDeleteRule(row)}
        >
          <DeleteOutlined />
        </Button>,
        <Button
          key="edit"
          type="text"
          shape="circle"
          onClick={() => handleEditRule(row)}
        >
          <EditOutlined />
        </Button>,
      ],
    },
  ].map((item) => {
    item.sorter = true;
    item.key = item.dataIndex;
    item.sorter = (a, b) => {
      if (item.dataIndex === 'categories' || item.dataIndex === 'attributes') {
        return sortTable(
          a?.associations[item.dataIndex]?.length || 0,
          b?.associations[item.dataIndex]?.length || 0
        );
      }

      if (item.dataIndex === 'associatedProductIds') {
        return sortTable(
          a?.productIds?.length || 0,
          b?.productIds?.length || 0
        );
      }

      if (item.dataIndex === 'skus') {
        return sortTable(a?.skus?.length || 0, b?.skus?.length || 0);
      }
      return sortTable(a[item.dataIndex] ?? '', b[item.dataIndex] ?? '');
    };

    return item;
  });

  const menu = (
    <Menu>
      <Menu.Item key="1">Menu 1</Menu.Item>
      <Menu.Item key="2">Menu 2</Menu.Item>
    </Menu>
  );

  const toolbar = () => [
    <Button type="primary" onClick={onClickEdit}>
      <PlusOutlined /> Add New
    </Button>,
    <Dropdown overlay={menu} key="export">
      <Button type="link">
        Export <DownOutlined />
      </Button>
    </Dropdown>,
  ];
  return (
    <div className="ant-pro-grid-content rules-content">
      <Layout className="rules-content-main">
        {!isEdit ? (
          <CustomProTable
            className="rules-content-main-table"
            data={rules}
            rowKey="_id"
            columns={columns}
            toolbar={toolbar}
            pagination={{ defaultPageSize: 20 }}
            selectable={false}
          />
        ) : (
          <RuleEdit
            rule={selectedRule}
            client={client}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        )}
      </Layout>
      {openConfirmModal && (
        <Modal
          className="rule-delete-modal"
          title="Delete rule"
          visible={openConfirmModal}
          onOk={handleDeleteRuleOk}
          onCancel={handleDeleteRuleCancel}
          width={400}
        >
          <div>Are sure want to delete this rule?</div>
        </Modal>
      )}
    </div>
  );
};

RulesPage.propTypes = {
  client: PropTypes.object,
};

RulesPage.defaultProps = {
  client: null,
};

export default memo(RulesPage);
