import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Spin } from 'antd';
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { SINGLE_CLIENT } from '@marketreach/services/apollo/clients';

import RightMenu from './RightMenu';

import './styles.scss';

const navigation = [
  {
    label: 'Taxonomy',
    path: '/taxonomy',
  },
  {
    label: 'Products',
    path: '/products',
  },
  {
    label: 'Feeds',
    path: '/feeds',
  },
  {
    label: 'Rules',
    path: '/rules',
  },
];

const TopBar = () => {
  const { isAuthenticated } = useAuth0();
  const { selected } = useClientsState();

  const { loading, data: clientData } = useQuery(SINGLE_CLIENT, {
    variables: {
      clientCode: selected?.apiId,
    },
  });

  const client = clientData?.getClient?.data;

  const { pathname } = useLocation();

  return (
    <nav className="menuBar">
      <div className="logo">MarketReach</div>

      <div className="leftMenu">
        <Spin spinning={loading}>
          {isAuthenticated && (
            <Menu mode="horizontal" defaultSelectedKeys={[pathname]}>
              {navigation.map(({ path, label }) => {
                return (
                  <Menu.Item key={path}>
                    <Link to={path}>
                      <span>{label}</span>
                    </Link>
                  </Menu.Item>
                );
              })}

              {client?.entities?.map((item) => (
                <Menu.Item key={`/entities/${item.slug}`}>
                  <Link to={`/entities/${item.slug}`}>
                    <span>{item.label_navigation || item.label}</span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          )}
        </Spin>
      </div>

      <div className="menuCon">
        <div className="rightMenu">
          <RightMenu />
        </div>
      </div>
    </nav>
  );
};

export default memo(TopBar);
