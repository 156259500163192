import { useQuery } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useMemo, useCallback } from 'react';

import { Tree } from '@marketreach/components/tree';
import { useClientsState } from '@marketreach/providers/ClientsProvider';

const TaxonomyEntityTree = ({
  preview,
  onPreviewEntityChange,
  query,
  entities,
  setEntities,
  entityName,
  baseEntity,
  onRemoveAssociation,
  onAddAssociation,
}) => {
  const { selected: client } = useClientsState();

  const [loading, setLoading] = useState(false);
  const [expandedKeys, onExpand] = useState([]);

  const {
    loading: loadingAttributes,
    data: entityRes,
    refetch: refetchAttributes,
  } = useQuery(query, {
    variables: { clientCode: client?.apiId },
  });

  useEffect(() => {
    if (client) {
      refetchAttributes();
    }
  }, [client, refetchAttributes]);

  useEffect(() => {
    const entitiesData = entityRes ? entityRes?.[entityName]?.data : [];

    if (!_.isEqual(entities, entitiesData)) {
      setEntities(entitiesData);
    }
  }, [entityRes, setEntities]);

  const checkedEntities = useMemo(
    () => [...new Set(baseEntity?.associations?.[entityName] || [])],
    [baseEntity, entityName]
  );

  const [previewCheckedEntities, setPreviewCheckedEntities] = useState({
    checked: [],
    halfChecked: [],
  });

  const checkChildSelected = useCallback(
    (attribute) => {
      const childs = entities.filter(
        (item) => item?.parentId === attribute._id
      );

      if (childs.length === 0) return false;

      for (const child of childs) {
        if (checkedEntities.indexOf(child._id) !== -1) {
          return true;
        }

        if (checkChildSelected(child)) {
          return true;
        }
      }

      return false;
    },
    [checkedEntities, entities]
  );

  useEffect(() => {
    setLoading(loadingAttributes || false);
  }, [loadingAttributes]);

  const remappedEntities = useMemo(() => {
    const mapped = entities.map((it) => ({
      name: it.name,
      ...it.core,
      _id: it._id,
    }));
    if (!preview) {
      return mapped;
    }

    let checkedCats = mapped.filter(
      (item) => checkedEntities.indexOf(item._id) !== -1
    );
    let stack = [...checkedCats];
    while (stack.length > 0) {
      const parents = mapped.filter((it2) =>
        stack.map((it) => it.parentId).includes(it2._id)
      );
      checkedCats = [...checkedCats, ...parents];
      stack = [...parents].filter(Boolean);
    }

    return checkedCats.filter((it, index) => checkedCats.indexOf(it) === index);
  }, [entities, preview, checkedEntities]);

  const onSelect = useCallback((selectedKeys, info) => {
    // console.log('selected', selectedKeys, info);
  }, []);

  const onCheckInPreviewMode = useCallback(
    (checkedKeys, info) => {
      setPreviewCheckedEntities(checkedKeys);
      onPreviewEntityChange(checkedKeys);
    },
    [onPreviewEntityChange, setPreviewCheckedEntities]
  );

  const onCheck = useCallback(
    (checkedKeys, info) => {
      if (baseEntity) {
        if (preview) {
          return onCheckInPreviewMode(checkedKeys, info);
        }

        if (info?.checked) {
          onAddAssociation(checkedKeys);
        } else {
          onRemoveAssociation(checkedKeys);
        }
      }
    },
    [onCheckInPreviewMode, baseEntity, preview]
  );

  const skusIntersection = useMemo(() => {
    if (!preview) return {};
    return entities.reduce(
      (acc, rec) => ({
        ...acc,
        [rec._id]: _.intersection(rec?.skus, baseEntity?.skus)?.length || 0,
      }),
      {}
    );
  }, [entities, baseEntity, preview]);

  return (
    <Tree
      checkable
      checkedKeys={preview ? previewCheckedEntities : checkedEntities}
      containerClass="entities-content-tree"
      entities={remappedEntities}
      expandedKeys={expandedKeys}
      isLoading={false}
      onCheck={onCheck}
      onExpand={onExpand}
      onSelect={onSelect}
      searchEnabled
      title={baseEntity?.name}
      totalCountLabel={`${entityName
        .slice(0, 1)
        .toUpperCase()}${entityName.slice(1)}`}
      titleRender={(entity) => {
        if (!preview || entity.children.length > 0) {
          return entity.title;
        }
        return `${entity.title} (${skusIntersection[entity.key] ?? '0'})`;
      }}
    />
  );
};

TaxonomyEntityTree.propTypes = {
  attributes: PropTypes.array,
  preview: PropTypes.bool,
  onPreviewEntityChange: PropTypes.func,
};

TaxonomyEntityTree.defaultProps = {
  attributes: [],
  preview: false,
  onPreviewEntityChange: () => {},
};

export default memo(TaxonomyEntityTree);
