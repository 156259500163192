import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './styles.scss';

const RichTextEdit = (props) => {
  const { value: propsValue } = props;

  return (
    <ReactQuill
      className="custom-rich-text-edit"
      theme="snow"
      value={propsValue}
      onChange={props.onChange}
    />
  );
};

RichTextEdit.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
};

RichTextEdit.defaultProps = {
  handleChange: () => {},
  value: '',
};

export default memo(RichTextEdit);
