import { Modal, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef } from 'react';

import { buildSlug } from '@marketreach/utils/common';

import './styles.scss';

const PropertySectionModal = (props) => {
  const { mode, targetSection, open, handleOk, handleCancel } = props;

  const [form] = Form.useForm();

  const input = useRef(null);
  const keyInput = useRef(null);
  const formRef = useRef(null);
  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current.focus();
      });
    }
  }, [open, input]);

  useEffect(() => {
    if (mode !== 'delete') {
      form.setFieldsValue({
        name: targetSection?.name || '',
        key: targetSection?.key || '',
        oldKey: targetSection?.key || '',
        type: targetSection?.type || '',
        layout: targetSection?.layout || '',
      });
    }
  }, [form, mode]);

  const onOk = () => {
    if (mode === 'add') {
      const formValue = form.getFieldValue('name');
      const keyValue = form.getFieldValue('key');
      handleOk({ name: formValue, key: keyValue });
    }
    if (mode === 'edit') {
      const formValue = form.getFieldValue('name');
      const keyValue = form.getFieldValue('key');
      const oldKeyValue = form.getFieldValue('oldKey');
      handleOk({ name: formValue, key: keyValue, oldKey: oldKeyValue });
    } else {
      handleOk(targetSection);
    }
  };

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
      key: '',
      oldKey: '',
      type: '',
      layout: '',
    });
    handleCancel();
  };
  const onFormLayoutChange = () => {};

  const getTitle = () => {
    switch (mode) {
      case 'add':
        return 'Add Section';
      case 'edit':
        return 'Edit Section';
      default:
        return 'Delete Section';
    }
  };

  function handleKeyUp(event) {
    if (event.keyCode === 13) {
      onOk();
    }
  }

  return (
    <Modal
      className="taxonomy-property-section-modal"
      title={getTitle()}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={400}
    >
      <Form
        ref={formRef}
        form={form}
        labelCol={{ span: 4 }}
        onValuesChange={onFormLayoutChange}
        onKeyUp={handleKeyUp}
      >
        {mode === 'delete' ? (
          <div>Are you sure want to delete this section?</div>
        ) : (
          <>
            <Form.Item label="Name" name="name">
              <Input
                ref={input}
                placeholder="input name"
                autoFocus
                onChange={(e) =>
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    key: buildSlug(e.target.value),
                  })
                }
              />
            </Form.Item>
            <Form.Item label="API ID" name="key">
              <Input
                ref={keyInput}
                placeholder="input api id"
                onChange={(e) =>
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    key: buildSlug(e.target.value),
                  })
                }
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

PropertySectionModal.propTypes = {
  mode: PropTypes.string,
  targetSection: PropTypes.object,
  open: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

PropertySectionModal.defaultProps = {
  mode: 'add',
  targetSection: null,
  open: false,
  handleOk: () => {},
  handleCancel: () => {},
};

export default memo(PropertySectionModal);
