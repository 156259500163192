import { Form, Input } from 'antd';
import React from 'react';

const Color = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label } = settings;

  // console.log('color preDefinedVal: ', preDefinedVal);
  return (
    <Form.Item
      name={key}
      rules={rules}
      label={label}
      initialValue={preDefinedVal?.toString() ?? null}
      hidden={hide ?? false}
      hasFeedback
    >
      <Input
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={preDefinedVal?.toString() ?? null}
        type="color"
        style={{ width: 100 }}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default React.memo(Color);
