import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Input, message, Modal, Radio } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import CustomDrawer from '@marketreach/components/drawer';
import CustomProTable from '@marketreach/components/protable';
import { PROPERTY_TYPE_RELATION } from '@marketreach/components/section/PropertyItem';
import EntityTableDisplay from '@marketreach/pages/taxonomy/properties/extra/EntityTableDisplay';
import PropertyTemplateModal from '@marketreach/pages/taxonomy/properties/manage/PropertyTemplateModal';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import { ENTITIES_LIST } from '@marketreach/services/apollo/entity';
import { capitalize, sortByOrder } from '@marketreach/utils/common';

import AddNewProperty from './add/AddNewProperty';
import EditPropertyModal from './EditPropertyModal';
import PropertySectionModal from './PropertySectionModal';

import './styles.scss';

const { Search } = Input;

const ManageProperties = (props) => {
  const {
    visible,
    sections,
    entityType,
    apiIdField,
    handleShowDrawer,
    handleAddSection,
    handleDeleteSectionOk,
    handleChangeSectionOrder,
    handleUpdateSection,
  } = props;

  const { selected: client } = useClientsState();

  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const { data: entitiesListData, loading } = useQuery(ENTITIES_LIST, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  const [entitiesList, setEntitiesList] = useState([]);

  useEffect(() => {
    if (
      entitiesListData?.listOfEntities?.data &&
      entitiesListData?.listOfEntities?.data !== entitiesList
    ) {
      setEntitiesList(
        entitiesListData.listOfEntities.data.map((item) => ({
          value: item.key,
          label: item.label,
        }))
      );
    }
  }, [entitiesListData]);

  const [state, setState] = useState('properties');
  const handleChangeState = (e) => {
    setState(e.target.value);
  };

  const [sectionModal, setSectionModal] = useState(false);
  const [sectionModalMode, setSectionModalMode] = useState('add');
  const [targetSection, setTargetSection] = useState(null);
  const onAddSection = () => {
    setSectionModalMode('add');
    setSectionModal(true);
  };
  const handleEditSection = (section) => {
    setTargetSection(section);
    setSectionModalMode('edit');
    setSectionModal(true);
  };
  const handleDeleteSection = (section) => {
    setTargetSection(section);
    setSectionModalMode('delete');
    setSectionModal(true);
  };

  const handleSectionModalOk = (section) => {
    if (section) {
      if (sectionModalMode !== 'delete') {
        if (sectionModalMode === 'add') {
          if (
            sections.findIndex(
              (sec) => sec?.key.toLowerCase() === section?.key.toLowerCase()
            ) > -1
          ) {
            message.error('Section key is duplicated, please input again.');

            return;
          }
        } else {
          if (
            section.key !== section.oldKey &&
            sections.findIndex(
              (sec) => sec.key.toLowerCase() === section.key.toLowerCase()
            ) > -1
          ) {
            message.error('Section key is duplicated, please input again.');

            return;
          }
        }
        if (sectionModalMode === 'add') {
          handleAddSection(section);
        } else {
          handleUpdateSection(section);
        }
        setSectionModal(false);
      } else {
        handleDeleteSectionOk(section);
        setSectionModal(false);
      }
    }
  };

  const Toolbar = () => (
    <div className="manage-properties-toolbar">
      <Radio.Group
        className="manage-properties-toolbar-item"
        value={state}
        onChange={handleChangeState}
      >
        <Radio.Button value="properties">Properties</Radio.Button>
        <Radio.Button value="templates">Templates</Radio.Button>
      </Radio.Group>
      <Search
        className="manage-properties-toolbar-item"
        key="search"
        placeholder="input search text"
        style={{ width: 200 }}
      />
      <Button
        className="manage-properties-toolbar-item"
        icon={<PlusOutlined />}
        onClick={onAddSection}
      >
        Add Section
      </Button>
    </div>
  );

  const footer = (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Button
        onClick={() => handleShowDrawer(false)}
        style={{ marginRight: 8 }}
      >
        Cancel
      </Button>
      <Button onClick={() => handleShowDrawer(false)} type="primary">
        Save
      </Button>
    </div>
  );

  const getDefaultValue = (item) => {
    if (item?.propertyType?.title === PROPERTY_TYPE_RELATION) {
      return (
        <EntityTableDisplay
          key={`${item?.settings?.key}ETD`}
          type={item?.settings?.entity}
          section={item?.section?.key}
          labelField={item?.settings?.entityLabelField}
          labelFieldType={item?.settings?.entityLabelFieldType}
          values={
            _.isArray(item?.settings?.defaultValue)
              ? item?.settings?.defaultValue
              : item?.settings?.defaultValue?.split(',')
          }
        />
      );
    }
    return item?.settings?.defaultValue;
  };

  const getSectionTableData = (items) => {
    const data =
      items.length > 0
        ? items.map((item) => ({
            order: item?.order,
            uniqueKey: item?.settings?.key,
            label: item?.settings?.label,
            default: getDefaultValue(item),
            type: item?.propertyType?.title,
            settings: item?.settings,
          }))
        : [];

    return data.slice().sort(sortByOrder);
  };

  const handleSortedProperties = (oldOrder, newOrder, sectionOrder) => {
    const selectedSection = sections.find(
      (section) => section.order === sectionOrder
    );

    const newProperties = JSON.parse(
      JSON.stringify(selectedSection?.properties || [])
    );
    const newIndex = newProperties.findIndex(
      (property) => property.order === newOrder
    );
    const oldIndex = newProperties.findIndex(
      (property) => property.order === oldOrder
    );
    newProperties[oldIndex].order = newOrder;
    newProperties[newIndex].order = oldOrder;

    const newSection = {
      ...selectedSection,
      properties: _.sortBy(newProperties, 'order'),
    };

    handleUpdateSection(newSection);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [propertyToBeDeleted, setPropertyToBeDeleted] = useState(null);
  const onDeleteProperty = (sectionOrder, propertyOrder) => {
    setPropertyToBeDeleted({
      sectionOrder,
      propertyOrder,
    });
    setOpenConfirmModal(true);
  };

  const handleDeletePropertyOk = () => {
    setPropertyManager({
      ...propertyManager,
      toDelete: {
        sectionOrder: propertyToBeDeleted?.sectionOrder,
        propertyOrder: propertyToBeDeleted?.propertyOrder,
      },
    });
    // handleDeleteProperty(
    //   propertyToBeDeleted?.sectionOrder,
    //   propertyToBeDeleted?.propertyOrder
    // );
    setPropertyToBeDeleted(null);
    setOpenConfirmModal(false);
  };

  const handleDeletePropertyCancel = () => {
    setOpenConfirmModal(false);
  };

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditTemplateModal, setOpenEditTemplateModal] = useState(false);

  const getPropertyBySectionAndOrder = (sectionOrder, propertyOrder) => {
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );
    const { properties } = sections[sectionIndex];
    return properties.find((item) => item.order === propertyOrder);
  };

  const onEditProperty = (sectionOrder, propertyOrder) => {
    const property = getPropertyBySectionAndOrder(sectionOrder, propertyOrder);
    console.log('property to edit: ', property);
    setPropertyManager({
      ...propertyManager,
      toEdit: property,
    });
    // setPropertyToBeEdited(property);
    setOpenEditModal(true);
  };

  const onEditTemplate = (sectionOrder, propertyOrder) => {
    const property = getPropertyBySectionAndOrder(sectionOrder, propertyOrder);
    console.log('property for template: ', property);
    setPropertyManager({
      ...propertyManager,
      toEdit: property,
    });
    setOpenEditTemplateModal(true);
  };

  const handleEditPropertyCancel = () => {
    setOpenEditModal(false);
  };

  const handleTemplateModalOk = () => {
    setOpenEditTemplateModal(false);
  };

  const content = (
    <div className="ant-pro-grid-content models-content">
      <div className="models-content-table-wrapper">
        <Toolbar />
        {sections.length > 0 &&
          sections.map((section, index) => (
            <CustomProTable
              key={`section_table_${index}`}
              className="models-content-table"
              title={capitalize(section.name)}
              data={getSectionTableData(section?.properties || [])}
              toolbar={() => [
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => handleEditSection(section)}
                >
                  <EditOutlined />
                </Button>,
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => handleDeleteSection(section)}
                >
                  <DeleteOutlined />
                </Button>,
                <Button
                  type="text"
                  shape="circle"
                  disabled={section.order === 0}
                  onClick={() => handleChangeSectionOrder(section, -1)}
                >
                  <ArrowUpOutlined />
                </Button>,
                <Button
                  type="text"
                  shape="circle"
                  disabled={section.order === sections.length - 1}
                  onClick={() => handleChangeSectionOrder(section, 1)}
                >
                  <ArrowDownOutlined />
                </Button>,
              ]}
              columns={[
                {
                  title: 'Key',
                  dataIndex: 'uniqueKey',
                },
                {
                  title: 'Label',
                  dataIndex: 'label',
                },
                {
                  title: 'Default',
                  dataIndex: 'default',
                },
                {
                  title: 'Type',
                  dataIndex: 'type',
                },
                {
                  title: 'Template',
                  dataIndex: 'template',
                  render: (text, row, rowIndex) => {
                    if (
                      row.type === PROPERTY_TYPE_RELATION ||
                      row?.settings?.fieldOptions?.isApiId
                    ) {
                      return [];
                    }
                    return [
                      <Button
                        type="text"
                        shape="circle"
                        onClick={() => onEditTemplate(index, row.order)}
                        key="template_edit"
                      >
                        <EditOutlined />
                      </Button>,
                    ];
                  },
                },
                {
                  title: 'Operation',
                  dataIndex: 'operation',
                  width: 100,
                  render: (text, row, rowIndex) => [
                    <Button
                      type="text"
                      shape="circle"
                      onClick={() => onDeleteProperty(index, row.order)}
                      key="operation_delete"
                    >
                      <DeleteOutlined />
                    </Button>,
                    <Button
                      type="text"
                      shape="circle"
                      onClick={() => onEditProperty(index, row.order)}
                      key="operation_edit"
                    >
                      <EditOutlined />
                    </Button>,
                  ],
                },
              ]}
              selectable={false}
              pagination={false}
              handleSortedData={(oldIndex, newIndex) =>
                handleSortedProperties(
                  getSectionTableData(section?.properties || [])[oldIndex]
                    ?.order,
                  getSectionTableData(section?.properties || [])[newIndex]
                    ?.order,
                  index
                )
              }
              draggable
            />
          ))}
      </div>
      <AddNewProperty
        sections={sections}
        data={{ entitiesList }}
        entityType={entityType}
        apiIdField={apiIdField}
      />
      {sectionModal && (
        <PropertySectionModal
          mode={sectionModalMode}
          targetSection={targetSection}
          open={sectionModal}
          handleOk={handleSectionModalOk}
          handleCancel={() => setSectionModal(false)}
        />
      )}
      {openConfirmModal && (
        <Modal
          className="taxonomy-property-delete-modal"
          title="Delete property"
          visible={openConfirmModal}
          onOk={handleDeletePropertyOk}
          onCancel={handleDeletePropertyCancel}
          width={400}
        >
          <div>Are sure want to delete this property?</div>
        </Modal>
      )}
      {propertyManager.toEdit && openEditModal && (
        <EditPropertyModal
          open={openEditModal}
          sections={sections}
          handleCancel={handleEditPropertyCancel}
          data={{ entitiesList }}
          entityType={entityType}
          apiIdField={apiIdField}
        />
      )}
      {propertyManager.toEdit && openEditTemplateModal && (
        <PropertyTemplateModal
          open={openEditTemplateModal}
          handleOk={handleTemplateModalOk}
          handleCancel={() => setOpenEditTemplateModal(false)}
        />
      )}
    </div>
  );

  return (
    <CustomDrawer
      title="Manage properties"
      className="properties-config"
      footer={footer}
      content={content}
      visible={visible}
      handleShowDrawer={handleShowDrawer}
    />
  );
};

ManageProperties.propTypes = {
  sections: PropTypes.array,
  visible: PropTypes.bool,
  apiIdField: PropTypes.string,
  handleShowDrawer: PropTypes.func,
  handleAddSection: PropTypes.func,
  handleDeleteSectionOk: PropTypes.func,
  handleChangeSectionOrder: PropTypes.func,
  handleUpdateSection: PropTypes.func,
  entityType: PropTypes.string,
};

ManageProperties.defaultProps = {
  sections: [],
  visible: false,
  entityType: null,
  apiIdField: null,
  handleShowDrawer: () => {},
  handleAddSection: () => {},
  handleDeleteSectionOk: () => {},
  handleChangeSectionOrder: () => {},
  handleUpdateSection: () => {},
};

export default memo(ManageProperties);
