import React, { memo } from 'react';

import InfoCard from '@marketreach/components/cards/InfoCard';

import PaymentsChart from './PaymentsChart';
import './styles.scss';

const Payments = () => (
  <InfoCard title="Payments" value="6,560" footer="Conversion Rate 60%">
    <PaymentsChart />
  </InfoCard>
);

export default memo(Payments);
