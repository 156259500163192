import { useMutation } from '@apollo/client';
import { Form, Input, Button, Typography } from 'antd';
import React, { useContext, useState, useRef, useEffect, memo } from 'react';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

import { UserContext } from '@marketreach/providers/UserProvider';
import { CLIENTS_QUERY } from '@marketreach/services/apollo/clients';
import { PROFILE_QUERY, REGISTRATION } from '@marketreach/services/apollo/user';
import { AUTH_TOKEN } from '@marketreach/services/localstorage';

import './styles.scss';

const { Text } = Typography;

const RegistrationForm = () => {
  const isUnMounted = useRef(false);
  const history = useHistory();
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const { user, setUser } = useContext(UserContext);

  const [form] = Form.useForm();

  const [error, setError] = useState('');

  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });

  const [register] = useMutation(REGISTRATION, {
    variables: {
      email: formState.email,
      password: formState.password,
      firstName: '',
      lastName: '',
    },
    refetchQueries: [PROFILE_QUERY, CLIENTS_QUERY],
  });
  const onFinish = async (values) => {
    setFormState({
      email: values.email,
      password: values.password,
    });

    const { data } = await register();
    console.log('data: ', data);
    if (data.register.success) {
      localStorage.setItem(AUTH_TOKEN, data.register.data[0].token);
      setUser(data.register.data[0].user);
      history.push('/');
    } else {
      setError(data.register.error);
    }
  };

  if (user) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="register-content">
      <Form
        form={form}
        name="register"
        className="register-form"
        onFinish={onFinish}
        scrollToFirstError
      >
        {error && <Text type="danger">{error}</Text>}
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            () => ({
              validator(rule, value) {
                if (!value || value.length >= 8) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('Use 8 characters or more for your password')
                );
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="register-form-button"
          >
            Sign up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(RegistrationForm);
