import { gql } from '@apollo/client';

export const CLIENTS_QUERY = 'Clients';

export const CLIENTS = gql`
  query Clients($filterParams: JSON) {
    clients(filterParams: $filterParams) {
      data
    }
  }
`;

export const GET_SINGLE_CLIENT_QUERY = 'getClient';

export const SINGLE_CLIENT = gql`
  query getClient($clientCode: String!) {
    getClient(clientCode: $clientCode) {
      data
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!, $apiId: String!) {
    createClient(name: $name, apiId: $apiId) {
      success
      error
      data
    }
  }
`;

export const UPDATE_CLIENTS = gql`
  mutation updateClients($clients: [JSON], $propertiesActions: [JSON]) {
    updateClients(clients: $clients, propertiesActions: $propertiesActions) {
      success
      error
      data
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation DeleteClient($_id: ID!, $apiId: String!) {
    deleteClient(_id: $_id, apiId: $apiId) {
      success
      error
      data
    }
  }
`;

export const ADD_SECTION = gql`
  mutation addSection($clientCode: String!, $section: JSON!, $type: String!) {
    addSection(clientCode: $clientCode, section: $section, type: $type) {
      success
      error
      data
    }
  }
`;

export const REMOVE_SECTION = gql`
  mutation removeSection($clientCode: String!, $index: Int!, $type: String!) {
    removeSection(clientCode: $clientCode, index: $index, type: $type) {
      success
      error
      data
    }
  }
`;

export const CLIENT_DATA = gql`
  query clientData($clientCode: String!) {
    clientData(clientCode: $clientCode) {
      data
    }
  }
`;

export const CLIENT_DATA_QUERY = 'clientData';

export const SAVE_CLIENT_DATA = gql`
  mutation saveClientData($id: ID, $clientCode: String!, $data: JSON!) {
    saveClientData(_id: $id, clientCode: $clientCode, data: $data) {
      success
      error
      data
    }
  }
`;

export const ADD_CUSTOM_ENTITY = gql`
  mutation addCustomEntity($clientCode: String!, $entity: JSON!) {
    addCustomEntity(clientCode: $clientCode, entity: $entity) {
      success
      error
      data
    }
  }
`;

export const UPDATE_CUSTOM_ENTITY = gql`
  mutation updateCustomEntity(
    $clientCode: String!
    $entity: JSON!
    $oldSlug: String!
  ) {
    updateCustomEntity(
      clientCode: $clientCode
      entity: $entity
      oldSlug: $oldSlug
    ) {
      success
      error
      data
    }
  }
`;

export const REMOVE_CUSTOM_ENTITY = gql`
  mutation removeCustomEntity($clientCode: String!, $slug: String!) {
    removeCustomEntity(clientCode: $clientCode, slug: $slug) {
      success
      error
      data
    }
  }
`;

export const COPY_ENTITY_SETTINGS = gql`
  mutation CopyEntotySettings(
    $clientCode: String!
    $sourceEntity: String!
    $destinationEntity: String!
    $sourceClient: String!
  ) {
    copyEntitySettings(
      clientCode: $clientCode
      sourceEntity: $sourceEntity
      destinationEntity: $destinationEntity
      sourceClient: $sourceClient
    ) {
      success
      error
      data
    }
  }
`;

export const APPLY_TEMPLATES = gql`
  mutation ApplyTemplates($clientCode: String!) {
    applyTemplates(clientCode: $clientCode) {
      success
      error
      data
    }
  }
`;
