import React, { memo, useState, useCallback, useContext } from 'react';

import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { setTaxonomyTreeExpandedState } from '@marketreach/services/localstorage';

import { TaxonomySidebarHeader } from './SidebarHeader';
import { SidebarTree } from './SidebarTree';

import './styles.scss';

const TaxonomySidebar = () => {
  const { selectedType, setSelectedType } = useContext(TaxonomyContext);

  const [expandedKeys, setExpandedKeys] = useState([]);

  const onChangeState = (e) => {
    setSelectedType(e.target.value);
  };

  const handleExpandedKeys = useCallback(
    (keys) => {
      setExpandedKeys(keys);
      setTaxonomyTreeExpandedState(keys);
    },
    [setExpandedKeys]
  );

  const [bulkAction, setBulkAction] = useState(null);

  const handleChangeBulkExpand = useCallback((e) => {
    if (e.target.value === 'expandAll') setBulkAction('expandAll');
    if (e.target.value === 'collapseAll') setBulkAction('collapseAll');
  }, []);

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="ant-pro-grid-content taxonomy-sidebar">
      <TaxonomySidebarHeader
        handleChangeBulkExpand={handleChangeBulkExpand}
        setOpenModal={setOpenModal}
        onChangeState={onChangeState}
        selectedType={selectedType}
      />
      <SidebarTree
        setOpenModal={setOpenModal}
        openModal={openModal}
        expandedKeys={expandedKeys}
        setExpandedKeys={handleExpandedKeys}
        bulkAction={bulkAction}
        setBulkAction={setBulkAction}
      />
    </div>
  );
};

TaxonomySidebar.propTypes = {};

TaxonomySidebar.defaultProps = {};

export default memo(TaxonomySidebar);
