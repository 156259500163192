import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';

import './styles.scss';

const CustomDrawer = (props) => {
  const { className, title, footer, content, visible, handleShowDrawer } =
    props;

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
    handleShowDrawer(false);
  };

  return (
    <Drawer
      className={className}
      title={title}
      width={1100}
      onClose={onClose}
      visible={visible ?? open}
      footer={footer}
      getContainer={false}
    >
      {content}
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  footer: PropTypes.node,
  content: PropTypes.node,
  visible: PropTypes.bool,
  handleShowDrawer: PropTypes.func,
};

CustomDrawer.defaultProps = {
  className: '',
  title: '',
  footer: undefined,
  content: undefined,
  visible: undefined,
  handleShowDrawer: () => {},
};

export default memo(CustomDrawer);
