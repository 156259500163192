import { Modal, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';

import PropertyItem, {
  PROPERTY_TYPE_CODE_EDIT,
} from '@marketreach/components/section/PropertyItem';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';

import './styles.scss';

const PropertyTemplateModal = (props) => {
  const { open, handleOk, handleCancel } = props;

  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const { toEdit: property } = propertyManager;

  const [form] = Form.useForm();

  const [code, setCode] = useState(property?.settings?.template);

  const input = useRef(null);
  const formRef = useRef(null);
  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current.focus();
      });
    }
  }, [open, input]);

  useEffect(() => {
    form.setFieldsValue({
      code: property?.template || '',
    });
  }, [form]);

  const onOk = () => {
    setPropertyManager({
      ...propertyManager,
      toEdit: {
        ...property,
        settings: {
          ...property?.settings,
          template: code,
        },
      },
      needSave: true,
    });

    handleOk();
  };

  const onCancel = () => {
    form.setFieldsValue({
      code: '',
    });
    handleCancel();
  };
  const onFormLayoutChange = () => {};

  return (
    <Modal
      className="taxonomy-property-section-modal"
      title={'Edit template'}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={window.innerWidth * 0.66}
    >
      <Form
        ref={formRef}
        form={form}
        labelCol={{ span: 4 }}
        onValuesChange={onFormLayoutChange}
      >
        <PropertyItem
          key={'code'}
          type={PROPERTY_TYPE_CODE_EDIT}
          defaultValue={code}
          onChange={(e) => {
            const val = e?.target ? e?.target.value : e?.toString();
            console.log('templateva: ', val);
            setCode(val);
          }}
        />
      </Form>
    </Modal>
  );
};

PropertyTemplateModal.propTypes = {
  open: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

PropertyTemplateModal.defaultProps = {
  open: false,
  handleOk: () => {},
  handleCancel: () => {},
};

export default memo(PropertyTemplateModal);
