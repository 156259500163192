import { gql } from '@apollo/client';

export const GET_SINGLE_FILE_QUERY = 'getFile';

export const SINGLE_FILE = gql`
  query getFile($clientCode: String!, $id: ID!) {
    file(clientCode: $clientCode, id: $id) {
      data
    }
  }
`;
