export const RULE_CHARS_LIMIT_BETWEEN = 'between';
export const RULE_CHARS_LIMIT_BIGGER_THAN = 'bigger_than';
export const RULE_CHARS_LIMIT_SMALLER_THAN = 'smaller_than';

const build = (settings) => {
  const rules = [];
  if (settings?.limit) {
    switch (settings?.limit) {
      case RULE_CHARS_LIMIT_BETWEEN:
        rules.push(...[{ min: settings?.min }, { max: settings?.max }]);
        break;
      case RULE_CHARS_LIMIT_BIGGER_THAN:
        rules.push({ min: settings?.min });
        break;
      case RULE_CHARS_LIMIT_SMALLER_THAN:
        rules.push({ max: settings?.max });
        break;
      default:
        break;
    }
  }

  return rules;
};

export default build;
