import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { memo } from 'react';

import InfoCard from '@marketreach/components/cards/InfoCard';

import './styles.scss';

const { Text } = Typography;

const TotalSales = () => (
  <InfoCard title="Total Sales" value="$ 123,346" footer="Daily Sales￥12,423">
    <div className="change-content">
      <div>
        <Text>WoW Change</Text>
        <Text className="change-value">12%</Text>
        <CaretUpOutlined style={{ color: '#f5222d', marginRight: 12 }} />
      </div>
      <div>
        <Text>DoD Change</Text>
        <Text className="change-value">11%</Text>
        <CaretDownOutlined style={{ color: '#52c41a' }} />
      </div>
    </div>
  </InfoCard>
);

export default memo(TotalSales);
