import { Form } from 'antd';
import React from 'react';

import MonacoEdit from '@marketreach/components/monaco';

const CodeEdit = (props) => {
  const { preDefinedVal, hide, rules, settings, onChange } = props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      label={label}
      initialValue={
        typeof preDefinedVal == 'object'
          ? JSON.stringify(preDefinedVal)
          : preDefinedVal?.toString()
      }
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(data) => {
        return data;
      }}
    >
      <MonacoEdit
        defaultValue={
          typeof preDefinedVal == 'object'
            ? JSON.stringify(preDefinedVal)
            : preDefinedVal?.toString()
        }
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default React.memo(CodeEdit);
