import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      error
      data
    }
  }
`;

export const LOGIN_QUERY = 'Login';

export const REGISTRATION = gql`
  mutation Register(
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
  ) {
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      error
      data
    }
  }
`;

export const PROFILE = gql`
  query Profile {
    profile {
      data
    }
  }
`;

export const PROFILE_QUERY = 'Profile';

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $newPassword: String
  ) {
    updateProfile(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      newPassword: $newPassword
    ) {
      success
      error
      data
    }
  }
`;

export const USERS_LIST = gql`
  query GetUsersList {
    getUsersList {
      data
    }
  }
`;

export const USERS_LIST_QUERY = 'GetUsersList';

export const LINK_USER_WITH_CLIENTS = gql`
  mutation LinkWithClients($clients: [String]!, $userId: String!) {
    linkWithClients(clients: $clients, userId: $userId) {
      success
      error
      data
    }
  }
`;

export const SET_PERMISSIONS = gql`
  mutation SetPermissions($permissions: [String]!, $userId: String!) {
    setPermissions(permissions: $permissions, userId: $userId) {
      success
      error
      data
    }
  }
`;

export const GET_ROLES = gql`
  query GetRolesList {
    getRolesList {
      data
    }
  }
`;

export const GET_ROLES_QUERY = 'GetRolesList';

export const SET_ROLES = gql`
  mutation SetRoles($roles: [String]!, $userId: String!) {
    setRoles(roles: $roles, userId: $userId) {
      success
      error
      data
    }
  }
`;

export const SET_SETTING = gql`
  mutation setSetting($key: String!, $value: JSON!, $clientCode: String) {
    setSetting(key: $key, value: $value, clientCode: $clientCode) {
      success
      error
      data
    }
  }
`;

export const GET_SETTING = gql`
  query GetSetting($key: String!, $clientCode: String) {
    getSetting(key: $key, clientCode: $clientCode) {
      data
    }
  }
`;

export const GET_SETTING_QUERY = 'GetSetting';
