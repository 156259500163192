import React, { memo } from 'react';

import './styles.scss';

const FeedsPage = () => (
  <div className="ant-pro-grid-content clients-content">Feeds page</div>
);

FeedsPage.propTypes = {};

FeedsPage.defaultProps = {};

export default memo(FeedsPage);
