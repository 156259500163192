import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Spin,
  Typography,
} from 'antd';
import React, { memo, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import {
  ADD_CUSTOM_ENTITY,
  GET_SINGLE_CLIENT_QUERY,
  REMOVE_CUSTOM_ENTITY,
  SINGLE_CLIENT,
  UPDATE_CUSTOM_ENTITY,
} from '@marketreach/services/apollo/clients';
import { buildSlug } from '@marketreach/utils/common';
import { buildRule } from '@marketreach/utils/validation';

import './styles.scss';

const { TextArea } = Input;

const CustomEntity = () => {
  const { selected } = useClientsState();

  const { loading, data: clientData } = useQuery(SINGLE_CLIENT, {
    variables: {
      clientCode: selected?.apiId,
    },
  });

  const client = clientData?.getClient?.data;

  const initFormState = {
    label: '',
    label_plural: '',
    slug: '',
    label_navigation: '',
    description: '',
  };

  const [clientCreationInProgress, setClientCreationInProgress] =
    useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState(undefined);
  const [entityOldSlug, setEntityOldSlug] = useState(undefined);

  const [addCustomEntity, { loading: creationLoading }] =
    useMutation(ADD_CUSTOM_ENTITY);
  const [updateCustomEntity, { loading: updateLoading }] =
    useMutation(UPDATE_CUSTOM_ENTITY);
  const [removeCustomEntity, { loading: removingLoading }] =
    useMutation(REMOVE_CUSTOM_ENTITY);

  const [form] = Form.useForm();

  const columns = [
    { title: 'Name', dataIndex: 'label' },
    { title: 'Name (Plural)', dataIndex: 'label_plural' },
    { title: 'Name (Navigation)', dataIndex: 'label_navigation' },
    { title: 'Description (optional)', dataIndex: 'description' },
    { title: 'API ID', dataIndex: 'slug' },
    { title: 'API ID Field', dataIndex: 'apiID' },
    {
      title: 'Operation',
      dataIndex: 'operation',
      sorter: false,
      width: 10,
      render: (text, row) => [
        <Button
          key="edit"
          type="text"
          shape="circle"
          onClick={() => handleEdit(row)}
        >
          <EditOutlined />
        </Button>,
        <Button
          key="delete"
          type="text"
          shape="circle"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete(row);
          }}
        >
          <DeleteOutlined />
        </Button>,
      ],
    },
  ];

  const toolbar = () => [
    <Button type="primary" onClick={handleTriggerAddEntity}>
      <PlusOutlined /> Add New
    </Button>,
  ];

  const handleTriggerAddEntity = () => {
    setEntityToEdit(undefined);
    form.setFieldsValue(initFormState);
    setShowAddNewModal(!showAddNewModal);
  };

  const isFormNotValid = () =>
    form.getFieldsError().some((item) => item.errors.length > 0);

  const handleCreateCustomEntity = () => {
    if (!isFormNotValid()) {
      setClientCreationInProgress(true);

      if (entityToEdit) {
        updateCustomEntity({
          variables: {
            clientCode: client?.apiId,
            entity: form.getFieldsValue(),
            oldSlug: entityOldSlug,
          },
          refetchQueries: [GET_SINGLE_CLIENT_QUERY],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Custom entity was updated');
            form.setFieldsValue(initFormState);
            setShowAddNewModal(false);
            setClientCreationInProgress(false);
            setEntityToEdit(undefined);
          })
          .catch((e) => {
            setClientCreationInProgress(false);
          });
      } else {
        addCustomEntity({
          variables: {
            clientCode: client?.apiId,
            entity: form.getFieldsValue(),
          },
          refetchQueries: [GET_SINGLE_CLIENT_QUERY],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Custom entity was created');
            form.setFieldsValue(initFormState);
            setShowAddNewModal(false);
            setClientCreationInProgress(false);
          })
          .catch((e) => {
            setClientCreationInProgress(false);
          });
      }
    }
  };

  const handleDelete = (row) => {
    if (window.confirm('Do you really want to remove custom entity?')) {
      removeCustomEntity({
        variables: {
          clientCode: client?.apiId,
          slug: row.slug,
        },
        refetchQueries: [GET_SINGLE_CLIENT_QUERY],
      }).then(() => {
        message.info('Custom entity was removed');
      });
    }
  };

  const onNameChange = (ent) => {
    form.setFieldsValue({
      slug: buildSlug(ent.target.value),
      label_plural: ent.target.value,
      label_navigation: ent.target.value,
    });
    form.validateFields();
  };

  const handleEdit = (row) => {
    setEntityToEdit(row);
    form.setFieldsValue(row);
    setShowAddNewModal(true);
    setEntityOldSlug(row.slug);
  };

  return (
    <div className="ant-pro-grid-content settings-content-locale">
      <Layout className="api-access-main">
        <div>
          <h4 className="capitalized">Custom entities</h4>

          <Spin
            spinning={
              loading || creationLoading || removingLoading || updateLoading
            }
          >
            {client?.entities?.length > 0 && (
              <CustomProTable
                data={client?.entities}
                columns={columns}
                toolbar={toolbar}
                handleRowClick={handleEdit}
              />
            )}
            {!(client?.entities?.length > 0) && (
              <Layout>
                <div className="toolbar">
                  <div className="right">{toolbar()}</div>
                </div>
              </Layout>
            )}
          </Spin>
        </div>
      </Layout>

      <Modal
        className="custom-entity-edit-modal"
        title={entityToEdit ? 'Edit Custom Entity' : 'Add New Custom Entity'}
        visible={showAddNewModal}
        onOk={handleCreateCustomEntity}
        onCancel={handleTriggerAddEntity}
      >
        <Spin spinning={clientCreationInProgress}>
          <div>
            <Form form={form} name={'entity_add_form'}>
              <Typography className="custom-entity-edit-modal-label">
                Name
              </Typography>
              <Form.Item
                name="label"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (!client?.entities) {
                        return Promise.resolve();
                      }
                      return client?.entities
                        ?.map((entity) => entity.slug)
                        .indexOf(buildSlug(value)) === -1
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                    message: 'This value already used',
                  },
                ]}
              >
                <Input
                  onChange={onNameChange}
                  rules={[
                    buildRule(
                      'unique',
                      true,
                      client?.entities?.map((entity) => entity.slug)
                    ),
                  ]}
                  placeholder={
                    'Descriptive name (singular) for the new custom entity (ex: Post)'
                  }
                />
              </Form.Item>

              <Typography className="custom-entity-edit-modal-label">
                Name (Plural)
              </Typography>
              <Form.Item name="label_plural" rules={[{ required: true }]}>
                <Input
                  placeholder={
                    'Descriptive name (plural) for the new custom entity (ex: Posts)'
                  }
                />
              </Form.Item>

              <Typography className="custom-entity-edit-modal-label">
                Name (Navigation)
              </Typography>
              <Form.Item name="label_navigation" shouldUpdate={true}>
                <Input
                  placeholder={
                    'Name displayed in the top navigation menu (ex: Blog)'
                  }
                />
              </Form.Item>

              <Typography className="custom-entity-edit-modal-label">
                Description (optional)
              </Typography>
              <Form.Item name="description" shouldUpdate={true}>
                <TextArea
                  autoSize={{ minRows: 2 }}
                  placeholder={
                    'Displays a text hint for content editors and API users (ex: Manage Blog Post'
                  }
                />
              </Form.Item>

              <Typography className="custom-entity-edit-modal-label">
                API ID
              </Typography>
              <Form.Item
                name="slug"
                shouldUpdate={true}
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (!client?.entities) {
                        return Promise.resolve();
                      }
                      return client?.entities
                        ?.map((entity) => entity.slug.toLowerCase())
                        .indexOf(buildSlug(value)) === -1
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                    message: 'This value already used',
                  },
                ]}
              >
                <Input
                  rules={[
                    buildRule(
                      'unique',
                      true,
                      client?.entities?.map((entity) => entity.slug)
                    ),
                  ]}
                  placeholder={
                    'ID used when accessing this custom entity via the API'
                  }
                />
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

CustomEntity.propTypes = {};

CustomEntity.defaultProps = {};

export default memo(CustomEntity);
