import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Form, Button, List, Modal, Descriptions } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { buildSlug } from '@marketreach/utils/common';

const ListOptions = (props) => {
  const { handleChangeState, settings } = props;
  const [form] = Form.useForm();

  const [items, setItems] = useState(settings?.list_options || []);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    handleChangeState('settings', 'list_options', items);
  }, [items]);

  const toggleShowModal = () => {
    setShowAddModal(!showAddModal);
  };

  const isFormNotValid = () =>
    form.getFieldsError().some((item) => item.errors.length > 0);

  const handleOk = () => {
    form.validateFields();
    if (!isFormNotValid()) {
      setItems(_.sortBy([...items, form.getFieldsValue()], 'label'));
      clear();
      toggleShowModal();
    }
  };

  const handleCancel = () => {
    clear();
    toggleShowModal();
  };

  const clear = () => {
    form.setFieldsValue({
      label: '',
      value: '',
    });
  };

  const onNameChange = (event) => {
    form.setFieldsValue({
      value: buildSlug(event.target.value),
    });
  };

  const removeItem = (index) => {
    const values = _.clone(items);
    values.splice(index, 1);
    setItems([...values]);
  };

  return (
    <>
      <List
        header={<div>List options</div>}
        footer={
          <div>
            <Button onClick={toggleShowModal} block icon={<PlusOutlined />}>
              Add new
            </Button>
          </div>
        }
        bordered
        dataSource={items}
        renderItem={(item, index) => (
          <List.Item>
            <Descriptions>
              <Descriptions.Item label="Label">{item.label}</Descriptions.Item>
              <Descriptions.Item label="Value">{item.value}</Descriptions.Item>
              <Descriptions.Item>
                <MinusCircleOutlined onClick={() => removeItem(index)} />
              </Descriptions.Item>
            </Descriptions>
          </List.Item>
        )}
      />
      {showAddModal && (
        <Modal
          title="Add list item"
          visible={showAddModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form}>
            <Form.Item
              name="label"
              label="Label"
              rules={[{ required: true }]}
              autoFocus
            >
              <Input onChange={onNameChange} autoFocus />
            </Form.Item>
            <Form.Item name="value" label="Value" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ListOptions;
