import React, { memo } from 'react';

import TrendRanking from '../ranking';

import VisitsTrendChart from './VisitsTrendChart';

import './styles.scss';

const VisitsTrend = () => {
  return (
    <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-16">
        <VisitsTrendChart />
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-8">
        <TrendRanking title="Visits Ranking" />
      </div>
    </div>
  );
};

export default memo(VisitsTrend);
