import { Form } from 'antd';
import React from 'react';

import RichTextEdit from '@marketreach/components/quill';

const RichEdit = (props) => {
  const { preDefinedVal, hide, rules, settings, onChange } = props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      label={label}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(data) => {
        return data;
      }}
    >
      <RichTextEdit value={preDefinedVal?.toString()} handleChange={onChange} />
    </Form.Item>
  );
};

export default RichEdit;
