import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTES } from '@marketreach/services/apollo/attributes';
import { CATEGORIES } from '@marketreach/services/apollo/categories';

import SidebarAttributeModal from './AttributeModal';
import SidebarCategoryModal from './CategoryModal';
import SidebarEntityTree from './SidebarEntityTree';

import './styles.scss';

export const SidebarTree = memo(
  ({
    openModal,
    setOpenModal,
    expandedKeys,
    setExpandedKeys,
    bulkAction,
    setBulkAction,
  }) => {
    const {
      selectedAttributeId,
      selectedCategoryId,
      selectedType,
      selectedCategory,
      selectedAttribute,
      categories,
      setSelectedCategoryId,
      setCategories,
      attributes,
      setSelectedAttributeId,
      setAttributes,
    } = useContext(TaxonomyContext);

    const addKeyToExpand = useCallback(
      (keyID) => {
        if (!keyID) return;
        const newExpandedKeys = [...(expandedKeys || []), keyID];
        setExpandedKeys(newExpandedKeys);
      },
      [expandedKeys]
    );

    const [selectedKeys, setSelectedKeys] = useState([]);
    useEffect(() => {
      if (selectedAttributeId || selectedCategoryId) {
        setSelectedKeys(
          selectedType === 'category'
            ? [selectedCategoryId]
            : [selectedAttributeId]
        );
      }
    }, [selectedType, selectedAttributeId, selectedCategoryId]);

    const [openSubModal, setOpenSubModal] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [attributeId, setAttributeId] = useState(null);
    const [subMode, setSubMode] = useState(null);
    const handleOk = (keyID = null) => {
      if (keyID) addKeyToExpand(keyID);
      setOpenModal(false);
      setOpenSubModal(false);
    };
    const handleCancel = () => {
      setOpenModal(false);
      setOpenSubModal(false);
    };
    const [subMenuEntity, setSubMenuEntity] = useState(null);
    const handleClickSubmenu = useCallback(
      (mode, id, entity) => {
        if (!['delete', 'edit', 'bulk_create'].includes(mode)) {
          return;
        }
        setSubMenuEntity(entity);
        if (selectedType === 'category') {
          setCategoryId(id);
        } else {
          setAttributeId(id);
        }
        setSubMode(mode);
        setOpenSubModal(true);
      },
      [
        setAttributeId,
        setCategoryId,
        setSubMenuEntity,
        categoryId,
        attributeId,
        setSubMode,
        setOpenSubModal,
      ]
    );

    const subModalTitle = useMemo(() => {
      switch (subMode) {
        case 'create':
          return selectedType === 'category'
            ? 'Create category'
            : 'Create attribute';
        case 'edit':
          return selectedType === 'category'
            ? 'Edit category'
            : 'Edit attribute';
        case 'delete':
          return selectedType === 'category'
            ? `Delete category '${subMenuEntity?.title}'`
            : `Delete attribute '${subMenuEntity?.title}'`;
        case 'bulk_create':
          return selectedType === 'category'
            ? 'Create New Categories'
            : 'Create New Attributes';
        default:
          return null;
      }
    }, [subMode, selectedType, subMenuEntity]);

    if (selectedType === 'category') {
      return (
        <>
          <SidebarEntityTree
            entityQuery={CATEGORIES}
            entities={categories}
            selectedEntityId={selectedCategoryId}
            setSelectedEntityId={setSelectedCategoryId}
            setEntities={setCategories}
            entityName="categories"
            selectedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            handleExpandedKeys={setExpandedKeys}
            handleClickSubmenu={handleClickSubmenu}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
          />
          <SidebarCategoryModal
            title="Add Root Category"
            mode="root"
            open={openModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            setSelectedEntityId={setSelectedCategoryId}
          />
          <SidebarCategoryModal
            title={subModalTitle}
            mode={subMode}
            open={openSubModal}
            entity={subMenuEntity}
            handleOk={handleOk}
            handleCancel={handleCancel}
            setSelectedEntityId={setSelectedCategoryId}
          />
        </>
      );
    }

    return (
      <>
        <SidebarEntityTree
          entityQuery={ATTRIBUTES}
          entities={attributes}
          selectedEntityId={selectedAttributeId}
          setSelectedEntityId={setSelectedAttributeId}
          setEntities={setAttributes}
          entityName="attributes"
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          handleExpandedKeys={setExpandedKeys}
          handleClickSubmenu={handleClickSubmenu}
          bulkAction={bulkAction}
          setBulkAction={setBulkAction}
        />{' '}
        <SidebarAttributeModal
          title="Add Attribute Group"
          mode="root"
          open={openModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          setSelectedEntityId={setSelectedAttributeId}
        />
        <SidebarAttributeModal
          title={subModalTitle}
          mode={subMode}
          open={openSubModal}
          entity={subMenuEntity}
          handleOk={handleOk}
          handleCancel={handleCancel}
          setSelectedEntityId={setSelectedAttributeId}
        />
      </>
    );
  }
);
