import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';

import { PROPERTY_TYPE_UPLOAD } from '@marketreach/components/section/PropertyItem';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA } from '@marketreach/services/apollo/entity';
import { getFileUploadImage } from '@marketreach/utils/files';

import './styles.scss';
/**
 * Component for display entity by ids
 *
 * @param props
 * @return {JSX.Element|*|string|string}
 * @constructor
 */
const EntityTableDisplay = (props) => {
  const { type, labelField, labelFieldType, values, section } = props;
  const { selected: client } = useClientsState();

  const [pageNumber] = useState(1);
  const [pageSize] = useState(5000);

  const [searchParams] = useState({ _id: values });
  const [sortParams] = useState({});

  const [entities, setEntities] = useState([]);

  const { loading, data: entityData } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: pageNumber,
      pageSize,
      searchParams,
      sortParams,
    },
  });

  useEffect(() => {
    if (entityData?.entityData?.data) {
      setEntities(entityData?.entityData?.data);
    }
  }, [entityData]);

  return (
    <Spin spinning={loading}>
      {entities.length > 0 &&
        entities.map((entity) => {
          const entityData = entity?.properties[section][labelField];

          const data = [];
          if (!_.isArray(entityData)) {
            data.push(entityData);
          } else {
            data.push(...entityData);
          }

          if (labelFieldType === PROPERTY_TYPE_UPLOAD) {
            return data.map((file) => {
              const imageUrl = getFileUploadImage(file);
              return (
                <p>
                  <img src={imageUrl} className={'tableImage'} />
                </p>
              );
            });
          }
          return data?.map((text) => <p>{text?.toString()}</p>);
        })}
      {entities.length === 0 && <p />}
    </Spin>
  );

  return values
    ? values.map((val) => <p>{val?.toString()}</p>)
    : values?.toString() || '';
};

EntityTableDisplay.propTypes = {
  type: PropTypes.string,
  section: PropTypes.string,
  labelField: PropTypes.string,
  labelFieldType: PropTypes.string,
  values: PropTypes.array,
};

EntityTableDisplay.defaultProps = {
  type: null,
  section: null,
  labelField: null,
  labelFieldType: null,
  values: null,
};

export default memo(EntityTableDisplay);
