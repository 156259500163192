import { useMutation, useQuery } from '@apollo/client';
import {
  Modal,
  Form,
  Input,
  message,
  Spin,
  Typography,
  Checkbox,
  Select,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo,
} from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CATEGORIES_QUERY_NAME,
  CATEGORIES,
  BULK_CREATE_CATEGORY,
} from '@marketreach/services/apollo/categories';
import {
  CREATE_HISTORY,
  HISTORIES_BY_PAGE_QUERY_NAME,
  HISTORIES_QUERY_NAME,
} from '@marketreach/services/apollo/history';
import { GET_PRODUCT_KEYS } from '@marketreach/services/apollo/products';
import { getChildItems } from '@marketreach/utils/categories';
import { getLeafs, useTreeData } from '@marketreach/utils/common';

import './styles.scss';

const { Text } = Typography;
const { Option } = Select;

const SidebarCategoryModal = ({
  title,
  mode,
  entity,
  open,
  handleOk,
  handleCancel,
  setSelectedEntityId,
}) => {
  const { selected: client } = useClientsState();

  const { categories } = useContext(TaxonomyContext);

  const { data: baseFieldsData } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client.apiId,
    },
  });
  const [addCategory] = useMutation(CREATE_CATEGORY);
  const [massCategoryCreate] = useMutation(BULK_CREATE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [form] = Form.useForm();

  const [categoryInProcess, setCategoryInProcess] = useState(false);
  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  const [productField, setProductField] = useState([]);

  const input = useRef(null);
  const formRef = useRef(null);
  const category = entity;
  const categoryId = entity?.key ?? null;

  const childs = useMemo(() => {
    if (!category) return [];
    return getLeafs(category.children).map((it) => it.key);
  }, [categoryId]);

  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current.focus();
      });
    }
  }, [open, input]);

  useEffect(() => {
    if (baseFieldsData?.getProductKeys?.data) {
      setProductField(
        baseFieldsData?.getProductKeys?.data.filter(
          (item) => item?.mainType.toLowerCase() === 'string'
        )
      );
    }
  }, [baseFieldsData]);

  useEffect(() => {
    if (category && mode === 'edit') {
      form.setFieldsValue({
        name: category.name,
      });
    } else {
      form.setFieldsValue({
        name: '',
        autoGenerated: false,
        productField: null,
      });
      setIsAutoGenerated(false);
    }
  }, [category, form, mode]);

  const onOk = () => {
    const formValue = form.getFieldsValue();

    if (
      !isAutoGenerated &&
      !formValue?.name &&
      !['delete', 'bulk_create', 'root'].includes(mode)
    ) {
      message.error('Please input category name');

      return;
    }

    if (
      !isAutoGenerated &&
      !formValue?.names &&
      (mode === 'bulk_create' || mode === 'root')
    ) {
      message.error('Please input categories names');

      return;
    }

    if (
      isAutoGenerated &&
      !formValue?.productField &&
      (mode === 'bulk_create' || mode === 'root')
    ) {
      message.error('Please select base field');
      return;
    }

    if (
      (!categoryId || mode === 'create') &&
      mode !== 'root' &&
      mode !== 'delete'
    ) {
      setCategoryInProcess(true);
      addCategory({
        variables: {
          ...formValue,
          type: 'category',
          layout: 'category',
          clientCode: client?.apiId,
          parentId: categoryId,
          isAutoGenerated,
        },
        refetchQueries: [CATEGORIES_QUERY_NAME, CATEGORIES],
        awaitRefetchQueries: true,
      })
        .then(() => {
          createHistory({
            variables: {
              clientCode: client?.apiId,
              action: category
                ? `${formValue?.name} was added as a subcategory of ${category?.name}`
                : `${formValue?.name} was added as a root category`,
              account: 'Admin',
              ipAddress: '127.0.0.1',
              type: 'category',
              parentId: category?._id,
            },
            refetchQueries: [
              HISTORIES_QUERY_NAME,
              HISTORIES_BY_PAGE_QUERY_NAME,
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            form.setFieldsValue({
              name: '',
              autoGenerated: false,
              productField: null,
            });
            setCategoryInProcess(false);
            setIsAutoGenerated(false);
            handleOk(category?._id);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (categoryId && mode === 'edit') {
      setCategoryInProcess(true);
      updateCategory({
        variables: {
          ...formValue,
          type: 'category',
          layout: 'category',
          clientCode: client?.apiId,
          _id: categoryId,
        },
        refetchQueries: [CATEGORIES_QUERY_NAME, CATEGORIES],
        awaitRefetchQueries: true,
      })
        .then(() => {
          createHistory({
            variables: {
              clientCode: client?.apiId,
              action: `Category name ${category?.name} was changed to ${formValue?.name}`,
              account: 'Admin',
              ipAddress: '127.0.0.1',
              type: 'category',
              parentId: category?._id,
            },
            refetchQueries: [
              HISTORIES_QUERY_NAME,
              HISTORIES_BY_PAGE_QUERY_NAME,
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            setCategoryInProcess(false);
            handleOk(category?._id);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (categoryId && mode === 'delete') {
      if (category) {
        setCategoryInProcess(true);
        deleteCategory({
          variables: {
            clientCode: client?.apiId,
            _id: categoryId,
            categories: [categoryId, ...(childs || [])],
            associations: category.associations,
          },
          refetchQueries: [CATEGORIES_QUERY_NAME, CATEGORIES],
          awaitRefetchQueries: true,
        })
          .then(() => {
            setSelectedEntityId(null);

            createHistory({
              variables: {
                clientCode: client?.apiId,
                action: `Category ${category?.name} was deleted`,
                account: 'Admin',
                ipAddress: '127.0.0.1',
                type: 'category',
                parentId: category?._id,
              },
              refetchQueries: [
                HISTORIES_QUERY_NAME,
                HISTORIES_BY_PAGE_QUERY_NAME,
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              setCategoryInProcess(false);
              handleOk();
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    if (
      (categoryId && mode === 'bulk_create') ||
      (!categoryId && mode === 'root')
    ) {
      setCategoryInProcess(true);
      massCategoryCreate({
        variables: {
          ...formValue,
          type: 'category',
          layout: 'category',
          clientCode: client?.apiId,
          parentId: categoryId,
          isAutoGenerated,
        },
        refetchQueries: [CATEGORIES_QUERY_NAME, CATEGORIES],
        awaitRefetchQueries: true,
      })
        .then(() => {
          createHistory({
            variables: {
              clientCode: client?.apiId,
              action: `Mass category creation ${formValue?.names}`,
              account: 'Admin',
              ipAddress: '127.0.0.1',
              type: 'category',
              parentId: category?._id,
            },
            refetchQueries: [
              HISTORIES_QUERY_NAME,
              HISTORIES_BY_PAGE_QUERY_NAME,
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            form.setFieldsValue({
              names: '',
              autoGenerated: false,
              productField: null,
            });
            setCategoryInProcess(false);
            setIsAutoGenerated(false);
            handleOk(category?._id);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
      autoGenerated: false,
      productField: null,
    });
    setIsAutoGenerated(false);
    handleCancel();
  };

  const onFormLayoutChange = () => {};

  function handleKeyUp(event) {
    if (mode !== 'bulk_create' && mode !== 'root') {
      if (event.keyCode === 13) {
        onOk();
      }
    }
  }

  return (
    <Modal
      className="taxonomy-sidebar-category-modal"
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={400}
    >
      <Spin spinning={categoryInProcess}>
        <Form
          ref={formRef}
          form={form}
          labelCol={{ span: 4 }}
          onValuesChange={onFormLayoutChange}
          onKeyUp={handleKeyUp}
        >
          {mode === 'delete' && (
            <>
              <div>Are sure want to delete this category?</div>
              {childs.length > 0 && (
                <>
                  <br />
                  <Text type="danger">
                    <b>
                      Warning: This will also delete {childs?.length}{' '}
                      {childs?.length === 1 ? 'subcategory' : 'subcategories'}
                    </b>
                  </Text>
                </>
              )}
            </>
          )}
          {(mode === 'bulk_create' || mode === 'root') && (
            <>
              <Form.Item label="Name" name="names" required>
                <TextArea
                  ref={input}
                  placeholder="input names"
                  autoFocus={true}
                  tabIndex={0}
                  autoSize={{ minRows: 1 }}
                  disabled={isAutoGenerated}
                />
              </Form.Item>
              <div>
                <Form.Item label="" name="autoGenerated">
                  <Checkbox
                    checked={isAutoGenerated}
                    onChange={() => setIsAutoGenerated(!isAutoGenerated)}
                  >
                    Auto Generated
                  </Checkbox>
                </Form.Item>
              </div>
              {isAutoGenerated && (
                <div>
                  <Form.Item label="" name="productField" required>
                    <Select
                      required={true}
                      showSearch
                      placeholder="Select a base field"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {productField.map((item) => (
                        <Option value={item.key}>{item.key}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </>
          )}
          {(mode === 'create' || mode === 'edit') && (
            <>
              <Form.Item label="Name" name="name" required>
                <Input
                  ref={input}
                  placeholder="input name"
                  autoFocus={true}
                  tabIndex={0}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

SidebarCategoryModal.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  categoryId: PropTypes.string,
  open: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  childs: PropTypes.array,
};

SidebarCategoryModal.defaultProps = {
  title: '',
  categoryId: null,
  mode: '',
  open: false,
  handleOk: () => {},
  handleCancel: () => {},
  childs: [],
};

export default memo(SidebarCategoryModal);
