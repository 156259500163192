import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const { TabPane } = Tabs;

const CustomTabs = (props) => {
  const {
    className,
    tabs,
    activeTab,
    tabContent,
    tabContents,
    extraContent,
    handleChange,
  } = props;

  const onChange = (key) => {
    handleChange(key);
  };
  return (
    <div className={className}>
      <Tabs
        tabBarExtraContent={extraContent}
        onChange={onChange}
        activeKey={activeTab}
      >
        {tabs.length > 0 &&
          tabs.map((tab, index) => (
            <TabPane tab={tab} activeKey={`${activeTab}`} key={`${tab}`}>
              {activeTab === index && tabContent}
              {!tabContent && tabContents[index]}{' '}
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
};

CustomTabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  tabContents: PropTypes.array,
  tabContent: PropTypes.node,
  extraContent: PropTypes.node,
  handleChange: PropTypes.func,
};

CustomTabs.defaultProps = {
  className: '',
  tabs: [],
  activeTab: 0,
  tabContents: [],
  tabContent: null,
  extraContent: undefined,
  handleChange: () => {},
};

export default memo(CustomTabs);
