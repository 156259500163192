const build = (settings, usedValues = {}) => [
  {
    validator: (_, value) => {
      if (!settings) return Promise.resolve();
      return usedValues.indexOf(value) === -1
        ? Promise.resolve()
        : Promise.reject();
    },
    message: 'This value already used',
  },
];

export default build;
