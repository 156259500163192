import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const CustomLoader = (props) => {
  const { loading } = props;

  if (!loading) {
    return null;
  }

  return (
    <div className="spin-wrapper">
      <Spin />
    </div>
  );
};

CustomLoader.propTypes = {
  loading: PropTypes.bool,
};

CustomLoader.defaultProps = {
  loading: false,
};

export default memo(CustomLoader);

export const LoadingSpinner = () => {
  return (
    <div className="spin-wrapper">
      <Spin />
    </div>
  );
};
