import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const { Text } = Typography;

const InfoCard = (props) => {
  const { title, value, footer, children } = props;

  return (
    <Card
      title={title}
      extra={<InfoCircleOutlined />}
      bordered={false}
      className="info-card"
    >
      <Text className="info-value">{value}</Text>
      <div className="info-change">{children}</div>
      <div className="info-card-footer">{footer}</div>
    </Card>
  );
};

InfoCard.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

InfoCard.defaultProps = {
  title: undefined,
  value: '',
  footer: '',
  children: undefined,
};

export default memo(InfoCard);
