import { Menu } from 'antd';
import React from 'react';

export const TaxonomySidebarContextMenu = React.memo(
  ({ handleClickSubmenu, id }) => {
    return (
      <Menu>
        <Menu.Item
          key="bulk_create"
          onClick={() => {
            handleClickSubmenu('bulk_create', id);
          }}
        >
          Create New Categories
        </Menu.Item>
        <Menu.Item
          key="edit"
          onClick={() => {
            handleClickSubmenu('edit', id);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="delete"
          onClick={() => {
            handleClickSubmenu('delete', id);
          }}
        >
          Delete
        </Menu.Item>{' '}
        <Menu.Item
          key="expand_children"
          onClick={() => {
            handleClickSubmenu('expand_children', id);
          }}
        >
          Expand Children
        </Menu.Item>
        <Menu.Item
          key="collapse_children"
          onClick={() => {
            handleClickSubmenu('collapse_children', id);
          }}
        >
          Collapse Children
        </Menu.Item>
      </Menu>
    );
  }
);
