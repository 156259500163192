const imagesExt = ['jpg', 'jpeg', 'png', 'gif'];

const existingIcons = [
  'after',
  'effects',
  'bridge',
  'doc',
  'file',
  'html',
  'javascript',
  'mp4',
  'ppt',
  'rtf',
  'xls',
  'ai',
  'css',
  'dreamweaver',
  'fireworks',
  'illustrator',
  'jpg',
  'pdf',
  'prelude',
  'search',
  'xml',
  'audition',
  'csv',
  'dwg',
  'fla',
  'indesign',
  'json',
  'file',
  'photoshop',
  'premiere',
  'svg',
  'avi',
  'dbf',
  'exe',
  'flash',
  'iso',
  'mp3',
  'png',
  'psd',
  'txt',
  'zip',
];

const buildImageUrl = (ext) => `/images/mime-types/${ext}.png`;

const getFileUploadImage = (file) => {
  let imageUrl = file.thumbUrl;
  if (file?.type) {
    const [mainType, ext] = file.type.split('/');
    if (mainType !== 'image') {
      if (existingIcons.indexOf(ext) !== -1) {
        imageUrl = buildImageUrl(ext);
      } else if (existingIcons.indexOf(mainType) !== -1) {
        imageUrl = buildImageUrl(ext);
      } else {
        imageUrl = buildImageUrl('file');
      }
    }
  } else {
    const ext = file?.name?.split('.').pop().trim();
    if (ext) {
      if (imagesExt.indexOf(ext) === -1 && existingIcons.indexOf(ext) !== -1) {
        imageUrl = buildImageUrl(ext);
      } else {
        imageUrl = buildImageUrl('file');
      }
    } else {
      imageUrl = file.thumbUrl;
    }
  }

  return imageUrl;
};

export { getFileUploadImage };
