import { Button, Col, Divider, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import CustomDrawer from '@marketreach/components/drawer';
import PropertyItem from '@marketreach/components/section/PropertyItem';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { sortByOrder } from '@marketreach/utils/common';

import './styles.scss';

const EntityGridView = (props) => {
  const {
    type,
    title,
    entity,
    visible,
    handleShowGridView,
    handleSaveClick,
    drawer,
    entitiesList,
  } = props;

  const [form] = Form.useForm();

  const { selected: client } = useClientsState();

  const sections = (client ? client?.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  const footer = (
    <Row gutter={16}>
      {handleSaveClick && (
        <Col className="gutter-row" span={6}>
          <Form.Item>
            <Button type={'primary'} htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Col>
      )}
      {drawer && (
        <Col className="gutter-row" span={6} offset={12}>
          <Form.Item>
            <Button onClick={() => handleShowGridView(false)}>Cancel</Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );

  const onFinish = async (values) => {
    handleSaveClick(values);
  };

  const content = (
    <div className="properties-content">
      {sections.length > 0 &&
        sections.map((section, index) => (
          <div key={`section_${index}`}>
            <Divider orientation="left">{section.name}</Divider>
            <div className="properties-fields">
              {section.properties &&
                section.properties.length > 0 &&
                section.properties
                  .slice()
                  .sort(sortByOrder)
                  .map((data, index) => (
                    <PropertyItem
                      data={{
                        ...data,
                        entitiesList,
                      }}
                      readOnly={data?.settings?.template}
                      type={data?.propertyType?.title}
                      defaultValue={
                        entity[`${data?.settings?.key}_keys`] ||
                        entity[data?.settings?.key] ||
                        ''
                      }
                      usedValues={entity}
                      key={`property_item_${index}`}
                    />
                  ))}
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      scrollToFirstError
    >
      {drawer && (
        <CustomDrawer
          title={title}
          className="properties-config"
          footer={footer}
          content={content}
          visible={visible}
          handleShowDrawer={handleShowGridView}
        />
      )}
      {!drawer && (
        <>
          {footer}
          {content}
        </>
      )}
    </Form>
  );
};

EntityGridView.propTypes = {
  type: PropTypes.string,
  title: PropTypes.node,
  entity: PropTypes.object,
  visible: PropTypes.bool,
  handleShowGridView: PropTypes.func,
  handleSaveClick: PropTypes.func,
  drawer: PropTypes.bool,
  entitiesList: PropTypes.array,
};

EntityGridView.defaultProps = {
  type: 'product',
  title: '',
  entity: {},
  visible: false,
  handleShowGridView: () => {},
  handleSaveClick: null,
  drawer: true,
  entitiesList: null,
};

export default memo(EntityGridView);
