export const CLIENTS_GET_ALL_PERMISSION = 'clients:get:all';
export const CLIENT_USERS_MANAGE_PERMISSIONS = 'clients:users:manage';

export const SETTINGS_VIEW_PERMISSIONS = 'settings:view';
export const SETTINGS_LOCALES_MANAGE_PERMISSIONS = 'settings:locales:manage';
export const SETTINGS_USERS_MANAGE_PERMISSIONS = 'settings:users:manage';

// TODO add i18n support
export const SETTINGS_TREE = [
  {
    key: 'clients',
    title: 'Clients',
    children: [
      {
        key: CLIENTS_GET_ALL_PERMISSION,
        title: 'Can see all clients',
      },
      {
        key: CLIENT_USERS_MANAGE_PERMISSIONS,
        title: 'Can manage client users',
      },
    ],
  },
  {
    key: 'settings',
    title: 'Settings',
    children: [
      {
        key: SETTINGS_VIEW_PERMISSIONS,
        title: 'Can see settings page',
      },
      { key: SETTINGS_LOCALES_MANAGE_PERMISSIONS, title: 'Can manage locales' },
      { key: SETTINGS_USERS_MANAGE_PERMISSIONS, title: 'Can manage users' },
    ],
  },
];
