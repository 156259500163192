import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { CLIENTS, SINGLE_CLIENT } from 'services/apollo/clients';

const ClientsStateContext = React.createContext();
const ClientsDispatchContext = React.createContext();

export const CLIENTS_ACTIONS = {
  updateClients: 'UPDATE_CLIENTS',
  setSelected: 'SET_CLIENT',
};

function clientsReducer(state, action) {
  switch (action.type) {
    case CLIENTS_ACTIONS.updateClients: {
      return {
        ...state,
        all: action.all,
        loading: false,
        selected: action.selected || action.all[0],
      };
    }
    case CLIENTS_ACTIONS.setSelected: {
      return {
        ...state,
        selected: action.selected,
        loading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function ClientsProvider({ children }) {
  const { loading, data } = useQuery(CLIENTS);
  const [state, dispatch] = React.useReducer(clientsReducer, {
    all: null,
    selected: null,
    loading: true,
  });

  const { loading: selectedClientLoading, data: selectedClientResponse } =
    useQuery(SINGLE_CLIENT, {
      variables: {
        clientCode: state.selected?.apiId,
      },
    });

  const selectedClient = selectedClientResponse?.getClient?.data;

  React.useEffect(() => {
    if (!loading && !selectedClientLoading && data?.clients?.data) {
      if (!state.selected && data.clients.data.length > 0) {
        dispatch({
          type: CLIENTS_ACTIONS.setSelected,
          selected: data.clients.data[0],
        });
      }
      if (
        !state.all ||
        !_.isEqual(state.all, data.clients.data) ||
        (selectedClient &&
          !_.isEqual(selectedClient?.sections, state?.selected?.sections))
      ) {
        dispatch({
          type: CLIENTS_ACTIONS.updateClients,
          all: data.clients.data,
          selected: selectedClient,
        });
      }
    }
  }, [loading, data, state, selectedClient, selectedClientLoading]);

  return (
    <ClientsStateContext.Provider value={state}>
      <ClientsDispatchContext.Provider value={dispatch}>
        {children}
      </ClientsDispatchContext.Provider>
    </ClientsStateContext.Provider>
  );
}

export function useClientsState() {
  const context = React.useContext(ClientsStateContext);
  if (!context) {
    throw new Error('useClientsState must be used within the ClientsProvider');
  }
  return context;
}

export function useClientsDispatch() {
  const context = React.useContext(ClientsDispatchContext);
  if (!context) {
    throw new Error(
      'useClientsDispatch must be used within the ClientsProvider'
    );
  }
  return context;
}
