import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Progress, Typography } from 'antd';
import React, { memo } from 'react';

import InfoCard from '@marketreach/components/cards/InfoCard';

import './styles.scss';

const { Text } = Typography;

const OperationalEffect = () => (
  <InfoCard
    title="Operational Effect"
    value="78%"
    footer={
      <div className="effect-change-content">
        <div className="ant-col-12">
          <Text>WoW Change</Text>
          <Text className="change-value">12%</Text>
          <CaretUpOutlined style={{ color: '#f5222d' }} />
        </div>
        <div className="ant-col-12">
          <Text>DoD Change</Text>
          <Text className="change-value">11%</Text>
          <CaretDownOutlined style={{ color: '#52c41a' }} />
        </div>
      </div>
    }
  >
    <Progress strokeLinecap="square" percent={78} />
  </InfoCard>
);

export default memo(OperationalEffect);
