/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, DownOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Checkbox, Dropdown, Menu } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import {
  PRODUCTS_BY_PAGE,
  PRODUCTS_CSV,
} from '@marketreach/services/apollo/products';
import { sortByField, sortByOrder } from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

import ProductsImageView from './ProductImageView';
import ProductGridView from './ProductsGridView';
import './styles.scss';

const ProductsTable = (props) => {
  const {
    showManagement,
    rule,
    rules,
    skuArray,
    handleShowDrawer,
    onDataLoad,
  } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    getAccessTokenSilently().then((r) => {
      setAccessToken(r);
    });
  }, [getAccessTokenSilently, setAccessToken]);

  const { selected: client } = useClientsState();

  const [openGridView, setOpenGridView] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleShowGridView = (show) => {
    setOpenGridView(show);
  };

  const handleClickGridView = (product) => {
    setSelectedProduct(product);
    setOpenGridView(true);
  };

  const sections = (client ? client?.sections?.product || [] : [])
    .slice()
    .sort(sortByOrder);
  const productColumns = getSectionColumns(sections);
  const columns = productColumns;

  // Search values from table search form
  const [searchValues, setSearchValues] = useState(null);
  const handleSearch = (data) => {
    setSearchValues(data);
  };

  // Products filters from search values
  const searchFilters = searchValues
    ? Object.keys(searchValues).map((key) => ({
        [key]:
          typeof searchValues[key] === 'string'
            ? {
                $regex: searchValues[key],
                $options: 'gi',
              }
            : searchValues[key],
      }))
    : [];

  const [nestedProductIds, setNestedProductIds] = useState([]);
  const [nested, setNested] = useState(false);
  const [loadProductIds, setLoadProductIds] = useState(true);
  const handleCheckNest = (e) => {
    setNested(e.target.checked);
  };

  const [expandedRow, setExpandedRow] = useState(null);
  const handleExpandRow = (expanded, row) => {
    setExpandedRow(expanded ? row : null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [sortField, setSortField] = useState('_id');
  const [sortDirection, setSortDirection] = useState('ASC');

  const options = {
    variables: {
      clientCode: client?.apiId,
      pageNumber: currentPage,
      pageSize: rowsPerPage,
      groupByProduct: nested,
      rule:
        (rule?.simpleMode && rule?.key && rule?.criteria?.length > 0) ||
        (!rule?.simpleMode && rule?.advancedQuery)
          ? rule
          : null,
      rules,
      filterParams: {
        $and: [
          // { sku: { $in: skuArray } },
          skuArray?.length > 0 ? { sku: { $in: skuArray } } : {},
          ...searchFilters,
        ],
      },
      productIds: nested ? nestedProductIds : [],
      orderField: sortField,
      orderDirection: sortDirection,
    },
  };
  const { loading, data, refetch } = useQuery(PRODUCTS_BY_PAGE, options);

  const { data: expandedData } = useQuery(PRODUCTS_BY_PAGE, {
    variables: {
      clientCode: client?.apiId,
      pageNumber: 1,
      pageSize: rowsPerPage,
      groupByProduct: false,
      rule:
        (rule?.simpleMode && rule?.key && rule?.criteria?.length > 0) ||
        (!rule?.simpleMode && rule?.advancedQuery)
          ? rule
          : null,
      rules,
      filterParams: {
        $and: [
          skuArray?.length > 0 ? { sku: { $in: skuArray } } : {},
          // eslint-disable-next-line camelcase
          { product_id: expandedRow?.product_id },
        ],
      },
    },
  });

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setRowsPerPage(size);
    refetch();
  };

  const handleSortChange = (field, direction) => {
    setSortField(field);
    setSortDirection(direction);
    refetch();
  };

  const products = data ? data.getProductsByPage.data : [];

  const expandedProducts = expandedData
    ? expandedData.getProductsByPage.data
    : [];
  const totalCount = data ? data.getProductsByPage.totalCount : 0;
  const pageSize = data ? data.getProductsByPage.pageSize : rowsPerPage;
  const pageNumber = data ? data.getProductsByPage.pageNumber : currentPage;
  const productIds = data ? data.getProductsByPage.productIds : [];
  const skus = data ? data.getProductsByPage.skus : [];

  const [keys, setKeys] = useState([]);
  useEffect(() => {
    if (keys.length === 0 && products.length > 0) {
      setKeys(
        products.length > 0
          ? Object.keys(products[0])
              .map((key) => ({
                value: key,
                label: key,
                type: typeof products[0][key],
              }))
              .sort(sortByField('label'))
          : []
      );
    }
  }, [keys, products]);

  useEffect(() => {
    if (!loading) {
      // Call parent after new data load
      onDataLoad(productIds, skus, keys);

      if (loadProductIds === true) {
        setNestedProductIds(productIds);
        setLoadProductIds(false);
      }
    }
  }, [loading, productIds, skus, keys, loadProductIds]);

  const expandedRowRender = (record) =>
    expandedRow &&
    expandedRow?._id === record?._id && (
      <CustomProTable
        className="expand-products-table"
        data={expandedProducts}
        toolbar={false}
        columns={columns}
      />
    );

  const [imageView, setImageView] = useState(false);
  const handleImageView = (view) => {
    setImageView(view);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleImageView(true)}>
        Image view
      </Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URI}/reports`,
              {
                clientCode: client?.apiId,
                query: PRODUCTS_CSV,
                responseName: 'getProductsByPageReport',
                ...options,
                fields: ['product_id', 'sku', 'name'],
              },
              {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'report.csv');
              document.body.appendChild(link);
              link.click();
            });
        }}
      >
        CSV
      </Menu.Item>
    </Menu>
  );

  const toolbar = () => [
    <Checkbox key="nest_product" onChange={handleCheckNest} checked={nested}>
      Group by Product ID
    </Checkbox>,
    <Dropdown overlay={menu} key="table_list">
      <Button>
        Table list <DownOutlined />
      </Button>
    </Dropdown>,
    <Dropdown overlay={exportMenu} key="export">
      <Button type="link">
        Export <DownOutlined />
      </Button>
    </Dropdown>,
    <>
      {showManagement && (
        <Button type="link" onClick={() => handleShowDrawer(true)}>
          Manage properties
        </Button>
      )}
    </>,
  ];

  const productDetailTitle = (
    <div className="product-detail-header">
      <div className="product-detail-header-title">
        <strong>Product Details:</strong> {selectedProduct?.displayName}
      </div>
      <>
        {showManagement && (
          <Button type="link" onClick={() => handleShowDrawer(true)}>
            Manage properties
          </Button>
        )}
      </>
    </div>
  );

  return (
    <div className="ant-pro-grid-content">
      {imageView ? (
        <>
          <div className="products-result-banner">
            <div>
              Product IDs: <strong>{productIds?.length || 0}</strong>
              <ArrowDownOutlined />
              <>
                SKUs: <strong>{skus?.length || 0}</strong>
              </>
            </div>
          </div>
          <ProductsImageView
            loading={loading}
            showDrawer={showManagement}
            nested={nested}
            products={products}
            totalCount={totalCount}
            pageNumber={currentPage}
            pageSize={rowsPerPage}
            productColumns={productColumns}
            handleImageView={handleImageView}
            handlePageChange={handlePageChange}
            handleShowDrawer={handleShowDrawer}
            handleCheckNest={handleCheckNest}
            handleClickBack={() => handleImageView(false)}
          />
        </>
      ) : (
        <div className="products-table-container">
          <div className="products-result-banner">
            <div>
              Product IDs: <strong>{productIds?.length || 0}</strong>
              <ArrowDownOutlined />
              <>
                SKUs: <strong>{skus?.length || 0}</strong>
              </>
            </div>
          </div>
          <CustomProTable
            className="products-table"
            data={products}
            columns={columns}
            loading={loading}
            toolbar={toolbar}
            handleRowClick={handleClickGridView}
            pagination={{
              current: pageNumber,
              total: totalCount,
              pageSize,
              showSizeChanger: true,
              onChange: (page, size) => {
                handlePageChange(page, size);
              },
              // showTotal: false,
              showTotal: (total, range) => (
                <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
              ),
            }}
            expandable={
              nested && {
                expandedRowRender,
                rowExpandable: () => true,
              }
            }
            searchable
            handleExpandRow={handleExpandRow}
            handleSearch={handleSearch}
            handleSortChange={handleSortChange}
          />
        </div>
      )}
      {openGridView && (
        <ProductGridView
          title={productDetailTitle}
          visible={openGridView}
          product={selectedProduct}
          handleShowGridView={handleShowGridView}
        />
      )}
    </div>
  );
};

ProductsTable.propTypes = {
  type: PropTypes.string,
  showManagement: PropTypes.bool,
  rule: PropTypes.object,
  rules: PropTypes.array,
  skuArray: PropTypes.array,
  handleShowDrawer: PropTypes.func,
  onDataLoad: PropTypes.func,
  realSearch: PropTypes.bool,
};

ProductsTable.defaultProps = {
  type: 'product',
  showManagement: false,
  rule: null,
  rules: [],
  skuArray: null,
  handleShowDrawer: () => {},
  onDataLoad: () => {},
  realSearch: true,
};

export default memo(ProductsTable);
