import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

HC_exporting(Highcharts);

const VisitsTrendChart = (props) => {
  const { options } = props;

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

VisitsTrendChart.propTypes = {
  options: PropTypes.object,
  title: PropTypes.string,
};

VisitsTrendChart.defaultProps = {
  options: {
    chart: {
      type: 'column',
      height: 300,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: 'Visits Trend',
      align: 'left',
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    series: [
      {
        name: 'Payments',
        data: [
          640, 1005, 1436, 20434, 14747, 10104, 4954, 1005, 2063, 3057, 4618,
          6444,
        ],
      },
    ],
  },
  title: '',
};

export default memo(VisitsTrendChart);
