import { useAuth0 } from '@auth0/auth0-react';
import { Layout, Menu } from 'antd';
import React, { memo, useState } from 'react';

import ApiAccess from '@marketreach/pages/auth/settings/ApiAccess';
import CustomEntity from '@marketreach/pages/auth/settings/CustomEntity';
import SchemaViewer from '@marketreach/pages/auth/settings/SchemaViewer';
import UserManagement from '@marketreach/pages/auth/settings/UserManagement';
import { useClientsState } from '@marketreach/providers/ClientsProvider';

import {
  hasPermission,
  SETTINGS_LOCALES_MANAGE_PERMISSIONS,
  SETTINGS_USERS_MANAGE_PERMISSIONS,
} from '../../../utils/permissioins';

import LocaleSetting from './ClientManagement';
import RuleSetting from './RuleSetting';

import './styles.scss';

const { Sider } = Layout;

const SettingsPage = () => {
  const { selected: client } = useClientsState();
  const { user } = useAuth0();

  const [collapsed, setCollapsed] = useState(false);
  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const [tab, setTab] = useState(
    hasPermission(user, SETTINGS_LOCALES_MANAGE_PERMISSIONS)
      ? 'locales'
      : 'rules'
  );
  const handleChangeMenu = (e) => {
    setTab(e.key);
  };

  return (
    <div className="ant-pro-grid-content settings-content">
      <Layout>
        <Sider
          width={250}
          collapsedWidth={15}
          collapsible
          collapsed={collapsed}
          onCollapse={handleToggleClick}
          className="side-bar-content"
        >
          {collapsed ? (
            <div className="collapsed-sider-content" />
          ) : (
            <>
              <div className="side-bar-content-title">Settings</div>
              <Menu
                defaultSelectedKeys={[tab]}
                mode="inline"
                onSelect={handleChangeMenu}
              >
                {hasPermission(user, SETTINGS_LOCALES_MANAGE_PERMISSIONS) && (
                  <Menu.Item key="locales">Clients</Menu.Item>
                )}
                <Menu.Item key="rules">Actions</Menu.Item>
                <Menu.Item key="access">API Access</Menu.Item>
                <Menu.Item key="schema">Schema Viewer</Menu.Item>
                {hasPermission(user, SETTINGS_USERS_MANAGE_PERMISSIONS) && (
                  <Menu.Item key="management">User Management</Menu.Item>
                )}
                <Menu.Item key="custom_entities">Custom Entities</Menu.Item>
              </Menu>
            </>
          )}
        </Sider>
        <Layout>
          {tab === 'locales' && <LocaleSetting />}
          {tab === 'rules' && <RuleSetting client={client} />}
          {tab === 'management' && <UserManagement />}
          {tab === 'access' && <ApiAccess />}
          {tab === 'schema' && <SchemaViewer />}
          {tab === 'custom_entities' && <CustomEntity />}
        </Layout>
      </Layout>
    </div>
  );
};

export default memo(SettingsPage);
