export const AUTH_TOKEN = 'auth_token';

const getLocalStorage = (item) => {
  const stateStr = localStorage.getItem(item);
  if (stateStr) {
    return JSON.parse(stateStr);
  }
  return [];
};

export const setTaxonomyTreeExpandedState = (expandedKeys) => {
  const stateStr = JSON.stringify({
    expandedKeys,
  });
  localStorage.setItem('taxonomy_tree_expanded_keys', stateStr);
};

export const getTaxonomyTreeExpandedState = () => {
  return getLocalStorage('taxonomy_tree_expanded_keys');
};
