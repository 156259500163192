import { Input, Typography, Checkbox, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const { Option } = Select;

const PropertyValidation = (props) => {
  const { type, state, handleChangeState } = props;

  const getValidationCheckbox = (type) => {
    switch (type) {
      case 'required':
        return (
          <Checkbox
            key="required"
            className="taxonomy-add-property-modal-item"
            checked={state?.validation?.required}
            onChange={(e) =>
              handleChangeState('validation', 'required', e.target.checked)
            }
          >
            <Typography className="taxonomy-add-property-modal-item-title">
              Make field required
            </Typography>
            <Typography>
              Prevents saving an entry if this field is empty.
            </Typography>
          </Checkbox>
        );
      case 'unique':
        return (
          <Checkbox
            key="unique"
            className="taxonomy-add-property-modal-item"
            checked={state?.validation?.unique}
            onChange={(e) =>
              handleChangeState('validation', 'unique', e.target.checked)
            }
          >
            <Typography className="taxonomy-add-property-modal-item-title">
              Set field as unique
            </Typography>
            <Typography>
              Ensures that multiple entries will not have duplicate values.
            </Typography>
          </Checkbox>
        );
      case 'limit_characters':
        return (
          <>
            <Checkbox
              key="limit_characters"
              className="taxonomy-add-property-modal-item"
              checked={state?.validation?.limit_characters}
              onChange={(e) =>
                handleChangeState(
                  'validation',
                  'limit_characters',
                  e.target.checked
                    ? {
                        limit: 'between',
                        max: 0,
                        min: 0,
                      }
                    : null
                )
              }
            >
              <Typography className="taxonomy-add-property-modal-item-title">
                Limit character count
              </Typography>
              <Typography>
                Specifies a minimum allowed number of characters.
              </Typography>
            </Checkbox>
            {state?.validation?.limit_characters && (
              <>
                <Select
                  showSearch
                  style={{
                    width: 200,
                    marginRight: 12,
                  }}
                  placeholder="Select a range"
                  optionFilterProp="children"
                  defaultValue="between"
                  onChange={(value) =>
                    handleChangeState('validation', 'limit_characters', {
                      ...state.validation.limit_characters,
                      limit: value,
                    })
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="between">Between</Option>
                  <Option value="bigger_than">Bigger than</Option>
                  <Option value="smaller_than">Smaller than</Option>
                </Select>
                {(state.validation.limit_characters?.limit === 'between' ||
                  state.validation.limit_characters?.limit ===
                    'smaller_than') && (
                  <InputNumber
                    placeholder="Maximum"
                    type="number"
                    style={{
                      width: 150,
                      marginRight: 12,
                    }}
                    defaultValue={state.validation.limit_characters?.max}
                    onChange={(value) =>
                      handleChangeState('validation', 'limit_characters', {
                        ...state.validation.limit_characters,
                        max: value,
                      })
                    }
                  />
                )}
                {(state.validation.limit_characters?.limit === 'between' ||
                  state.validation.limit_characters?.limit ===
                    'bigger_than') && (
                  <InputNumber
                    placeholder="Minimum"
                    type="number"
                    style={{ width: 150 }}
                    defaultValue={state.validation.limit_characters?.min}
                    onChange={(value) =>
                      handleChangeState('validation', 'limit_characters', {
                        ...state.validation.limit_characters,
                        min: value,
                      })
                    }
                  />
                )}
                <Typography>Custom error message</Typography>
                <Input
                  defaultValue={state.validation.limit_characters?.error}
                  onChange={(e) =>
                    handleChangeState('validation', 'limit_characters', {
                      ...state.validation.limit_characters,
                      error: e.target.value,
                    })
                  }
                />
              </>
            )}
          </>
        );
      case 'match_pattern':
        return (
          <>
            <Checkbox
              key="match_pattern"
              className="taxonomy-add-property-modal-item"
              checked={state?.validation?.match_pattern}
              onChange={(e) =>
                handleChangeState(
                  'validation',
                  'match_pattern',
                  e.target.checked
                    ? {
                        type: 'custom',
                        pattern: null,
                        case_sensitive: false,
                        multi_line: false,
                        error: null,
                      }
                    : null
                )
              }
            >
              <Typography className="taxonomy-add-property-modal-item-title">
                Match a specific pattern
              </Typography>
              <Typography>
                Only accepts specified regular expression (e.g e-mail, URL,
                phone number).
              </Typography>
            </Checkbox>
            {state?.validation?.match_pattern && (
              <>
                <Select
                  showSearch
                  style={{
                    width: 200,
                    marginRight: 12,
                  }}
                  placeholder="Select a type"
                  optionFilterProp="children"
                  defaultValue={
                    state.validation.match_pattern?.type || 'custom'
                  }
                  onChange={(value) =>
                    handleChangeState('validation', 'match_pattern', {
                      ...state.validation.match_pattern,
                      type: value,
                    })
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="custom">Custom</Option>
                  <Option value="email">Email</Option>
                  <Option value="url">URL</Option>
                  <Option value="slug">Slug</Option>
                </Select>
                {state.validation.match_pattern?.type === 'custom' && (
                  <Input
                    style={{ width: 350 }}
                    defaultValue={state.validation.match_pattern.pattern}
                    onChange={(e) =>
                      handleChangeState('validation', 'match_pattern', {
                        ...state.validation.match_pattern,
                        pattern: e.target.value,
                      })
                    }
                  />
                )}
                <div className="taxonomy-add-property-modal-item-checkbox-wrapper">
                  <Checkbox className="taxonomy-add-property-modal-item-checkbox">
                    <Typography>Case sensitive</Typography>
                  </Checkbox>
                  <Checkbox className="taxonomy-add-property-modal-item-checkbox">
                    <Typography>Multiline</Typography>
                  </Checkbox>
                </div>
                <Typography>Custom error message</Typography>
                <Input
                  defaultValue={state.validation.match_pattern.error}
                  onChange={(e) =>
                    handleChangeState('validation', 'match_pattern', {
                      ...state.validation.match_pattern,
                      error: e.target.value,
                    })
                  }
                />
              </>
            )}
          </>
        );
      case 'restrict_pattern':
        return (
          <>
            <Checkbox
              key="restrict_pattern"
              className="taxonomy-add-property-modal-item"
              checked={state.validation.restrict_pattern}
              onChange={(e) =>
                handleChangeState(
                  'validation',
                  'restrict_pattern',
                  e.target.checked
                    ? {
                        type: 'custom',
                        pattern: null,
                        case_sensitive: false,
                        multi_line: false,
                        error: null,
                      }
                    : null
                )
              }
            >
              <Typography className="taxonomy-add-property-modal-item-title">
                Restrict a specific pattern
              </Typography>
              <Typography>
                Will not accept a specific regular expression.
              </Typography>
            </Checkbox>
            {state?.validation?.restrict_pattern && (
              <>
                <Select
                  showSearch
                  style={{
                    width: 200,
                    marginRight: 12,
                  }}
                  placeholder="Select a type"
                  optionFilterProp="children"
                  defaultValue={
                    state.validation.restrict_pattern?.type || 'custom'
                  }
                  onChange={(value) =>
                    handleChangeState('validation', 'restrict_pattern', {
                      ...state.validation.restrict_pattern,
                      type: value,
                    })
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="custom">Custom</Option>
                  <Option value="email">Email</Option>
                  <Option value="url">URL</Option>
                  <Option value="slug">Slug</Option>
                </Select>
                {state.validation.restrict_pattern?.type === 'custom' && (
                  <Input
                    style={{ width: 350 }}
                    defaultValue={state.validation.restrict_pattern.pattern}
                    onChange={(e) =>
                      handleChangeState('validation', 'restrict_pattern', {
                        ...state.validation.restrict_pattern,
                        pattern: e.target.value,
                      })
                    }
                  />
                )}
                <div className="taxonomy-add-property-modal-item-checkbox-wrapper">
                  <Checkbox
                    checked={state.validation.restrict_pattern?.case_sensitive}
                    className="taxonomy-add-property-modal-item-checkbox"
                    onChange={(e) =>
                      handleChangeState('validation', 'restrict_pattern', {
                        ...state.validation.restrict_pattern,
                        case_sensitive: e.target.checked,
                      })
                    }
                  >
                    <Typography>Case sensitive</Typography>
                  </Checkbox>
                  <Checkbox
                    checked={state.validation.restrict_pattern?.multi_line}
                    className="taxonomy-add-property-modal-item-checkbox"
                    onChange={(e) =>
                      handleChangeState('validation', 'restrict_pattern', {
                        ...state.validation.restrict_pattern,
                        multi_line: e.target.checked,
                      })
                    }
                  >
                    <Typography>Multiline</Typography>
                  </Checkbox>
                </div>
                <Typography>Custom error message</Typography>
                <Input
                  defaultValue={state.validation.restrict_pattern?.error}
                  onChange={(e) =>
                    handleChangeState('validation', 'restrict_pattern', {
                      ...state.validation.restrict_pattern,
                      error: e.target.value,
                    })
                  }
                />
              </>
            )}
          </>
        );
      case 'range':
      case 'file_count_limit':
      case 'file_size_limit':
        let label = 'Limit input range';
        let description = 'Specifies a minimum and/or maximum allowed value.';
        let checked = false;
        const isRangeType = type === 'range';
        switch (type) {
          case 'file_count_limit':
            label = 'Limit number of files';
            description =
              'Specifies a minimum or maximum of files that may be uploaded.';
            checked = state.validation.file_count_limit;
            break;
          case 'file_size_limit':
            label = 'Limit file size';
            description =
              'Specifies a minimum andor maximum file size (in bytes, per file) that may be uploaded.';
            checked = state.validation.file_size_limit;
            break;
          default:
            checked = state.validation.range;
        }

        return (
          <>
            <Checkbox
              key="validation"
              className="taxonomy-add-property-modal-item"
              checked={checked}
              onChange={(e) =>
                handleChangeState(
                  'validation',
                  type,
                  e.target.checked
                    ? {
                        limit: isRangeType ? 'between' : 'smaller_than',
                        min: isRangeType ? 0 : 0,
                        max: isRangeType ? 0 : 1,
                      }
                    : null
                )
              }
            >
              <Typography className="taxonomy-add-property-modal-item-title">
                {label}
              </Typography>
              <Typography>{description}</Typography>
            </Checkbox>
            {state.validation[type] && (
              <>
                <Select
                  showSearch
                  style={{
                    width: 200,
                    marginRight: 12,
                  }}
                  placeholder="Select a range"
                  optionFilterProp="children"
                  defaultValue="between"
                  value={state?.validation[type].limit}
                  onChange={(value) =>
                    handleChangeState('validation', type, {
                      ...state.validation[type],
                      limit: value,
                    })
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {isRangeType && <Option value="between">Between</Option>}
                  <Option value="bigger_than">
                    {isRangeType ? 'Bigger than' : 'No less than'}
                  </Option>
                  <Option value="smaller_than">
                    {isRangeType ? 'Smaller than' : 'No more than'}
                  </Option>
                </Select>
                {(state?.validation[type]?.limit === 'between' ||
                  state?.validation[type]?.limit === 'bigger_than') && (
                  <InputNumber
                    placeholder="Minimum"
                    type="number"
                    style={{
                      width: 150,
                      marginRight: 12,
                    }}
                    defaultValue={state.validation[type]?.min}
                    onChange={(value) =>
                      handleChangeState('validation', type, {
                        ...state.validation[type],
                        min: value,
                        max: isRangeType ? state.validation[type]?.max : 0,
                      })
                    }
                  />
                )}
                {(state?.validation[type]?.limit === 'between' ||
                  state?.validation[type]?.limit === 'smaller_than') && (
                  <InputNumber
                    placeholder="Maximum"
                    type="number"
                    style={{ width: 150 }}
                    defaultValue={state.validation[type].max}
                    onChange={(value) =>
                      handleChangeState('validation', type, {
                        ...state.validation[type],
                        max: value,
                        min: isRangeType ? state.validation[type]?.min : 0,
                      })
                    }
                  />
                )}

                {!isRangeType && (
                  <>
                    <Typography>Custom error message</Typography>
                    <Input
                      defaultValue={state.validation[type]?.error}
                      onChange={(e) =>
                        handleChangeState('validation', type, {
                          ...state.validation[type],
                          error: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <>{getValidationCheckbox(type)}</>;
};

PropertyValidation.propTypes = {
  type: PropTypes.string,
  state: PropTypes.object,
  handleChangeState: PropTypes.func,
};

PropertyValidation.defaultProps = {
  type: null,
  state: null,
  handleChangeState: () => {},
};

export default memo(PropertyValidation);
