import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import HCExporting from 'highcharts/modules/exporting';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

HCExporting(Highcharts);
highcharts3d(Highcharts);

const SalesProportionChart = (props) => {
  const { options } = props;

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

SalesProportionChart.propTypes = {
  options: PropTypes.object,
};

SalesProportionChart.defaultProps = {
  options: {
    chart: {
      type: 'pie',
      height: 400,
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    title: {
      text: 'Sales',
      align: 'left',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45,
      },
    },
    series: [
      {
        name: 'Sales',
        data: [
          ['Bag', 8],
          ['Jacket', 3],
          ['Female top', 1],
          ['Man shirt', 6],
          ['Sun glasses', 8],
          ['Watch', 4],
          ['Handbag', 4],
          ['Cap', 1],
          ['Helmet', 1],
        ],
      },
    ],
  },
};

export default memo(SalesProportionChart);
