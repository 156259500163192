import * as _ from 'lodash';
import React from 'react';

import { getFileUploadImage } from '@marketreach/utils/files';

import { sortByOrder } from '../common';

export const sortTable = (a, b) => {
  if (typeof a === 'number') {
    return a - b;
  }

  if (typeof a === 'string') {
    return (a || '').localeCompare(b || '');
  }

  if (a === undefined) {
    return -1;
  }

  return JSON.stringify(a).localeCompare(JSON.stringify(b));
};

export const getSectionColumns = (sections) => {
  if (!sections) {
    return [];
  }

  let properties = [];
  sections
    .slice()
    .sort(sortByOrder)
    .forEach((section) => {
      if (section.properties) {
        properties = properties.concat(
          section.properties.slice().sort(sortByOrder)
        );
      }
    });

  if (!properties) {
    return [];
  }

  const columns = [];
  properties.forEach((property) => {
    if (property?.settings) {
      if (property.propertyType.title === 'File Upload') {
        columns.push({
          title: property.settings.label || '',
          dataIndex: property.settings.key || '',
          ellipsis: true,
          width: 100,
          propertyType: property.propertyType.title,
          sorter: (a, b) =>
            sortTable(a[property.settings.key], b[property.settings.key]),
          render: (text, row) => {
            const files = [];

            if (_.isArray(row[property.settings.key])) {
              for (const item of row[property.settings.key]) {
                files.push({
                  src: getFileUploadImage(item),
                  alt: item.name,
                });
              }
            } else {
              files.push({ src: row[property.settings.key] });
            }
            return files
              .filter((item) => item?.src?.length > 0)
              .map((item) => (
                <img
                  width="30"
                  height="30"
                  src={item.src}
                  alt={item.alt || 'product thumb'}
                />
              ));
          },
        });
      } else if (property.propertyType.title === 'Toggle') {
        columns.push({
          title: property.settings.label || '',
          dataIndex: property.settings.key || '',
          ellipsis: true,
          width: 175,
          propertyType: property.propertyType.title,
          sorter: (a, b) =>
            sortTable(a[property.settings.key], b[property.settings.key]),
          render: (text, row) => (
            <div>{(!!row[property.settings.key]).toString()}</div>
          ),
        });
      } else {
        columns.push({
          title: property.settings.label || '',
          dataIndex: property.settings.key || '',
          ellipsis: true,
          width: 175,
          propertyType: property.propertyType.title,
          sorter: (a, b) =>
            sortTable(a[property.settings.key], b[property.settings.key]),
        });
      }
    }
  });

  return columns;
};

export const getNestedProducts = (products, productIds) => {
  if (!products || products === []) {
    return [];
  }

  const nestedProducts = [];
  productIds.forEach((productId) => {
    const children = products.filter(
      (product) => productId === product.product_id
    );
    if (children && children[0]) {
      nestedProducts.push(children[0]);
    }
  });

  return nestedProducts;
};
