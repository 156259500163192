import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Spin, Switch, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTES } from '@marketreach/services/apollo/attributes';

import ProductsTable from '../../share/products/ProductsTable';

import './styles.scss';

const TaxonomyAttributesPreview = (props) => {
  const { previewFilters } = props;

  const { selected: client } = useClientsState();
  const { selectedCategory } = useContext(TaxonomyContext);
  const [skus, setSkus] = useState([]);

  const { data: previewAttributesInfo, loading: previewAttributesLoading } =
    useQuery(ATTRIBUTES, {
      variables: {
        clientCode: client?.apiId,
        fullMode: true,
        filterParams: {
          _id: {
            $in: previewFilters,
          },
        },
      },
    });

  useEffect(() => {
    if (previewAttributesInfo?.attributes?.data?.length > 0) {
      setSkus(
        previewAttributesInfo.attributes.data
          .map((item) => item.skus || [])
          .flat()
          .filter((sku) => (selectedCategory?.skus || []).indexOf(sku) !== -1)
      );
    } else {
      setSkus([]);
    }
  }, [previewAttributesInfo]);

  return (
    <div className="attributes-content-preview">
      <Spin spinning={previewAttributesLoading}>
        <ProductsTable client={client} type="category" skuArray={skus} />
      </Spin>
    </div>
  );
};

TaxonomyAttributesPreview.propTypes = {
  previewFilters: PropTypes.array,
  rawAttributes: PropTypes.array,
  onPreviewChange: PropTypes.func,
};

TaxonomyAttributesPreview.defaultProps = {
  previewFilters: [],
  rawAttributes: [],
  onPreviewChange: () => {},
};

export default memo(TaxonomyAttributesPreview);
