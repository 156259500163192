import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

HCExporting(Highcharts);

const PaymentsChart = (props) => {
  const { options } = props;

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

PaymentsChart.propTypes = {
  options: PropTypes.object,
};

PaymentsChart.defaultProps = {
  options: {
    chart: {
      type: 'column',
      height: 50,
    },
    xAxis: {
      visible: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
    },
    title: {
      style: {
        display: 'none',
      },
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      {
        name: 'Payments',
        data: [
          20434, 14747, 10104, 4954, 1005, 369, 640, 1005, 1436, 2063, 3057,
          4618, 6444, 9822,
        ],
      },
    ],
  },
};

export default memo(PaymentsChart);
