import { Card } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const CustomCard = (props) => {
  const { title, extra, className, children } = props;

  return (
    <Card
      title={title}
      extra={extra}
      bordered={false}
      className={classnames('custom-card', className)}
    >
      <div className="custom-card-content">{children}</div>
    </Card>
  );
};

CustomCard.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomCard.defaultProps = {
  title: undefined,
  extra: '',
  children: undefined,
  className: '',
};

export default memo(CustomCard);
