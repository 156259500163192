import { isArray, isObject } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import slugify from 'slugify';

import { getFileUploadImage } from '@marketreach/utils/files';

const sortByOrder = ({ order: orderA = 0 }, { order: orderB = 0 }) =>
  orderA - orderB;

const sortByField = (field) => (a, b) => {
  return a[field]?.toUpperCase().localeCompare(b[field]?.toUpperCase());
};

const capitalize = (str) => {
  if (typeof str !== 'string' || !str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const buildSlug = (str) => {
  // you can read the doc here - https://github.com/simov/slugify
  return slugify(str.replace(/^https?:\/\//, ''), {
    locale: 'en',
    lower: true,
    replacement: '_',
    remove: /[*+~.()'"!:@\\/<>?]/g,
  });
};

const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, message]);
};

const normalizeEntity = (data, simpleView = false) => {
  return data.map((item) => {
    const insideProp = {};

    if (item?.properties) {
      for (const section of Object.keys(item.properties)) {
        for (const key of Object.keys(item.properties[section])) {
          insideProp[key] = item?.properties[section][key];
        }
      }
    }
    for (const prop of Object.keys(item)) {
      if (prop !== 'properties' && prop !== 'relatedFieldsData') {
        insideProp[prop] = item[prop];
      }
    }

    const preparedData = {
      ...insideProp,
      _id: item._id,
      parentId: item?.core?.parentId,
      core: item?.core,
    };
    if (item['relatedFieldsData'] && simpleView) {
      for (const field in item['relatedFieldsData']) {
        preparedData[`${field}_keys`] = preparedData[field];
        preparedData[field] = item['relatedFieldsData'][field].map(
          (fieldData) => {
            const data = [...(!isArray(fieldData) ? [fieldData] : fieldData)];
            return data.map((dataItem) => {
              if (isObject(dataItem)) {
                const imageUrl = getFileUploadImage(dataItem);
                return (
                  <p>
                    <img src={imageUrl} className={'tableImage'} />
                  </p>
                );
              }
              return <p>{dataItem.toString()}</p>;
            });
          }
        );
      }
    }
    return preparedData;
  });
};

export const getAllExpandableChildrenIds = (leafEntity) => {
  const stack = [...leafEntity.children];
  const result = [];
  while (stack.length > 0) {
    const currItem = stack.pop();
    if (Boolean(currItem.children) && currItem.children.length > 0) {
      stack.push(...currItem.children);
      result.push(currItem.key);
    }
  }
  return result;
};

const useTreeData = (entityDataList, titleField = 'name', parentId = null) => {
  return useMemo(() => {
    if (typeof entityDataList?.length === 'undefined') return [];
    return getSubTree(entityDataList, parentId, titleField);
  }, [entityDataList, titleField]);
};

export const getLeafs = (rootEntities) => {
  const stack = [...rootEntities];
  const results = [];
  while (stack.length > 0) {
    const currItem = stack.pop();
    if (
      typeof currItem.children === 'undefined' ||
      currItem.children.length === 0
    ) {
      results.push(currItem);
    }
    stack.push(...currItem.children);
  }
  return results;
};

export const useSearch = (rootEntities, searchString) => {
  const result = useMemo(() => {
    if (!Boolean(searchString)) {
      return rootEntities;
    }

    const searchStringLowerCase = searchString.toLowerCase();
    rootEntities.forEach((t) => {
      t.isSearchPartially = false;
      t.isSearchFull =
        t.title.toLowerCase().indexOf(searchStringLowerCase) > -1;
    });

    const leafs = getLeafs(rootEntities.filter((it) => !it.isSearchFull));
    const parents = [];

    leafs.forEach((leaf) => {
      const isFound =
        leaf.title.toLowerCase().indexOf(searchStringLowerCase) > -1;
      if (isFound && leaf.parent !== null) parents.push(leaf.parent);
      leaf.isSearchFull = isFound;
    });

    while (parents.length > 0) {
      const currItem = parents.pop();
      currItem.isSearchPartially = true;
      if (currItem.parent !== null) {
        parents.push(currItem.parent);
      }
    }

    const newRootEntities = rootEntities.filter(
      (x) => x.isSearchFull || x.isSearchPartially
    );

    const newEntities = [...newRootEntities];

    while (newEntities.length > 0) {
      const currItem = newEntities.pop();
      currItem.isSearchFull =
        currItem.title.toLowerCase().indexOf(searchStringLowerCase) > -1;
      if (currItem.isSearchFull) {
        continue;
      }

      if (
        typeof currItem !== 'undefined' &&
        typeof currItem.children !== 'undefined'
      ) {
        currItem.children = currItem.children.filter(
          (x) => x.isSearchFull || x.isSearchPartially
        );
        newEntities.push(...currItem.children);
      }
    }

    return newRootEntities;
  }, [rootEntities, searchString]);
  return result;
};

const getSubTree = (entityDataList, parentId = null, titleField) => {
  if (typeof entityDataList?.length === 'undefined') return [];

  const idMapping = entityDataList.reduce((acc, el, i) => {
    acc[el._id] = i;
    return acc;
  }, {});
  let roots = [];
  const dictionary = {};
  entityDataList
    .map((entityInfo) => ({
      title: entityInfo[titleField],
      key: entityInfo._id,
      parentId: entityInfo.parentId,
      children: [],
      associations: entityInfo.associations ?? [],
      'data-treenode-id': entityInfo._id,
    }))
    .forEach((entityInfo, index, array) => {
      // Handle the root element
      let isRoot =
        parentId === null
          ? typeof entityInfo.parentId === 'undefined' ||
            entityInfo.parentId === null
          : entityInfo.parentId === parentId;
      dictionary[entityInfo.key] = entityInfo;
      if (isRoot) {
        entityInfo.parent = null;
        roots = [...roots, entityInfo];
        return;
      }
      const parentEl = array[idMapping[entityInfo.parentId]];
      if (Boolean(parentEl)) {
        parentEl.children = [...(parentEl.children || []), entityInfo];
        entityInfo.parent = parentEl;
      }
    });
  return [roots, dictionary];
};

const treeData = (entityDataList, titleField) =>
  getSubTree(entityDataList, null, titleField)[0];

export {
  sortByOrder,
  sortByField,
  capitalize,
  buildSlug,
  useBeforeUnload,
  normalizeEntity,
  treeData,
  useTreeData,
};
