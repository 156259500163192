import { Menu, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const CustomMenu = (props) => {
  const { items } = props;

  const menu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return <Dropdown.Button className="custom-menu" overlay={menu} />;
};

CustomMenu.propTypes = {
  items: PropTypes.array,
};

CustomMenu.defaultProps = {
  items: [
    {
      key: '1',
      label: '1st menu item',
    },
    {
      key: '2',
      label: '2st menu item',
    },
    {
      key: '3',
      label: '3st menu item',
    },
  ],
};

export default memo(CustomMenu);
