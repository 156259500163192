import { Tabs, Progress, Typography } from 'antd';
import React, { memo } from 'react';

import PaymentStatusChart from './PaymentStatusChart';
import './styles.scss';

const { Text } = Typography;
const { TabPane } = Tabs;

const AllStoresStatus = () => {
  const tabs = [...Array(30).keys()].map((key) => (
    <div key={key} className="ant-row conversion-rate">
      <div className="ant-col-12 conversion-rate-content">
        <Text className="store-name">{`Store ${key}`}</Text>
        <Text className="conversion-rate-title">Conversion Rate</Text>
        <Text className="conversion-rate-value">{`${(key % 10) * 10}%`}</Text>
      </div>
      <Progress
        strokeLinecap="square"
        type="circle"
        percent={(key % 10) * 10}
        width={40}
        className="ant-col-12 conversion-rate-progress"
      />
    </div>
  ));

  return (
    <div className="payment-status-content">
      <Tabs>
        {tabs.map((it, index) => (
          <TabPane key={index} tab={it} />
        ))}
      </Tabs>
      <PaymentStatusChart />
    </div>
  );
};

export default memo(AllStoresStatus);
