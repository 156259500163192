import React, { memo } from 'react';

import InfoCard from '@marketreach/components/cards/InfoCard';

import './styles.scss';
import VisitsChart from './VisitsChart';

const Visits = () => (
  <InfoCard title="Visits" value="8,846" footer="Daily Visits 1,234">
    <VisitsChart />
  </InfoCard>
);

export default memo(Visits);
