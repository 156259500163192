import { Form, InputNumber } from 'antd';
import React from 'react';

const Decimal = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules}
      label={label}
      initialValue={parseFloat(preDefinedVal)}
      hidden={hide ?? false}
      hasFeedback
    >
      <InputNumber
        disabled={disabled}
        readOnly={readOnly}
        type="number"
        defaultValue={parseFloat(preDefinedVal)}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default React.memo(Decimal);
