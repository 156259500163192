import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, message, Spin, Select, Button } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import {
  PROPERTY_TYPE_CODE_EDIT,
  PROPERTY_TYPE_COLOR,
  PROPERTY_TYPE_DATE,
  PROPERTY_TYPE_DECIMAL,
  PROPERTY_TYPE_INTEGER,
  PROPERTY_TYPE_LIST,
  PROPERTY_TYPE_RELATION,
  PROPERTY_TYPE_RICH_TEXT,
  PROPERTY_TYPE_TEXT,
  PROPERTY_TYPE_TOGGLE,
  PROPERTY_TYPE_UPLOAD,
} from '@marketreach/components/section/PropertyItem';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import {
  CLIENT_DATA_QUERY,
  CLIENTS,
  CLIENTS_QUERY,
  COPY_ENTITY_SETTINGS,
  GET_SINGLE_CLIENT_QUERY,
} from '@marketreach/services/apollo/clients';
import { ENTITIES_LIST } from '@marketreach/services/apollo/entity';
import {
  hasPermission,
  SETTINGS_LOCALES_MANAGE_PERMISSIONS,
} from '@marketreach/utils/permissioins';

import assets from '../../../../../assets/properties/assets.svg';
import codeEditor from '../../../../../assets/properties/code-editor.svg';
import color from '../../../../../assets/properties/color.svg';
import date from '../../../../../assets/properties/date-and-time.svg';
import float from '../../../../../assets/properties/float.svg';
import integer from '../../../../../assets/properties/integer.svg';
import list from '../../../../../assets/properties/list.svg';
import relation from '../../../../../assets/properties/relation.png';
import richText from '../../../../../assets/properties/richtext-editor.svg';
import text from '../../../../../assets/properties/text.svg';
import toggle from '../../../../../assets/properties/toggle.svg';

import AddNewPropertyModal from './AddNewPropertyModal';
import '../../styles.scss';

export const PROPERTY_TYPE_IMAGE = {
  get(title) {
    if (title in this) {
      return this[title]();
    }
    return text;
  },
  [PROPERTY_TYPE_TEXT]: () => text,
  [PROPERTY_TYPE_CODE_EDIT]: () => codeEditor,
  [PROPERTY_TYPE_UPLOAD]: () => assets,
  [PROPERTY_TYPE_COLOR]: () => color,
  [PROPERTY_TYPE_DATE]: () => date,
  [PROPERTY_TYPE_DECIMAL]: () => float,
  [PROPERTY_TYPE_INTEGER]: () => integer,
  [PROPERTY_TYPE_LIST]: () => list,
  [PROPERTY_TYPE_RICH_TEXT]: () => richText,
  [PROPERTY_TYPE_TOGGLE]: () => toggle,
  [PROPERTY_TYPE_RELATION]: () => relation,
};

const { Text } = Typography;
const { Option } = Select;

const AddNewProperty = (props) => {
  const { sections, data, entityType, apiIdField } = props;
  const { selected: client } = useClientsState();
  const { user } = useAuth0();

  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const [selectedClient, setSelectedClient] = useState(client.apiId);
  const [selectedEntity, setSelectedEntity] = useState();
  const [clients, setClients] = useState();

  const [runCopyEntitySettings, { loading: loadingCopyEntitySettings }] =
    useMutation(COPY_ENTITY_SETTINGS);

  const { loading: loadingClients, data: clientRes } = useQuery(CLIENTS);

  const { data: entitiesListData, loading: loadingEntitiesList } = useQuery(
    ENTITIES_LIST,
    {
      variables: {
        clientCode: selectedClient,
      },
    }
  );

  const [entitiesList, setEntitiesList] = useState([]);

  useEffect(() => {
    if (
      entitiesListData?.listOfEntities?.data &&
      entitiesListData?.listOfEntities?.data !== entitiesList
    ) {
      setEntitiesList(
        entitiesListData.listOfEntities.data.map((item) => ({
          value: item.key,
          label: item.label,
        }))
      );
    }
  }, [entitiesListData]);

  useEffect(() => {
    if (clientRes?.clients?.data) {
      setClients(clientRes?.clients?.data);
    }
  }, [clientRes]);

  const properties = [
    {
      label: 'Text',
      children: [
        {
          title: PROPERTY_TYPE_TEXT,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_TEXT),
          description: 'Headings, Titles, Descriptions',
        },
        {
          title: PROPERTY_TYPE_INTEGER,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_INTEGER),
          description: 'ID, quantity, etc',
        },
        {
          title: PROPERTY_TYPE_DECIMAL,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_DECIMAL),
          description: 'Prices, quantity, weight',
        },
        {
          title: PROPERTY_TYPE_TOGGLE,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_TOGGLE),
          description: 'True or false',
        },
        {
          title: PROPERTY_TYPE_LIST,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_LIST),
          description: 'Arbitrary list structures',
        },
        {
          title: PROPERTY_TYPE_DATE,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_DATE),
          description: 'Calendar date picker w/ time',
        },
        {
          title: PROPERTY_TYPE_COLOR,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_COLOR),
          description: 'Supports RGBA, HEX and HSL',
        },
      ],
    },
    {
      label: 'Relation',
      children: [
        {
          title: PROPERTY_TYPE_RELATION,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_RELATION),
          description: 'Configure links between entities',
        },
      ],
    },
    {
      label: 'Assets',
      children: [
        {
          title: PROPERTY_TYPE_UPLOAD,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_UPLOAD),
          description: 'Supports any file type',
        },
      ],
    },
    {
      label: 'Editors',
      children: [
        {
          title: PROPERTY_TYPE_CODE_EDIT,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_CODE_EDIT),
          description: 'Arbitrary code structure',
        },
        {
          title: PROPERTY_TYPE_RICH_TEXT,
          image: PROPERTY_TYPE_IMAGE.get(PROPERTY_TYPE_RICH_TEXT),
          description: 'Text editor with formatting',
        },
      ],
    },
  ];

  const [openAddModal, setOpenAddModal] = useState(false);
  const [propertyType, setPropertyType] = useState(null);
  const [propertyParentLabel, setPropertyParentLabel] = useState(null);
  const onAddProperty = (property) => {
    let duplicated = false;
    sections.forEach((section) => {
      if (!!section?.properties && !duplicated) {
        section.properties.forEach((p) => {
          if (p.settings.key === property.settings.key) {
            duplicated = true;
          }
        });
      }
    });

    if (duplicated) {
      message.error('Property key is duplicated');

      return;
    }

    setPropertyManager({
      ...propertyManager,
      toAdd: property,
      needAdd: true,
    });
    setOpenAddModal(false);
  };
  const handleCancelModal = () => {
    setOpenAddModal(false);
  };

  const handlePropertyItemClick = (label, type) => {
    setOpenAddModal(true);
    setPropertyType(type);
    setPropertyParentLabel(label);
  };

  const copyFromEntity = async () => {
    if (
      window.confirm(
        `All existing property will be replaced with ${
          entitiesList.find((item) => item.value === selectedEntity).label
        } properties. Are you sure?`
      )
    ) {
      await runCopyEntitySettings({
        variables: {
          clientCode: client.apiId,
          sourceEntity: selectedEntity,
          destinationEntity: entityType,
          sourceClient: selectedClient,
        },
        refetchQueries: [
          CLIENT_DATA_QUERY,
          CLIENTS_QUERY,
          GET_SINGLE_CLIENT_QUERY,
        ],
      });
    }
  };

  return (
    <Spin spinning={loadingCopyEntitySettings}>
      <div className="ant-pro-grid-content models-content-add-property">
        <div className="copyEntitySettings">
          <div className="title">Copy from another entity</div>
          <div>
            <Spin spinning={loadingEntitiesList || loadingClients}>
              {entitiesList && (
                <>
                  {hasPermission(user, SETTINGS_LOCALES_MANAGE_PERMISSIONS) &&
                    clients && (
                      <div>
                        <div className="subtitle">Select client</div>
                        <Select
                          className={'clientSelect'}
                          onChange={(value) => setSelectedClient(value)}
                          defaultValue={selectedClient}
                        >
                          {clients.map((client) => (
                            <Option value={client.apiId}>{client.name}</Option>
                          ))}
                        </Select>
                      </div>
                    )}
                  <Select
                    className={'entitiesSelect'}
                    onChange={(value) => setSelectedEntity(value)}
                  >
                    {entitiesList
                      .filter((item) =>
                        client.apiId === selectedClient
                          ? item.value !== entityType
                          : true
                      )
                      .map((item) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                  </Select>
                  <Button disabled={!selectedEntity} onClick={copyFromEntity}>
                    Copy
                  </Button>
                </>
              )}
            </Spin>
          </div>
        </div>
        <div className="models-content-add-property-title">
          Add new property
        </div>
        {properties.map((property, index) => (
          <div key={`property_${index}`}>
            <div className="models-content-add-property-label">
              {property.label}
            </div>
            {property.children.map((child, index) => (
              <div
                key={child.title.replace(`property_child_${index}`)}
                className="models-content-add-property-item"
                onClick={() => handlePropertyItemClick(property.label, child)}
              >
                <img src={child.image} alt={child.title} />
                <div className="models-content-add-property-item-content">
                  <Text className="models-content-add-property-item-label">
                    {child.title}
                  </Text>
                  <Text className="models-content-add-property-item-description">
                    {child.description}
                  </Text>
                </div>
              </div>
            ))}
          </div>
        ))}
        <AddNewPropertyModal
          open={openAddModal}
          sections={sections}
          type={propertyType}
          handleOk={onAddProperty}
          handleCancel={handleCancelModal}
          data={data}
          entityType={entityType}
          apiIdField={apiIdField}
        />
      </div>
    </Spin>
  );
};

AddNewProperty.propTypes = {
  sections: PropTypes.array,
  entityType: PropTypes.string,
  apiIdField: PropTypes.string,
};

AddNewProperty.defaultProps = {
  sections: [],
  entityType: null,
  apiIdField: null,
};

export default memo(AddNewProperty);
