import { gql } from '@apollo/client';

export const HISTORIES = gql`
  query Histories($clientCode: String!, $filterParams: JSON) {
    histories(clientCode: $clientCode, filterParams: $filterParams) {
      data
    }
  }
`;

export const HISTORIES_QUERY_NAME = 'Histories';

export const HISTORIES_BY_PAGE = gql`
  query GetHistoriesByPage(
    $clientCode: String!
    $filterParams: JSON
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    getHistoriesByPage(
      clientCode: $clientCode
      filterParams: $filterParams
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      data
    }
  }
`;

export const HISTORIES_BY_PAGE_QUERY_NAME = 'GetHistoriesByPage';

export const CREATE_HISTORY = gql`
  mutation CreateHistory(
    $clientCode: String!
    $action: String
    $account: String
    $ipAddress: String
    $type: String
    $parentId: String
  ) {
    createHistory(
      clientCode: $clientCode
      action: $action
      account: $account
      ipAddress: $ipAddress
      type: $type
      parentId: $parentId
    ) {
      success
      error
      data
    }
  }
`;
