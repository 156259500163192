import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useState, useContext, useEffect } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { CATEGORIES } from '@marketreach/services/apollo/categories';

import ProductsTable from '../../share/products/ProductsTable';

import './styles.scss';

const TaxonomyCategoriesPreview = (props) => {
  const { previewFilters } = props;

  const { selected: client } = useClientsState();

  const {
    selectedAttribute,
    associations,
    categories: rawCategories,
  } = useContext(TaxonomyContext);

  const [skus, setSkus] = useState([]);
  const { data: previewCategoriesInfo, loading: previewCategoriesLoading } =
    useQuery(CATEGORIES, {
      variables: {
        clientCode: client?.apiId,
        fullMode: true,
        filterParams: {
          _id: {
            $in: previewFilters,
          },
        },
      },
    });

  useEffect(() => {
    if (previewCategoriesInfo?.categories?.data?.length > 0) {
      let skus = previewCategoriesInfo.categories.data
        .map((item) => item.skus || [])
        .flat()
        .filter((sku) => (selectedAttribute?.skus || []).indexOf(sku) !== -1);
      console.log('sku1', skus, selectedAttribute?.skus);
      setSkus(skus);
    } else {
      setSkus(selectedAttribute?.skus || []);
    }
  }, [previewCategoriesInfo, selectedAttribute]);

  const categories = associations?.categories || [];

  return (
    <div className="categories-content-preview">
      <Spin spinning={previewCategoriesLoading}>
        <ProductsTable
          type="attribute"
          skuArray={skus}
          realSearch={categories.length > 0}
        />
      </Spin>
    </div>
  );
};

TaxonomyCategoriesPreview.propTypes = {
  previewFilters: PropTypes.array,
};

TaxonomyCategoriesPreview.defaultProps = {
  previewFilters: [],
};

export default memo(TaxonomyCategoriesPreview);
