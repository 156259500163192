import { Menu } from 'antd';
import React from 'react';

export const DefaultContextMenu = React.memo(({ handleClickSubmenu, id }) => {
  return (
    <Menu>
      <Menu.Item
        key="select_all"
        onClick={() => {
          handleClickSubmenu('select_all', id);
        }}
      >
        Select All
      </Menu.Item>
      <Menu.Item
        key="deselect_all"
        onClick={() => {
          handleClickSubmenu('deselect_all', id);
        }}
      >
        Unselect All
      </Menu.Item>
      <Menu.Item
        key="expand_children"
        onClick={() => {
          handleClickSubmenu('expand_children', id);
        }}
      >
        Expand Children
      </Menu.Item>
      <Menu.Item
        key="collapse_children"
        onClick={() => {
          handleClickSubmenu('collapse_children', id);
        }}
      >
        Collapse Children
      </Menu.Item>
    </Menu>
  );
});
