import React, { memo } from 'react';

import Analysis from './analysis';
import Overview from './overview';
import AllStoresStatus from './stores';
import Trends from './trend';

import './styles.scss';

const Dashboard = () => (
  <div className="ant-pro-grid-content main-content">
    <Overview />
    <Trends />
    <Analysis />
    <AllStoresStatus />
  </div>
);

export default memo(Dashboard);
