import { Divider } from 'antd';
import React, { useMemo, memo, useCallback } from 'react';

import PropertyItem, {
  PROPERTY_TYPE_CODE_EDIT,
  PROPERTY_TYPE_RICH_TEXT,
  PROPERTY_TYPE_UPLOAD,
} from './PropertyItem';
import './index.less';

const MIN_AMOUNT_OF_SYMBOLS_TO_COLLAPSE_GRID = 80;

export const Section = memo(
  ({
    properties,
    sortByOrder,
    entity,
    name,
    onChange,
    usedValues,
    setNewProperties,
  }) => {
    const sortedProperties = useMemo(() => {
      return [...properties].sort(sortByOrder);
    }, [properties, sortByOrder]);

    const handleChangeProperty = useCallback(
      (sectionName, propertyKey, value, newProperties) => {
        const oldEntity = JSON.parse(JSON.stringify(entity));
        const oldProperty = newProperties || oldEntity.properties || {};
        const newSection = oldProperty[sectionName] || {};

        const newProperty = {
          ...(newProperties || oldProperty),
          [sectionName]: {
            ...newSection,
            [propertyKey]: value,
          },
        };

        setNewProperties(newProperty);
      },
      [setNewProperties, entity]
    );

    const handleChange = useCallback(
      (data) => (value) => handleChangeProperty(name, data.settings.key, value),
      [handleChangeProperty]
    );

    const isEntityLarge = (data) => {
      return (
        [
          PROPERTY_TYPE_CODE_EDIT,
          PROPERTY_TYPE_UPLOAD,
          PROPERTY_TYPE_RICH_TEXT,
        ].includes(data?.propertyType?.title) ||
        data?.settings?.description.length >
          MIN_AMOUNT_OF_SYMBOLS_TO_COLLAPSE_GRID
      );
    };
    return (
      <div>
        <Divider orientation="left">{name}</Divider>
        <div
          className={`properties-fields properties-container n${
            sortedProperties.length === 1
              ? 1
              : Math.min(Math.max(sortedProperties.length, 3), 3)
          }`}
        >
          {sortedProperties
            .map((it) => {
              return {
                ...it,
                isLarge: isEntityLarge(it),
              };
            })
            .sort((a, b) => +a.isLarge - +b.isLarge)
            .map((data) => (
              <PropertyItem
                isLarge={data.isLarge}
                entity={entity}
                type={data?.propertyType?.title}
                data={data}
                disabled={data?.settings?.template?.length > 0}
                key={`property_item_${name}_${data?.settings?.key}_${data?.propertyType?.title}`}
                usedValues={usedValues}
                onChange={onChange}
                handleChangeValue={handleChange(data)}
              />
            ))}
        </div>
      </div>
    );
  }
);

//  <div
//    key={`${selectedCategory?._id || selectedAttribute?._id}_section_${index}`}
//  >
//    <Divider orientation="left">{section.name}</Divider>
//    <div className="properties-fields">
//      {section.properties &&
//        section.properties.length > 0 &&
//        [...section.properties]
//          .sort(sortByOrder)
//          .map((data, index) => (
//            <PropertyItem
//              entity={type === 'category' ? selectedCategory : selectedAttribute}
//              type={data?.propertyType?.title}
//              data={data}
//              disabled={data?.settings?.template?.length > 0}
//              key={`property_item_${index}`}
//              usedValues={usedValues}
//              onChange={onChange}
//              handleChangeValue={(value) =>
//                handleChangeProperty(section.name, data.settings.key, value)
//              }
//            />
//          ))}
//    </div>
//  </div>;
