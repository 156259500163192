import React, { memo } from 'react';

import CustomCard from '@marketreach/components/cards/CustomCard';
import CustomMenu from '@marketreach/components/dropdown';
import CustomTable from '@marketreach/components/table';

import SearchStatusChart from './SearchStatus';
import './styles.scss';

const TopSearch = () => (
  <CustomCard
    title="Online Top Search"
    extra={<CustomMenu />}
    className="top-search"
  >
    <div className="ant-row row-content">
      <div className="ant-col ant-col-xs-24 ant-col-sm-12">
        <SearchStatusChart title="Search Users" value={12321} change={17.1} />
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-12">
        <SearchStatusChart
          title="Per Capita Search"
          value={2.7}
          change={-26.1}
        />
      </div>
    </div>
    <CustomTable />
  </CustomCard>
);

export default memo(TopSearch);
