import { Radio } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const CustomRadioGroup = (props) => {
  const { className, items } = props;

  return (
    <Radio.Group className={className}>
      {items.map((item) => (
        <Radio.Button value={item.value} key={item.value}>
          {item.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

CustomRadioGroup.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
};

CustomRadioGroup.defaultProps = {
  className: '',
  items: [],
};

export default memo(CustomRadioGroup);
