import PropTypes from 'prop-types';
import React, { useState, createContext } from 'react';

const initialValues = {
  toEdit: null,
  needSave: false,
  toAdd: null,
  needAdd: false,
  toDelete: null,
  type: null,
};

export const PropertyManagerContext = createContext(initialValues);

const PropertyManagerProvider = (props) => {
  const { children } = props;

  const [propertyManager, setPropertyManager] = useState(initialValues);

  return (
    <PropertyManagerContext.Provider
      value={{ propertyManager, setPropertyManager }}
    >
      {children}
    </PropertyManagerContext.Provider>
  );
};

PropertyManagerProvider.propTypes = {
  children: PropTypes.node,
};

PropertyManagerProvider.defaultProps = {
  children: undefined,
};

export default PropertyManagerProvider;
