import { useMutation } from '@apollo/client';
import { message, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useState, useContext, useEffect } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import {
  GET_SINGLE_CLIENT_QUERY,
  UPDATE_CLIENTS,
} from '@marketreach/services/apollo/clients';
import { CREATE_HISTORY } from '@marketreach/services/apollo/history';
import { sortByOrder } from '@marketreach/utils/common';

import './styles.scss';
import ProductsTable from '../share/products/ProductsTable';
import ManageProperties from '../taxonomy/properties/manage/ManageProperties';

const ProductsPage = ({ refetchClients }) => {
  const { selected: client } = useClientsState();
  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const [updateClients, { loading: updateClientsLoading }] =
    useMutation(UPDATE_CLIENTS);
  const [createHistory, { loading: createHistoryLoading }] =
    useMutation(CREATE_HISTORY);

  const sections = (client ? client?.sections?.product || [] : [])
    .slice()
    .sort(sortByOrder);

  const [openManage, setOpenManage] = useState(false);
  const handleShowDrawer = (show) => {
    setOpenManage(show);
  };

  const saveSections = (newSections) => {
    const clientSections = JSON.parse(JSON.stringify(client.sections));

    clientSections.product = newSections;
    const newClient = {
      ...client,
      sections: clientSections,
    };

    updateClients({
      variables: { clients: [newClient] },
      refetchQueries: [GET_SINGLE_CLIENT_QUERY],
      awaitRefetchQueries: true,
    })
      .then(() => {
        message.info('Updated successfully');
        refetchClients();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddSection = (section) => {
    const newSections = JSON.parse(JSON.stringify(sections));
    newSections.push({
      ...section,
      properties: [],
      order: sections.length,
    });

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Section ${section?.name} was added in Product`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: 'product',
      },
    });

    saveSections(newSections);
  };

  const handleDeleteSectionOk = (section) => {
    const newSections = sections
      .filter((sec) => sec.order !== section.order)
      .map((sec, index) => ({
        ...sec,
        order: index,
      }));

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Section ${section?.name} was deleted from Product`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: 'product',
      },
    });

    saveSections(newSections);
  };

  const handleAddProperty = (property) => {
    const sectionOrder = property.section.order;
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const newProperty = {
      ...property,
      order: newSections[sectionIndex].properties.length,
    };
    newSections[sectionIndex].properties = [
      ...newSections[sectionIndex].properties,
      newProperty,
    ];

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${property?.name} was added in Product`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: 'product',
      },
    });

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toAdd && propertyManager.needAdd) {
      handleAddProperty(propertyManager.toAdd);
      setPropertyManager({
        ...propertyManager,
        needAdd: false,
        toAdd: null,
      });
    }
  }, [propertyManager.toAdd, propertyManager.needAdd]);

  const handleDeleteProperty = (sectionOrder, propertyOrder) => {
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const oldProperties = newSections[sectionIndex].properties;
    const oldProperty = oldProperties.find(
      (property) => property.order === propertyOrder
    );
    newSections[sectionIndex].properties = oldProperties.filter(
      (property) => property.order !== propertyOrder
    );

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${oldProperty?.name} was deleted from Product`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: 'product',
      },
    });

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toDelete) {
      handleDeleteProperty(
        propertyManager.toDelete.sectionOrder,
        propertyManager.toDelete.propertyOrder
      );
    }
  }, [propertyManager.toDelete]);

  const handleEditProperty = (newProperty) => {
    const sectionIndex = sections.findIndex(
      (section) => section.order === newProperty?.section?.order
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const newProperties = JSON.parse(
      JSON.stringify(newSections[sectionIndex].properties)
    );
    const propertyIndex = newProperties.findIndex(
      (property) => property.order === newProperty?.order
    );
    const oldProperty = newProperties[propertyIndex];

    newProperties[propertyIndex] = newProperty;
    newSections[sectionIndex].properties = newProperties;

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${oldProperty?.settings?.label} was edited in Product`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: 'Product',
      },
    });

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toEdit && propertyManager.needSave) {
      handleEditProperty(propertyManager.toEdit);
      setPropertyManager({
        ...propertyManager,
        needSave: false,
        toEdit: null,
      });
    }
  }, [propertyManager.toEdit]);

  const handleChangeSectionOrder = (section, delta) => {
    const newSections = JSON.parse(JSON.stringify(sections));
    const sectionIndex = sections.findIndex(
      (sec) => sec.order === section.order
    );

    const newOrder = section.order + delta;
    const originalSectionIndex = sections.findIndex(
      (sec) => sec.order === newOrder
    );

    newSections[sectionIndex].order = newOrder;
    newSections[originalSectionIndex].order = section.order;

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Section order was changed from ${section.order} to ${newOrder} in Product`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: 'product',
      },
    });

    saveSections(newSections);
  };

  const handleUpdateSection = (section) => {
    const sectionIndex = sections.findIndex(
      (sec) => sec.order === section.order
    );
    const newSections = JSON.parse(JSON.stringify(sections));

    newSections[sectionIndex] = section;

    saveSections(newSections);
  };

  return (
    <div className="ant-pro-grid-content products-content">
      <ProductsTable
        client={client}
        showManagement
        handleShowDrawer={handleShowDrawer}
      />
      {openManage && (
        <Spin spinning={updateClientsLoading || createHistoryLoading}>
          <ManageProperties
            visible={openManage}
            sections={sections}
            handleShowDrawer={handleShowDrawer}
            handleAddProperty={handleAddProperty}
            handleAddSection={handleAddSection}
            handleDeleteSectionOk={handleDeleteSectionOk}
            handleChangeSectionOrder={handleChangeSectionOrder}
            handleDeleteProperty={handleDeleteProperty}
            handleUpdateSection={handleUpdateSection}
            handleEditProperty={handleEditProperty}
            entityType={'product'}
          />
        </Spin>
      )}
    </div>
  );
};

ProductsPage.propTypes = {
  client: PropTypes.object,
  refetchClients: PropTypes.func,
};

ProductsPage.defaultProps = {
  client: null,
  refetchClients: () => {},
};

export default memo(ProductsPage);
