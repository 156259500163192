// Get validations array with property type
import {
  PROPERTY_TYPE_CODE_EDIT,
  PROPERTY_TYPE_DECIMAL,
  PROPERTY_TYPE_INTEGER,
  PROPERTY_TYPE_LIST,
  PROPERTY_TYPE_RELATION,
  PROPERTY_TYPE_RICH_TEXT,
  PROPERTY_TYPE_TEXT,
  PROPERTY_TYPE_UPLOAD,
  PROPERTY_TYPE_COLOR,
  PROPERTY_TYPE_DATE,
  PROPERTY_TYPE_TOGGLE,
} from '@marketreach/components/section/PropertyItem';

const dict = {
  [PROPERTY_TYPE_RICH_TEXT]: ['required'],
  [PROPERTY_TYPE_CODE_EDIT]: ['required'],
  [PROPERTY_TYPE_UPLOAD]: ['required', 'file_count_limit', 'file_size_limit'],
  [PROPERTY_TYPE_COLOR]: ['required'],
  [PROPERTY_TYPE_DATE]: ['required'],
  [PROPERTY_TYPE_LIST]: ['required'],
  [PROPERTY_TYPE_TOGGLE]: ['required'],
  [PROPERTY_TYPE_RELATION]: ['required'],
  [PROPERTY_TYPE_INTEGER]: ['required', 'unique', 'range'],
  [PROPERTY_TYPE_DECIMAL]: ['required', 'unique', 'range'],
  [PROPERTY_TYPE_TEXT]: [
    'required',
    'unique',
    'limit_characters',
    'match_pattern',
    'restrict_pattern',
  ],
};

const getPropertyValidations = (type) => dict[type] ?? [];

export { getPropertyValidations };
