import { useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Input, message, Spin, Checkbox, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { memo, useEffect, useRef, useState, useMemo } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import {
  CREATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  ATTRIBUTES_QUERY_NAME,
  ATTRIBUTES,
  BULK_CREATE_ATTRIBUTE,
} from '@marketreach/services/apollo/attributes';
import {
  CREATE_HISTORY,
  HISTORIES_BY_PAGE_QUERY_NAME,
  HISTORIES_QUERY_NAME,
} from '@marketreach/services/apollo/history';
import { GET_PRODUCT_KEYS } from '@marketreach/services/apollo/products';
import { getLeafs } from '@marketreach/utils/common';

import './styles.scss';

const { Option } = Select;

const SidebarAttributeModal = ({
  title,
  mode,
  open,
  handleOk,
  handleCancel,
  setSelectedEntityId,
  entity,
}) => {
  const { selected: client } = useClientsState();

  const { data: baseFieldsData } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client.apiId,
    },
  });
  const [addAttribute] = useMutation(CREATE_ATTRIBUTE);
  const [massAddAttribute] = useMutation(BULK_CREATE_ATTRIBUTE);
  const [updateAttribute] = useMutation(UPDATE_ATTRIBUTE);
  const [deleteAttribute] = useMutation(DELETE_ATTRIBUTE);
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [form] = Form.useForm();

  const [attributesInProcess, setAttributesInProcess] = useState(false);
  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  const [productField, setProductField] = useState([]);
  const attribute = entity;
  const attributeId = entity?.key ?? null;

  const childs = useMemo(() => {
    if (!attribute) return [];
    return getLeafs(attribute.children).map((it) => it.key);
  }, [attributeId]);

  const input = useRef(null);
  useEffect(() => {
    if (baseFieldsData?.getProductKeys?.data) {
      setProductField(
        baseFieldsData?.getProductKeys?.data.filter(
          (item) => item?.mainType.toLowerCase() === 'string'
        )
      );
    }
  }, [baseFieldsData]);

  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current.focus();
      });
    }
  }, [open, input]);

  useEffect(() => {
    if (entity && mode === 'edit') {
      form.setFieldsValue({
        name: entity.name,
      });
    } else {
      form.setFieldsValue({
        name: '',
        names: '',
        autoGenerated: null,
        productField: null,
      });
      setIsAutoGenerated(false);
    }
  }, [entity, form, mode]);

  const onOk = () => {
    const formValue = form.getFieldsValue();

    if (
      !isAutoGenerated &&
      !formValue?.name &&
      !['delete', 'bulk_create', 'root'].includes(mode)
    ) {
      message.error('Please input category name');

      return;
    }

    if (!isAutoGenerated && !formValue?.names && mode === 'bulk_create') {
      message.error('Please input categories names');

      return;
    }

    if (
      isAutoGenerated &&
      !formValue?.productField &&
      !['bulk_create', 'root'].includes(mode)
    ) {
      message.error('Please select base field');
      return;
    }

    if (
      (!entity || mode === 'create') &&
      mode !== 'root' &&
      mode !== 'delete'
    ) {
      setAttributesInProcess(true);
      addAttribute({
        variables: {
          ...formValue,
          type: 'attribute',
          layout: 'attribute',
          clientCode: client?.apiId,
          parentId: attributeId,
          isAutoGenerated,
        },
        refetchQueries: [ATTRIBUTES_QUERY_NAME, ATTRIBUTES],
        awaitRefetchQueries: true,
      })
        .then(() => {
          createHistory({
            variables: {
              clientCode: client?.apiId,
              action: attribute
                ? `Atribute ${formValue?.name} was added to the ${attribute?.name} group`
                : `Atribute group ${formValue?.name} was added`,
              account: 'Admin',
              ipAddress: '127.0.0.1',
              type: 'attribute',
              parentId: attribute?._id,
            },
            refetchQueries: [
              HISTORIES_QUERY_NAME,
              HISTORIES_BY_PAGE_QUERY_NAME,
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            form.setFieldsValue({
              names: '',
              name: '',
              autoGenerated: null,
              productField: null,
            });
            setAttributesInProcess(false);
            setIsAutoGenerated(false);
            handleOk(attribute?._id);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (
      ((attributeId || mode === 'bulk_create') &&
        mode !== 'delete' &&
        mode !== 'edit') ||
      (!attributeId && mode === 'root')
    ) {
      setAttributesInProcess(true);
      massAddAttribute({
        variables: {
          ...formValue,
          type: 'attribute',
          layout: 'attribute',
          clientCode: client?.apiId,
          parentId: attributeId,
          isAutoGenerated,
        },
        refetchQueries: [ATTRIBUTES_QUERY_NAME, ATTRIBUTES],
        awaitRefetchQueries: true,
      })
        .then(() => {
          createHistory({
            variables: {
              clientCode: client?.apiId,
              action: attribute
                ? `Atribute ${formValue?.name} was added to the ${attribute?.name} group`
                : `Atribute group ${formValue?.name} was added`,
              account: 'Admin',
              ipAddress: '127.0.0.1',
              type: 'attribute',
              parentId: attribute?._id,
            },
            refetchQueries: [
              HISTORIES_QUERY_NAME,
              HISTORIES_BY_PAGE_QUERY_NAME,
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            form.setFieldsValue({
              names: '',
              name: '',
              autoGenerated: null,
              productField: null,
            });
            setAttributesInProcess(false);
            setIsAutoGenerated(false);
            handleOk(attribute?._id);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (attributeId && mode === 'edit') {
      setAttributesInProcess(true);
      updateAttribute({
        variables: {
          ...formValue,
          type: 'attribute',
          layout: 'attribute',
          clientCode: client?.apiId,
          _id: attributeId,
        },
        refetchQueries: [ATTRIBUTES_QUERY_NAME],
        awaitRefetchQueries: true,
      })
        .then(() => {
          createHistory({
            variables: {
              clientCode: client?.apiId,
              action: `Attribute name ${attribute?.name} was changed to ${formValue?.name}`,
              account: 'Admin',
              ipAddress: '127.0.0.1',
              type: 'attribute',
              parentId: attribute?._id,
            },
            refetchQueries: [
              HISTORIES_QUERY_NAME,
              HISTORIES_BY_PAGE_QUERY_NAME,
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            setAttributesInProcess(false);
            handleOk(attribute?._id);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (attributeId && mode === 'delete') {
      if (attribute) {
        setAttributesInProcess(true);
        deleteAttribute({
          variables: {
            clientCode: client?.apiId,
            _id: attributeId,
            associations: attribute.associations,
            // attributes: [attributeId, ...(childs || [])],
          },
          refetchQueries: [ATTRIBUTES_QUERY_NAME, ATTRIBUTES],
          awaitRefetchQueries: true,
        })
          .then(() => {
            setSelectedEntityId(null);
            createHistory({
              variables: {
                clientCode: client?.apiId,
                action: `Attribute ${attribute?.name} was deleted`,
                account: 'Admin',
                ipAddress: '127.0.0.1',
                type: 'attribute',
                parentId: attribute?._id,
              },
              refetchQueries: [
                HISTORIES_QUERY_NAME,
                HISTORIES_BY_PAGE_QUERY_NAME,
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              setAttributesInProcess(false);
              handleOk();
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
      names: '',
      autoGenerated: null,
      productField: null,
    });
    setIsAutoGenerated(false);
    handleCancel();
  };

  const onFormLayoutChange = () => {};

  function handleKeyUp(event) {
    if (mode !== 'bulk_create' && mode !== 'root') {
      if (event.keyCode === 13) {
        onOk();
      }
    }
  }

  return (
    <Modal
      className="taxonomy-sidebar-category-modal"
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={400}
    >
      <Spin spinning={attributesInProcess}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          onValuesChange={onFormLayoutChange}
          onKeyUp={handleKeyUp}
        >
          {mode === 'delete' && (
            <div>Are you sure want to delete this attribute?</div>
          )}
          {(mode === 'create' || mode === 'edit') && (
            <>
              <Form.Item label="Name" name="name" required>
                <Input ref={input} placeholder="input name" autoFocus />
              </Form.Item>
            </>
          )}
          {(mode === 'bulk_create' || mode === 'root') && (
            <>
              <Form.Item label="Name" name="names" required>
                <TextArea
                  ref={input}
                  placeholder="input names"
                  autoFocus={true}
                  tabIndex={0}
                  autoSize={{ minRows: 1 }}
                />
              </Form.Item>
              {mode === 'bulk_create' && (
                <div>
                  <Form.Item label="" name="autoGenerated">
                    <Checkbox
                      checked={isAutoGenerated}
                      onChange={() => setIsAutoGenerated(!isAutoGenerated)}
                    >
                      Auto Generated
                    </Checkbox>
                  </Form.Item>
                </div>
              )}
              {isAutoGenerated && (
                <div>
                  <Form.Item label="" name="productField" required>
                    <Select
                      required={true}
                      showSearch
                      placeholder="Select a base field"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {productField.map((item) => (
                        <Option value={item.key}>{item.key}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default memo(SidebarAttributeModal);
