import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Layout,
  Spin,
  Tree,
  Empty,
  Menu,
  // Dropdown,
  Popover,
  Typography,
} from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import CustomProTable from '@marketreach/components/protable';
import EntityModal from '@marketreach/pages/entities/EntityModal';
import EntityGridView from '@marketreach/pages/share/entity/EntityGridView';
import PropertiesManager from '@marketreach/pages/taxonomy/properties/PropertiesManager';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import {
  BULK_ENTITY_CREATE,
  ENTITIES_LIST,
  ENTITY_DATA,
  ENTITY_DATA_QUERY,
  REMOVE_ENTITY_DATA,
  SAVE_ENTITY_DATA,
} from '@marketreach/services/apollo/entity';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';
import {
  normalizeEntity,
  sortByOrder,
  treeData,
} from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

import './styles.scss';

const { Sider } = Layout;
const { Text } = Typography;

export const ENTITY_PAGE_SIMPLE_VIEW = 'simple';
export const ENTITY_PAGE_TREE_VIEW = 'tree';

const EntitiesPage = () => {
  const { type } = useParams();

  // Build key for get/set expanded keys of tree
  const expandedKeysKey = `${type}_tree_view_expanded_keys`;

  // States and queries start
  const { selected: client } = useClientsState();
  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const [entitiesList, setEntitiesList] = useState([]);

  const entityInfo = client?.entities.find((item) => item.slug === type);

  const [view, setView] = useState(ENTITY_PAGE_SIMPLE_VIEW);
  const [collapsed, setCollapsed] = useState(false);

  const [showManageProperties, setShowManageProperties] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState({});

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(500);

  // filtering and sorting0
  const [searchParams, setSearchParams] = useState({});
  const [sortParams, setSortParams] = useState({});

  const [selectedEntityId, setSelectedEntityId] = useState();
  const [selectedEntity, setSelectedEntity] = useState();

  const [openSubModal, setOpenSubModal] = useState(false);
  const [subMode, setSubMode] = useState(null);
  const [showInvite, setShowInvite] = useState(false);

  const [entityDataList, setEntityDataList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const { loading, data } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: pageNumber,
      pageSize,
      searchParams,
      sortParams,
    },
  });

  const { data: entitiesListData } = useQuery(ENTITIES_LIST, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  // Load expanded keys data
  const { data: expandedKeysData, loading: expandedKeysLoading } = useQuery(
    GET_SETTING,
    {
      variables: {
        key: expandedKeysKey,
        clientCode: client?.apiId,
      },
    }
  );

  const [saveEntityData, { loading: saveEntityLoading }] =
    useMutation(SAVE_ENTITY_DATA);
  const [removeEntityData, { loading: removeEntityLoading }] =
    useMutation(REMOVE_ENTITY_DATA);
  const [bulkEntityCreate, { loading: bulkEntityLoading }] =
    useMutation(BULK_ENTITY_CREATE);
  const [setSetting, { loading: setExpandedKeysLoading }] =
    useMutation(SET_SETTING);

  // States and queries end

  const handlePageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };

  useEffect(() => {
    if (data?.entityData?.data) {
      setEntityDataList(
        normalizeEntity(
          data?.entityData?.data,
          view === ENTITY_PAGE_SIMPLE_VIEW
        )
      );
    }
  }, [data, showEditor]);

  useEffect(() => {
    if (
      entitiesListData?.listOfEntities?.data &&
      entitiesListData?.listOfEntities?.data !== entitiesList
    ) {
      setEntitiesList(entitiesListData.listOfEntities.data);
    }
  }, [entitiesListData]);

  useEffect(() => {
    setTotalCount(data?.entityData?.totalCount || 0);
  }, [data, setTotalCount]);

  useEffect(() => {
    setSelectedEntity(
      entityDataList?.find((item) => item._id === selectedEntityId)
    );
  }, [selectedEntityId]);

  useEffect(() => {
    if (showEditor) {
      setEntityToEdit(
        entityDataList.find((item) => item._id === entityToEdit._id)
      );
    }
  }, [entityDataList]);

  const sections = (client ? client?.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  useEffect(() => {
    const baseColumns = getSectionColumns(sections);
    if (baseColumns.length === 0 && !showManageProperties) {
      setShowInvite(true);
    } else {
      setShowInvite(false);
    }
  }, [sections, setShowInvite, showManageProperties]);

  /**
   * If expaned keys exists - set it to the state
   */
  useEffect(() => {
    if (
      expandedKeysData?.getSetting?.data?.value &&
      expandedKeys !== expandedKeysData?.getSetting?.data?.value
    ) {
      setExpandedKeys(expandedKeysData?.getSetting?.data?.value);
    }
  }, [expandedKeysData]);

  useEffect(() => {
    console.log('expandedKeys: ', expandedKeys);
  }, [expandedKeys]);

  const columns = getSectionColumns(sections);

  columns.push({
    title: 'Operation',
    dataIndex: 'operation',
    sorter: false,
    width: 10,
    render: (text, row) => [
      <Button
        key="edit"
        type="text"
        shape="circle"
        onClick={() => handleRowClick(row)}
      >
        <EditOutlined />
      </Button>,
      <Button
        key="delete"
        type="text"
        shape="circle"
        onClick={(e) => handleDelete(row, e)}
      >
        <DeleteOutlined />
      </Button>,
    ],
  });

  const handleShowDrawer = (value) => {
    if (value) {
      setPropertyManager({
        ...propertyManager,
        type,
      });
    }
    setShowManageProperties(value);
    setShowInvite(false);
  };

  const addItem = (value) => {
    setSelectedEntityId(null);
    setEntityToEdit({});
    setShowEditor(value);
  };

  const toolbar = () => {
    return (
      <div>
        <Button type="primary" onClick={() => addItem(true)}>
          <PlusOutlined /> Add item
        </Button>
        <Popover
          placement="leftTop"
          title={<Text type="warning">Add property</Text>}
          content={'Please add first property'}
          visible={showInvite}
        >
          <Button type="link" onClick={() => handleShowDrawer(true)}>
            Manage properties
          </Button>
        </Popover>
      </div>
    );
  };

  const handleSaveClick = (values: any) => {
    saveEntityData({
      variables: {
        id: selectedEntity ? selectedEntity?._id : entityToEdit?._id,
        clientCode: client?.apiId,
        type,
        data: values,
      },
      refetchQueries: [ENTITY_DATA_QUERY],
    });

    setShowEditor(false);
  };

  const handleRowClick = (row) => {
    setEntityToEdit(row);
    setShowEditor(true);
  };

  const handleShow = (value) => {
    setShowManageProperties(value);
  };

  const handleSearch = (params) => {
    setSearchParams(params);
  };

  const handleSortChange = (sortField, direction) => {
    setSortParams({
      field: sortField,
      direction,
    });
  };

  const handleDelete = async (row, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (window.confirm('Do you really want to delete that item?')) {
      await removeEntityData({
        variables: {
          id: row?._id,
          clientCode: client?.apiId,
          type,
        },
        refetchQueries: [ENTITY_DATA_QUERY],
      });
    }
  };

  const handleViewChange = async (view) => {
    setView(view);
  };

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const getApiIdField = () => {
    if (client.sections[type]) {
      for (const section of client.sections[type]) {
        for (const property of section?.properties) {
          if (property?.settings?.fieldOptions?.isApiId === true) {
            return property?.settings?.key;
          }
        }
      }
    }
  };

  const onSelect = (key) => {
    if (key.length > 0) {
      const keyData = key.pop();
      setSelectedEntityId(keyData);
    }
  };

  const onClickSubmenu = (type, id) => {
    setOpenSubModal(true);
    setSubMode(type);
  };

  const menu = (id) => (
    <Menu>
      <Menu.Item
        key="bulk_create"
        onClick={() => onClickSubmenu('bulk_create', id)}
      >
        Create New {type}
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => onClickSubmenu('delete', id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleModalOk = (data) => {
    switch (subMode) {
      case 'delete':
        removeEntityData({
          variables: {
            id: selectedEntity?._id,
            clientCode: client?.apiId,
            type,
          },
          refetchQueries: [ENTITY_DATA_QUERY],
        });
        break;
      case 'root_create':
        bulkEntityCreate({
          variables: {
            clientCode: client?.apiId,
            type,
            keys: data,
          },
          refetchQueries: [ENTITY_DATA_QUERY],
        });
        break;
      case 'bulk_create':
        if (selectedEntity) {
          bulkEntityCreate({
            variables: {
              parentId: selectedEntity
                ? selectedEntity?._id
                : entityToEdit?._id,
              clientCode: client?.apiId,
              type,
              // keys: { [getApiIdField()]: data },
              keys: data,
            },
            refetchQueries: [ENTITY_DATA_QUERY],
          });
        } else {
          bulkEntityCreate({
            variables: {
              clientCode: client?.apiId,
              type,
              keys: data,
            },
            refetchQueries: [ENTITY_DATA_QUERY],
          });
        }
        break;
    }
    setOpenSubModal(false);
  };

  const handleModalCancel = () => {
    setOpenSubModal(false);
  };

  const onExpand = async (data) => {
    /**
     * Store expanded keys into user settings
     */
    await setSetting({
      variables: {
        key: expandedKeysKey,
        value: data,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
    });
  };

  return (
    <div className="ant-pro-grid-content entity-content">
      <Spin
        spinning={saveEntityLoading || removeEntityLoading || bulkEntityLoading}
      >
        <h1>
          {entityInfo?.label_plural || entityInfo?.label || 'Entity editor'}
        </h1>
        <div>
          {view === ENTITY_PAGE_SIMPLE_VIEW && (
            <Button onClick={() => handleViewChange(ENTITY_PAGE_TREE_VIEW)}>
              Go to tree view
            </Button>
          )}
          {view === ENTITY_PAGE_TREE_VIEW && (
            <Button onClick={() => handleViewChange(ENTITY_PAGE_SIMPLE_VIEW)}>
              Go to simple view
            </Button>
          )}
        </div>
        {view === ENTITY_PAGE_SIMPLE_VIEW && (
          <>
            <CustomProTable
              className="products-table"
              data={entityDataList}
              columns={columns}
              loading={loading}
              toolbar={toolbar}
              pagination={{
                current: pageNumber,
                total: totalCount,
                pageSize,
                showSizeChanger: true,
                onChange: (page, size) => {
                  handlePageChange(page, size);
                },
                // showTotal: false,
                showTotal: (total, range) => (
                  <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
                ),
              }}
              searchable
              handleSearch={handleSearch}
              handleSortChange={handleSortChange}
              handleRowClick={handleRowClick}
            />
            {showEditor && (
              <EntityGridView
                visible={showEditor}
                title={
                  _.isEmpty(entityToEdit)
                    ? `Add ${entityInfo?.label}`
                    : `Edit ${entityInfo?.label}`
                }
                entity={entityToEdit}
                type={type}
                handleShowGridView={addItem}
                handleSaveClick={handleSaveClick}
                entitiesList={entitiesList}
              />
            )}
          </>
        )}
        {view === ENTITY_PAGE_TREE_VIEW && getApiIdField() && (
          <div className="ant-pro-grid-content">
            <Spin
              spinning={
                saveEntityLoading ||
                removeEntityLoading ||
                loading ||
                bulkEntityLoading
              }
            >
              <Layout>
                <Sider
                  width={350}
                  collapsedWidth={15}
                  collapsible
                  collapsed={collapsed}
                  onCollapse={handleToggleClick}
                  className="side-bar-content"
                >
                  {collapsed ? (
                    <div className="collapsed-sider-content" />
                  ) : (
                    <div className="taxonomy-sidebar-tree-content">
                      <Button
                        className="categories-tree-add-btn"
                        type="dashed"
                        onClick={() => onClickSubmenu('root_create')}
                      >
                        <PlusOutlined /> Add root {type}
                      </Button>
                      <Spin
                        spinning={expandedKeysLoading || setExpandedKeysLoading}
                      >
                        <Tree
                          defaultSelectedKeys={[selectedEntityId]}
                          expandedKeys={expandedKeys}
                          showIcon={false}
                          showLine={{
                            showLeafIcon: false,
                          }}
                          selectedKeys={[selectedEntityId]}
                          onExpand={onExpand}
                          onSelect={onSelect}
                          treeData={treeData(
                            entityDataList,
                            getApiIdField(),
                            menu
                          )}
                        />
                      </Spin>
                    </div>
                  )}
                </Sider>
                <Layout>
                  {!selectedEntity && (
                    <Empty className="position-center">
                      Select entity at sidebar
                    </Empty>
                  )}
                  {selectedEntity && (
                    <>
                      <div className="ant-pro-grid-content entity-content">
                        <div className="properties-content-title">
                          {selectedEntity[getApiIdField()]}
                        </div>
                        <EntityGridView
                          key={selectedEntity._id}
                          visible={true}
                          title={
                            selectedEntity
                              ? `Edit ${entityInfo?.label}`
                              : `Add ${entityInfo?.label}`
                          }
                          entity={selectedEntity}
                          type={type}
                          handleShowGridView={addItem}
                          handleSaveClick={handleSaveClick}
                          drawer={false}
                        />
                      </div>
                    </>
                  )}
                  <EntityModal
                    title={`Create new ${entityInfo?.label}`}
                    mode={subMode}
                    open={openSubModal}
                    entityId={selectedEntity?._id}
                    handleOk={handleModalOk}
                    handleCancel={handleModalCancel}
                  />
                </Layout>
              </Layout>
            </Spin>
          </div>
        )}
        {showManageProperties && (
          <PropertiesManager
            visible={showManageProperties}
            handleShow={handleShow}
            entityType={type}
            apiIdField={getApiIdField()}
          />
        )}
        {view === ENTITY_PAGE_TREE_VIEW && !getApiIdField() && (
          <Empty className="position-center">
            To work with tree view, please configure api id field
          </Empty>
        )}
      </Spin>
    </div>
  );
};

EntitiesPage.propTypes = {
  client: PropTypes.object,
  type: PropTypes.string,
};

EntitiesPage.defaultProps = {
  client: null,
  type: null,
};

export default memo(EntitiesPage);
