import { SearchOutlined, MenuOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useState, useEffect, useRef } from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

import './styles.scss';

const CustomProTable = ({
  className,
  title,
  rowKey,
  columns,
  data,
  toolbar,
  loading,
  selectable,
  expandable,
  draggable,
  searchable,
  pagination,
  handleSortedData,
  handleExpandRow,
  handleRowClick,
  handleSearch,
  handleSortChange,
}) => {
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    if (draggable) {
      setSortedData(data);
    }
  }, [data, draggable]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter(
            (key, index) => index % 2 === 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter(
            (key, index) => index % 2 !== 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const DraggableBodyRow = ({ className, ...restProps }) => (
    <SortableItem index={restProps['data-row-key'] ?? 0} {...restProps} />
  );

  const DragHandle = sortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'pointer',
        color: '#999',
      }}
    />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(sortedData),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setSortedData(newData);
      handleSortedData(oldIndex, newIndex);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const dragColumns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    ...columns,
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const onExpand = (expanded, record) => {
    console.log('onExpand', expanded, record);
    handleExpandRow(expanded, record);
    setExpandedRowKeys(expanded ? [record?.key] : []);
  };

  const [multiColumnSearch, setMultiColumnSearch] = useState({});
  const onColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const selectedColumn = columns.find((col) => col.dataIndex === dataIndex);
    const propertyType = selectedColumn?.propertyType;

    const newFilters = {
      ...multiColumnSearch,
      [dataIndex]:
        propertyType === 'Toggle'
          ? selectedKeys[0] !== 'false'
          : selectedKeys[0],
    };
    setMultiColumnSearch(newFilters);
    handleSearch(newFilters);
  };

  const onColumnSearchReset = (clearFilters, dataIndex) => {
    clearFilters();
    const newFilters = { ...multiColumnSearch };
    delete newFilters[dataIndex];

    setMultiColumnSearch(newFilters);
    handleSearch(newFilters);
  };

  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => onColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => onColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => onColumnSearchReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const tableColumns = draggable ? dragColumns : columns;
  const searchableColumns = tableColumns.map((column) => ({
    ...column,
    ...getColumnSearchProps(column.dataIndex),
  }));

  return (
    <div className="marketReachTable">
      <div className="toolbar">
        <div className="left">
          <h3>{title}</h3>
        </div>
        <div className="right">{toolbar ? toolbar() : ''}</div>
      </div>

      <Table
        className={classnames(
          'custom-pro-table',
          selectedRowKeys.length > 0 ? '' : 'custom-pro-table-no-selected',
          className
        )}
        headerTitle={title}
        options={{
          density: true,
          reload: false,
        }}
        columns={searchable ? searchableColumns : tableColumns}
        dataSource={(draggable ? sortedData : data)?.map((r, index) =>
          r?.key
            ? r
            : {
                ...r,
                key: index,
              }
        )}
        loading={loading}
        rowKey={rowKey}
        beforeSearchSubmit={handleSearch}
        scroll={{ x: 'max-content' }}
        rowSelection={selectable ? rowSelection : false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={pagination}
        expandedRowKeys={expandedRowKeys}
        expandable={expandable}
        onExpand={onExpand}
        components={
          draggable && {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }
        }
        search={false}
        onChange={(pagination, filters, sorter, extra) => {
          handleSortChange(
            sorter.field,
            sorter.order === 'ascend' ? 'ASC' : 'DESC'
          );
        }}
      />
    </div>
  );
};

CustomProTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rowKey: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  draggable: PropTypes.bool,
  searchable: PropTypes.bool,
  toolbar: PropTypes.any,
  expandable: PropTypes.object,
  pagination: PropTypes.any,
  handleSortedData: PropTypes.func,
  handleExpandRow: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleSearch: PropTypes.func,
  handleSortChange: PropTypes.func,
};

CustomProTable.defaultProps = {
  className: '',
  title: '',
  rowKey: 'key',
  columns: [],
  data: [],
  loading: false,
  selectable: true,
  draggable: false,
  searchable: false,
  toolbar: () => [],
  expandable: null,
  pagination: false,
  handleSortedData: () => {},
  handleExpandRow: () => {},
  handleRowClick: () => {},
  handleSearch: () => {},
  handleSortChange: () => {},
};

export default memo(CustomProTable);
