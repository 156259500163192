import React, { memo } from 'react';

import SalesProportion from './Proportion';
import TopSearch from './TopSearch';

import './styles.scss';

const Analysis = () => (
  <div className="ant-row row-content">
    <div className="custom-card-container ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-12">
      <TopSearch />
    </div>
    <div className="custom-card-container ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-12">
      <SalesProportion />
    </div>
  </div>
);

export default memo(Analysis);
