export const RULE_RANGE_BETWEEN = 'between';
export const RULE_RANGE_BIGGER_THAN = 'bigger_than';
export const RULE_RANGE_SMALLER_THAN = 'smaller_than';

const build = (settings) => {
  const rules = [];
  if (settings?.limit) {
    switch (settings?.limit) {
      case RULE_RANGE_BETWEEN:
        rules.push(
          ...[
            { type: 'number', min: settings?.min },
            { type: 'number', max: settings?.max },
          ]
        );
        break;
      case RULE_RANGE_BIGGER_THAN:
        rules.push({ type: 'number', min: settings?.min });
        break;
      case RULE_RANGE_SMALLER_THAN:
        rules.push({ type: 'number', max: settings?.max });
        break;
      default:
        break;
    }
  }

  return rules;
};

export default build;
