import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useCallback } from 'react';

import { Chips } from '@marketreach/components/chips';
import PropertyInput from '@marketreach/components/items/index';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { buildRule } from '@marketreach/utils/validation/index';

export const PROPERTY_TYPE_TEXT = 'Text';
export const PROPERTY_TYPE_INTEGER = 'Integer';
export const PROPERTY_TYPE_DECIMAL = 'Decimal';
export const PROPERTY_TYPE_TOGGLE = 'Toggle';
export const PROPERTY_TYPE_LIST = 'List';
export const PROPERTY_TYPE_COLOR = 'Color';
export const PROPERTY_TYPE_DATE = 'Date and Time';
export const PROPERTY_TYPE_UPLOAD = 'File Upload';
export const PROPERTY_TYPE_CODE_EDIT = 'Code Editor';
export const PROPERTY_TYPE_RICH_TEXT = 'RichText';
export const PROPERTY_TYPE_RELATION = 'Relation';
export const PROPERTY_TYPE_RADIOBUTTON = 'Radio button';

const PropertyItem = (props) => {
  const {
    key,
    isLarge,
    showDescription,
    showLabel,
    readOnly,
    type,
    entity,
    data,
    usedValues,
    defaultValue,
    onChange,
    disabled,
  } = props;

  const { selected: client } = useClientsState();

  let existingValue = undefined;

  if (entity?.properties && entity?.properties[data?.section?.key]) {
    existingValue = entity?.properties[data?.section?.key][data?.settings?.key];
  }

  // backward compatibility
  if (
    !existingValue &&
    entity?.properties &&
    entity?.properties[data?.settings?.key]
  ) {
    existingValue = entity?.properties[data?.settings?.key];
  }

  const preDefinedVal = useMemo(() => {
    if (existingValue !== null && typeof existingValue !== 'undefined') {
      return existingValue;
    }

    return defaultValue || data?.settings?.defaultValue;
  }, [data, entity]);

  const rules = useMemo(() => {
    if (data?.validation) {
      return Object.keys(data?.validation)
        .map((validationKey) => {
          return buildRule(
            validationKey,
            data.validation[validationKey],
            usedValues[data?.settings?.key] ?? []
          );
        })
        .flat()
        .filter((item) => item !== undefined);
    }
    return [];
  }, [data]);

  const validation = useMemo(
    () =>
      data?.validation
        ? Object.keys(data?.validation).filter((key) => data?.validation[key])
        : [],
    [data]
  );

  const hiddenField = data?.advanced?.hideField ?? false;
  const getFieldStatus = useCallback((form, name) => {
    if (form && name) {
      if (form.getFieldError(name)?.length > 0) return 'error';
      if (form.isFieldTouched(name) && form.getFieldError(name)?.length === 0)
        return 'success';
    }

    return '';
  }, []);
  if (hiddenField)
    return (
      <PropertyInput
        type={type}
        settings={data?.settings ?? {}}
        validation={data?.validation || {}}
        rules={rules}
        preDefinedVal={preDefinedVal}
        hide={data?.advanced?.hideField}
        readOnly={readOnly}
        defaultValue={defaultValue}
        client={client}
        onChange={onChange}
        disabled={disabled}
      />
    );

  return (
    <Form.Item noStyle shouldUpdate>
      {(form) => {
        const wrapperClass = 'properties-fields-item';

        return (
          <div
            className={wrapperClass}
            style={{
              gridColumn: isLarge ? '1 / 4' : 'auto',
            }}
          >
            <div className="header">
              {showLabel && (
                <div className="labels">
                  <h4 className="properties-container-title">
                    {data?.settings?.label}
                  </h4>
                  <h5>{data?.settings?.description}</h5>
                </div>
              )}
              {showDescription && (
                <div className="tags">
                  <Chips entities={validation} />
                </div>
              )}
            </div>
            <div
              className={`input ${getFieldStatus(form, data?.settings?.key)}`}
            >
              <PropertyInput
                type={type}
                settings={data?.settings ?? {}}
                validation={data?.validation || {}}
                rules={rules}
                preDefinedVal={preDefinedVal}
                hide={data?.advanced?.hideField}
                readOnly={readOnly}
                defaultValue={defaultValue}
                client={client}
                onChange={onChange}
                disabled={disabled}
              />
            </div>
          </div>
        );
      }}
    </Form.Item>
  );
};

PropertyItem.propTypes = {
  key: PropTypes.string,
  readOnly: PropTypes.bool,
  showDescription: PropTypes.bool,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  entity: PropTypes.object,
  data: PropTypes.object,
  usedValues: PropTypes.object,
  defaultValue: PropTypes.any,
  handleChangeValue: PropTypes.func,
  onChange: PropTypes.func,
};

PropertyItem.defaultProps = {
  key: null,
  readOnly: false,
  showDescription: true,
  showLabel: true,
  disabled: false,
  type: null,
  entity: null,
  data: null,
  usedValues: null,
  defaultValue: null,
  handleChangeValue: () => {},
  onChange: () => {},
};

export default memo(PropertyItem);
