import { gql } from '@apollo/client';

export const ATTRIBUTES = gql`
  query Attributes(
    $clientCode: String!
    $filterParams: JSON
    $fullMode: Boolean = false
  ) {
    attributes(
      clientCode: $clientCode
      filterParams: $filterParams
      fullMode: $fullMode
    ) {
      data
    }
  }
`;

export const ATTRIBUTES_QUERY_NAME = 'Attributes';

export const ATTRIBUTE = gql`
  query Attribute($clientCode: String!, $id: ID!) {
    attribute(clientCode: $clientCode, id: $id) {
      data
    }
  }
`;

export const ATTRIBUTE_QUERY_NAME = 'Attribute';

export const CREATE_ATTRIBUTE = gql`
  mutation CreateAttribute(
    $clientCode: String!
    $name: String
    $type: String
    $layout: String
    $parentId: ID
  ) {
    createAttribute(
      clientCode: $clientCode
      name: $name
      type: $type
      layout: $layout
      parentId: $parentId
    ) {
      success
      error
      data
    }
  }
`;

export const BULK_CREATE_ATTRIBUTE = gql`
  mutation BulkCreateAttribute(
    $clientCode: String!
    $names: String
    $type: String
    $layout: String
    $parentId: ID
    $isAutoGenerated: Boolean
    $productField: String
  ) {
    bulkCreateAttribute(
      clientCode: $clientCode
      names: $names
      type: $type
      layout: $layout
      parentId: $parentId
      isAutoGenerated: $isAutoGenerated
      productField: $productField
    ) {
      success
      error
      data
    }
  }
`;

export const UPDATE_ATTRIBUTE = gql`
  mutation UpdateAttribute(
    $clientCode: String!
    $_id: ID!
    $name: String
    $layout: String
    $type: String
    $associations: JSON
    $properties: JSON
    $templates: JSON
  ) {
    updateAttribute(
      clientCode: $clientCode
      _id: $_id
      name: $name
      type: $type
      layout: $layout
      associations: $associations
      properties: $properties
      templates: $templates
    ) {
      success
      error
      data
    }
  }
`;

export const DELETE_ATTRIBUTE = gql`
  mutation DeleteAttribute(
    $clientCode: String!
    $_id: ID!
    $associations: JSON
  ) {
    deleteAttribute(
      clientCode: $clientCode
      _id: $_id
      associations: $associations
    ) {
      success
      error
      data
    }
  }
`;

export const ADD_ASSOCIATITON_CATEGORY = gql`
  mutation AddAssociationCategory(
    $clientCode: String!
    $_id: ID
    $categoryIds: [ID!]
    $attributeIds: [ID!]
  ) {
    addAssociationCategory(
      clientCode: $clientCode
      _id: $_id
      categoryIds: $categoryIds
      attributeIds: $attributeIds
    ) {
      success
      error
      data
    }
  }
`;

export const REMOVE_ASSOCIATITON_CATEGORY = gql`
  mutation RemoveAssociationCategory(
    $clientCode: String!
    $_id: ID
    $attributeIds: [ID!]
    $categoryIds: [ID!]
  ) {
    removeAssociationCategory(
      clientCode: $clientCode
      _id: $_id
      attributeIds: $attributeIds
      categoryIds: $categoryIds
    ) {
      success
      error
      data
    }
  }
`;

export const REMOVE_ASSOCIATITON_CATEGORY_QUERY = 'RemoveAssociationCategory';
