import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query Categories(
    $clientCode: String!
    $filterParams: JSON
    $fullMode: Boolean = false
  ) {
    categories(
      clientCode: $clientCode
      filterParams: $filterParams
      fullMode: $fullMode
    ) {
      data
    }
  }
`;

export const CATEGORIES_QUERY_NAME = 'Categories';

export const CATEGORY = gql`
  query Category($clientCode: String!, $id: ID!) {
    category(clientCode: $clientCode, id: $id) {
      data
    }
  }
`;

export const CATEGORY_QUERY_NAME = 'Category';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory(
    $clientCode: String!
    $name: String
    $type: String
    $layout: String
    $parentId: ID
  ) {
    createCategory(
      clientCode: $clientCode
      name: $name
      type: $type
      layout: $layout
      parentId: $parentId
    ) {
      success
      error
      data
    }
  }
`;

export const BULK_CREATE_CATEGORY = gql`
  mutation BulkCreateCategory(
    $clientCode: String!
    $names: String
    $type: String
    $layout: String
    $parentId: ID
    $isAutoGenerated: Boolean
    $productField: String
  ) {
    bulkCreateCategory(
      clientCode: $clientCode
      names: $names
      type: $type
      layout: $layout
      parentId: $parentId
      isAutoGenerated: $isAutoGenerated
      productField: $productField
    ) {
      success
      error
      data
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $clientCode: String!
    $_id: ID!
    $name: String
    $layout: String
    $type: String
    $associations: JSON
    $properties: JSON
    $templates: JSON
  ) {
    updateCategory(
      clientCode: $clientCode
      _id: $_id
      name: $name
      type: $type
      layout: $layout
      associations: $associations
      properties: $properties
      templates: $templates
    ) {
      success
      error
      data
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory(
    $clientCode: String!
    $_id: ID
    $associations: JSON
    $categories: [String]
  ) {
    deleteCategory(
      clientCode: $clientCode
      _id: $_id
      associations: $associations
      categories: $categories
    ) {
      success
      error
      data
    }
  }
`;

export const ADD_ASSOCIATITON_ATTRIBUTE = gql`
  mutation AddAssociationAttribute(
    $clientCode: String!
    $_id: ID!
    $attributeIds: [ID!]
  ) {
    addAssociationAttribute(
      clientCode: $clientCode
      _id: $_id
      attributeIds: $attributeIds
    ) {
      success
      error
      data
    }
  }
`;

export const REMOVE_ASSOCIATITON_ATTRIBUTE = gql`
  mutation RemoveAssociationAttribute(
    $clientCode: String!
    $_id: ID!
    $attributeIds: [ID!]
  ) {
    removeAssociationAttribute(
      clientCode: $clientCode
      _id: $_id
      attributeIds: $attributeIds
    ) {
      success
      error
      data
    }
  }
`;
