import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, message, Radio, Spin, Typography } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState, useEffect } from 'react';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';

import CustomSelect from '@marketreach/components/select/index';
import {
  GET_PRODUCT_KEY_VALUES,
  GET_PRODUCT_KEYS,
} from '@marketreach/services/apollo/products';

import ProductsTable from '../../../share/products/ProductsTable';

import '../../Rules.styles.scss';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';

const { Text } = Typography;

const RuleEdit = (props) => {
  const { type, rule, client, handleSaveClick, handleCancelClick } = props;

  const [state, setState] = useState({
    basis: rule?.basis || 'include',
    match: rule?.match || 'any',
    word: rule?.word || 'whole',
    case_sensitive: !!rule?.case_sensitive,
    key: rule?.key || '',
    criteria: rule?.criteria || [],
    type: rule?.type || 'normal',
    scope: rule?.scope || 0,
    refer: rule?.refer || 'product_detail',
    simpleMode:
      typeof rule?.simpleMode !== 'undefined' ? rule?.simpleMode : true,
    advancedQuery: rule?.advancedQuery || {},
  });

  const [productIds, setProductIds] = useState([]);
  const [skus, setSKUs] = useState([]);
  const [keys, setKeys] = useState([]);

  const { data: keyData } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  const { data: keyFullData, loading: loadingFullKeys } = useQuery(
    GET_PRODUCT_KEYS,
    {
      variables: {
        clientCode: client?.apiId,
        full: true,
      },
      skip: state.simpleMode,
    }
  );

  const { data: keyValues, loading: keyValuesLoading } = useQuery(
    GET_PRODUCT_KEY_VALUES,
    {
      variables: {
        clientCode: client?.apiId,
        key: state.key,
      },
      skip: !state.key,
    }
  );

  useEffect(() => {
    if (keyData?.getProductKeys?.data) {
      const keysList = _.cloneDeep(keyData.getProductKeys.data);

      keysList.unshift({
        key: 'wildcard_search',
        label: '- search by all keys - ',
        mainType: 'String',
        type: ['String'],
        values: ['*'],
      });
      setKeys(keysList);
    }
  }, [keyData]);

  const showMatch = useMemo(() => {
    return state.criteria?.length >= 2;
  }, [state]);

  const keyValue = useMemo(() => {
    if (Boolean(state.key)) {
      const keyItem = _.find(keys, { key: state.key });

      if (keyItem) {
        const count = keyValues?.getProductKeyValues?.data?.values.length;
        if (count) {
          return `${state.key} (${count})`;
        }
        return state.key;
      }

      return `${state.key} (loading)`;
    }
    return state.key;
  }, [keys, state.key, keyValuesLoading]);

  const criteriaValuesByKey = useMemo(() => {
    const suggestedTags = [];
    const keyItem = _.find(keys, { key: state.key });
    if (!keyItem) {
      return suggestedTags;
    }

    const result = (keyValues?.getProductKeyValues?.data?.values || [])
      .slice()
      .sort()
      .map((text) => ({
        value: typeof text !== 'undefined' ? text : 'NULL',
        label: typeof text !== 'undefined' ? text?.toString() : 'NULL',
      }));

    //add special "empty" items
    result.unshift({
      value: 'null',
      label: 'Null',
    });

    if (
      [keyItem.type]
        .flat()
        .map((item) => item.toLowerCase())
        .findIndex((val) => val === 'string') > -1
    ) {
      // for string add empty
      result.unshift({
        value: '',
        label: 'Empty',
      });
    }
    return result;
  }, [keys, state.key, keyValuesLoading]);

  const handleChangeState = (field, e) => {
    switch (field) {
      case 'case_sensitive':
        return setState((prevState) => ({
          ...prevState,
          [field]: e.target.value.toLowerCase() === 'true',
        }));
      case 'criteria':
      case 'key':
        return setState((prevState) => ({
          ...prevState,
          [field]: e,
        }));
      default:
        return setState((prevState) => ({
          ...prevState,
          [field]: e.target.value,
        }));
    }
  };

  const handleProductsLoad = (newProductIds, newSkus, newKeys) => {
    // Prevent re render
    if (JSON.stringify(newProductIds) !== JSON.stringify(productIds))
      setProductIds(newProductIds);
    if (JSON.stringify(newSkus) !== JSON.stringify(skus)) setSKUs(newSkus);
  };

  const basisCell = (basis) => {
    const color = basis === 'Include' ? '#2cae2e' : '#ff1f2b';
    return (
      <div style={{ color }} className="rule-basis">
        {basis === 'Include' ? <PlusCircleOutlined /> : <MinusCircleOutlined />}
        <Text className="rule-basis-title" style={{ color }}>
          {basis}
        </Text>
      </div>
    );
  };

  const [saving, setSaving] = useState(false);

  const onClickSave = () => {
    if (state.simpleMode) {
      if (!state.key) {
        message.error('Please select key');

        return;
      }

      if (!state?.criteria?.length || state.criteria.length === 0) {
        message.error('Please input criteria');

        return;
      }
    } else {
      if (!state.advancedQuery) {
        message.error('Please set condition');

        return;
      }
    }
    setSaving(true);

    handleSaveClick({
      ...state,
      key: _.isNumber(state.key) ? '' : state.key,
      productIds,
      skus,
      advancedTree: QbUtils.getTree(tree),
      advancedQuery: JSON.stringify(QbUtils.mongodbFormat(tree, ruleConfig)),
    });
    cleanup();
  };

  const onClickCancel = () => {
    handleCancelClick();
    cleanup();
  };

  const matchString = () => {
    if (
      (state.simpleMode && (!state.key || state.criteria === 0)) ||
      (!state.simpleMode && !state.advancedQuery)
    ) {
      return <span>All products</span>;
    }
    if (state.simpleMode) {
      return (
        <span>
          {state.basis} products where <b>{state.key}</b> contains {state.match}{' '}
          {state.word} words of [ {state.criteria.join(' | ')} ]
          {state.case_sensitive && <span> with </span>}
          {!state.case_sensitive && <span> ignoring </span>} case sensitivity
        </span>
      );
    }
    return (
      <span>
        {state.basis} products where {QbUtils.queryString(tree, ruleConfig)}
      </span>
    );
  };

  // const InitialConfig = AntdConfig;
  const [ruleConfig, setRuleConfig] = useState(AntdConfig);

  useEffect(() => {
    const config = {
      ...AntdConfig,
      fields: {},
    };
    if (!state.simpleMode && keyFullData) {
      for (const key of keyFullData?.getProductKeys?.data) {
        if (key.key === 'wildcard_search') continue;

        let type = key.mainType.toLowerCase();
        if (type === 'string') {
          type = 'text';
        }
        const fieldConfig = {
          label: key.key,
          type,
          valueSources: ['value'],
        };

        if (type === 'text' && key?.values?.length > 0) {
          // build list values
          const listValuesData = [];
          for (const val of key?.values.slice().sort()) {
            listValuesData.push({ value: val, title: val });
          }
          fieldConfig.fieldSettings = {
            listValues: listValuesData,
            allowCustomValues: true,
          };
          fieldConfig.type = 'select';
        }
        config.fields[key.key] = fieldConfig;
      }
    }

    setRuleConfig(config);
  }, [keys, keyValues, state.simpleMode, keyFullData, loadingFullKeys]);

  const [tree, setTree] = useState();

  useEffect(() => {
    try {
      if (keys.length > 0) {
        if (rule?.advancedTree) {
          setTree(
            QbUtils.checkTree(QbUtils.loadTree(rule?.advancedTree), ruleConfig)
          );
        } else {
          const initJsonValues = { and: [] };
          const initTree = QbUtils.loadFromJsonLogic(
            initJsonValues,
            ruleConfig
          );
          setTree(initTree);
        }
      }
    } catch (e) {
      console.error('244 cant load tree', tree);
    }
  }, [ruleConfig, keys]);

  const renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  const onQBChange = (immutableTree, immutableConfig) => {
    setRuleConfig(immutableConfig);
    setTree(immutableTree);
    setState({
      ...state,
      advancedQuery: JSON.stringify(
        QbUtils.mongodbFormat(immutableTree, ruleConfig)
      ),
    });
  };

  const cleanup = () => {
    setState({});
  };

  const criteriaMode = [
    { label: 'Simple query', value: true },
    { label: 'Advanced mode', value: false },
  ];

  return (
    <div className="ant-pro-grid-content rules-content-edit">
      <Spin spinning={saving}>
        <Text className="rules-content-edit-settings-title">Rule Settings</Text>
        <div className="rules-content-edit-settings">
          <div className="rules-content-edit-settings-left">
            <div className="rules-content-edit-settings-field hidden-rule-settings">
              <Text className="rules-content-edit-settings-label">Type:</Text>
              <Radio.Group
                value={state.type}
                onChange={(e) => handleChangeState('type', e)}
                buttonStyle="solid"
                className="radio-group-container"
              >
                <Radio.Button className="radio-group-button" value="normal">
                  Normal
                </Radio.Button>
                <Radio.Button className="radio-group-button" value="default">
                  Default
                </Radio.Button>
                <Radio.Button className="radio-group-button" value="universal">
                  Universal
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="rules-content-edit-settings-field">
              <Text className="rules-content-edit-settings-label">Basis:</Text>
              <Radio.Group
                value={state.basis}
                onChange={(e) => handleChangeState('basis', e)}
                buttonStyle="solid"
                className="radio-group-container"
              >
                <Radio.Button
                  className="radio-group-button basis-button-include"
                  value="include"
                >
                  {basisCell('Include')}
                </Radio.Button>
                <Radio.Button
                  className="radio-group-button basis-button-exclude"
                  value="exclude"
                >
                  {basisCell('Exclude')}
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="rules-content-edit-settings-field">
              <Text className="rules-content-edit-settings-label">Word:</Text>
              <Radio.Group
                value={state.word}
                onChange={(e) => handleChangeState('word', e)}
                buttonStyle="solid"
                className="radio-group-container"
              >
                <Radio.Button className="radio-group-button" value="whole">
                  Whole
                </Radio.Button>
                <Radio.Button className="radio-group-button" value="partial">
                  Partial
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="rules-content-edit-settings-field">
              <Text className="rules-content-edit-settings-label">
                Case Sensitive:
              </Text>
              <Radio.Group
                value={state.case_sensitive ? 'true' : 'false'}
                onChange={(e) => handleChangeState('case_sensitive', e)}
                buttonStyle="solid"
                className="radio-group-container"
              >
                <Radio.Button className="radio-group-button" value="true">
                  Yes
                </Radio.Button>
                <Radio.Button className="radio-group-button" value="false">
                  No
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="rules-content-edit-settings-right">
            <Spin spinning={keys.length === 0}>
              <Radio.Group
                options={criteriaMode}
                onChange={(e) => handleChangeState('simpleMode', e)}
                value={state.simpleMode}
                optionType="button"
                buttonStyle="solid"
              />

              {state.simpleMode && (
                <Spin spinning={keyValuesLoading}>
                  <div className="rules-content-edit-settings-field">
                    <Text className="rules-content-edit-settings-label">
                      Key:
                    </Text>
                    <CustomSelect
                      items={keys.map((item) => ({
                        label: item?.label || item.key,
                        value: item.key,
                      }))}
                      placeholder="Select key"
                      value={keyValue}
                      defaultValue={keyValue}
                      handleChange={(value) => handleChangeState('key', value)}
                    />
                  </div>
                  {showMatch && (
                    <div className="rules-content-edit-settings-field">
                      <Text className="rules-content-edit-settings-label">
                        Match:
                      </Text>
                      <Radio.Group
                        value={state.match}
                        onChange={(e) => handleChangeState('match', e)}
                        buttonStyle="solid"
                        className="radio-group-container"
                      >
                        <Radio.Button
                          className="radio-group-button"
                          value="all"
                        >
                          All
                        </Radio.Button>
                        <Radio.Button
                          className="radio-group-button"
                          value="any"
                        >
                          Any
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  )}
                  <div className="rules-content-edit-settings-field">
                    <Text className="rules-content-edit-settings-label">
                      Criteria:
                    </Text>
                    <CustomSelect
                      mode="tags"
                      items={criteriaValuesByKey}
                      className="rule-tags-multi-select"
                      value={state.criteria}
                      defaultValue={state.criteria}
                      handleChange={(value) =>
                        handleChangeState('criteria', value)
                      }
                    />
                  </div>
                </Spin>
              )}
              {!state.simpleMode && tree && (
                <Spin spinning={loadingFullKeys}>
                  <div className="rules-content-edit-settings-field">
                    <Query
                      {...ruleConfig}
                      renderBuilder={renderBuilder}
                      value={tree}
                      onChange={onQBChange}
                    />
                  </div>
                </Spin>
              )}
            </Spin>
            <div className="rules-content-edit-settings-field">
              <Button
                className="rules-content-edit-settings-save-btn"
                type="primary"
                style={{ marginRight: 12 }}
                onClick={onClickSave}
              >
                Save ruleset
              </Button>
              <Button type="primary" danger onClick={onClickCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <Text className="rules-content-edit-settings-label">
          Match condition: {matchString()}
        </Text>
        {!saving && (
          <ProductsTable
            client={client}
            type={type}
            rule={state}
            onDataLoad={handleProductsLoad}
          />
        )}
      </Spin>
    </div>
  );
};

RuleEdit.propTypes = {
  type: PropTypes.string,
  rule: PropTypes.object,
  client: PropTypes.object,
  handleSaveClick: PropTypes.func,
  handleCancelClick: PropTypes.func,
};

RuleEdit.defaultProps = {
  type: 'product',
  rule: null,
  client: null,
  handleSaveClick: () => {},
  handleCancelClick: () => {},
};

export default memo(RuleEdit);
