import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React from 'react';

const dateFormat = 'MM/DD/YYYY';

const MKDatePicker = ({ value, defaultValue, ...props }) => {
  return (
    <DatePicker
      // value={value || null}
      {...props}
      format={dateFormat}
      defaultValue={defaultValue}
    />
  );
};

const DateItem = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      normalize={(val) => {
        console.log('normalize');
        return val?.format(dateFormat);
      }}
      initialValue={
        moment(preDefinedVal, dateFormat).isValid()
          ? moment(preDefinedVal, dateFormat)
          : null
      }
      rules={rules}
      label={label}
      hidden={hide ?? false}
      hasFeedback
      disabled={disabled}
    >
      <MKDatePicker
        readOnly={readOnly}
        onChange={(e) => {
          console.log('MKDatePicker change: ', e, e?.format(dateFormat));
          onChange(e?.format(dateFormat));
        }}
        defaultValue={preDefinedVal ? moment(preDefinedVal, dateFormat) : null}
      />
    </Form.Item>
  );
};

export default React.memo(DateItem);
