import React, { memo } from 'react';

import CustomCard from '@marketreach/components/cards/CustomCard';
import CustomMenu from '@marketreach/components/dropdown';
import CustomRadioGroup from '@marketreach/components/radio';

import SalesProportionChart from './SalesProportionChart';
import './styles.scss';

const radioButtons = [
  { value: 'all', label: 'ALL' },
  { value: 'online', label: 'Online' },
  { value: 'stores', label: 'Stores' },
];

const SalesProportion = () => (
  <CustomCard
    title="The Proportion Of Sales"
    extra={
      <>
        <CustomRadioGroup items={radioButtons} />
        <CustomMenu />
      </>
    }
    className="proportion-sales"
  >
    <SalesProportionChart />
  </CustomCard>
);

export default memo(SalesProportion);
