import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { RECALCULATE_RULES } from 'services/apollo/rules';

import CustomLoader from '@marketreach/components/loader';
import './styles.scss';
import { APPLY_TEMPLATES } from '@marketreach/services/apollo/clients';
import { REFRESH_PRODUCTS_SCHEMA } from '@marketreach/services/apollo/products';

const RuleSetting = (properties) => {
  const { client } = properties;
  const [recalculateRules, { loading: recalculating }] =
    useMutation(RECALCULATE_RULES);

  const [reloadProductsSchema, { loading: reloadingProductsSchema }] =
    useMutation(REFRESH_PRODUCTS_SCHEMA);

  const [applyTemplates, { loading: applyTemplatesLoading }] =
    useMutation(APPLY_TEMPLATES);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openSchemaConfirmModal, setSchemaOpenConfirmModal] = useState(false);
  const handleRecalculateClick = () => {
    setOpenConfirmModal(true);
  };

  const handleRefreshSchemaClick = () => {
    setSchemaOpenConfirmModal(true);
  };

  const handleRecalculateOk = () => {
    if (client) {
      recalculateRules({
        variables: {
          clientCode: client?.apiId,
        },
      }).then(() => {
        message.info('All rules were recalculated successfully');
        setOpenConfirmModal(false);
      });
    } else {
      message.error('Please select client');
    }
  };

  const handleRecalculateCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleRefreshSchemaOk = () => {
    if (client) {
      reloadProductsSchema({
        variables: {
          clientCode: client?.apiId,
        },
      }).then(() => {
        message.info('Schema for products was reloaded');
        setSchemaOpenConfirmModal(false);
      });
    } else {
      message.error('Please select client');
    }
  };

  const handleRefreshSchemaCancel = () => {
    setSchemaOpenConfirmModal(false);
  };

  const handleApplyTemplatesClick = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'All templated values will be updated. Do you sure?',
      okText: 'Yes',
      cancelText: 'No',

      onOk: () => {
        applyTemplates({ variables: { clientCode: client.apiId } });
      },
    });
  };

  return (
    <div className="settings-content-rule">
      <CustomLoader
        loading={
          recalculating || reloadingProductsSchema || applyTemplatesLoading
        }
      />
      <div className="row-content">
        <Button
          className="settings-content-rule-initiate-btn"
          type="primary"
          onClick={handleRecalculateClick}
        >
          Recalculate Products for All Rules
        </Button>
        {openConfirmModal && (
          <Modal
            className="rule-recaculate-modal"
            title="Recalculate Products for All Rules"
            visible={openConfirmModal}
            onOk={handleRecalculateOk}
            onCancel={handleRecalculateCancel}
            width={400}
          >
            <div>Are sure want to recaclulate products for all rules?</div>
          </Modal>
        )}
      </div>
      <br />
      <div className="row-content">
        <Button
          className="settings-content-rule-initiate-btn"
          type="primary"
          onClick={handleRefreshSchemaClick}
        >
          Refresh Products schema
        </Button>
        {openSchemaConfirmModal && (
          <Modal
            className="rule-recaculate-modal"
            title="Refresh Schema for Products"
            visible={openSchemaConfirmModal}
            onOk={handleRefreshSchemaOk}
            onCancel={handleRefreshSchemaCancel}
            width={400}
          >
            <div>Are sure want to refresh product schema?</div>
          </Modal>
        )}
      </div>
      <br />
      <div className="row-content">
        <Button
          className="settings-content-rule-initiate-btn"
          type="primary"
          onClick={handleApplyTemplatesClick}
        >
          Re-Apply Templates
        </Button>
      </div>
    </div>
  );
};

RuleSetting.propTypes = {
  client: PropTypes.object,
};

RuleSetting.defaultProps = {
  client: null,
};

export default memo(RuleSetting);
