import { Tag } from 'antd';
import React from 'react';

export const Chips = React.memo(({ entities }) => (
  <>
    {entities.length > 0 &&
      entities.map((c) => (
        <Tag color="#198be2" key={c.replace(' ', '_')}>
          {c}
        </Tag>
      ))}
  </>
));
